export const listButtons = [
  // {
  //   id: 1,
  //   title: 'Página Inicial',
  //   nameOption: '',
  //   icon: 'home-lg-alt',
  //   url: 'dashboard',
  // },
  {
    id: 2,
    title: 'Minha Administradora',
    nameOption: 'administradora',
    icon: 'briefcase',
    url: 'myadmin',
  },
  {
    id: 3,
    title: 'Financeiro',
    nameOption: 'segundaVia',
    icon: 'file-invoice-dollar',
    // url: 'copyTicket',
    // url: 'ticketUpload',
    // url: 'normalRequest',
    // url: 'thirdParty',
    url: 'tickets',
  },
  {
    id: 4,
    title: 'Fale com o Condomínio',
    nameOption: 'mensagem',
    icon: 'comment-dots',
    url: 'talkCondo',
  },
  {
    id: 5,
    title: 'Reserva de Espaço',
    nameOption: 'reservaEspaco',
    icon: 'calendar-check',
    url: 'spaceReservation',
  },
  {
    id: 6,
    title: 'Mural de Avisos',
    nameOption: 'aviso',
    icon: 'newspaper',
    url: 'noticeBoard',
  },
  {
    id: 7,
    title: 'Comunicados Individuais',
    nameOption: 'comunicado',
    icon: 'exclamation-circle',
    url: 'individualMessage',
  },
  {
    id: 8,
    title: 'Assembleias Virtuais',
    nameOption: 'assembleia',
    icon: 'comments',
    url: 'virtualAssemblies',
  },
  {
    id: 9,
    title: 'Enquetes e Votações',
    nameOption: 'enquete',
    icon: 'question',
    url: 'polls',
  },
  {
    id: 10,
    title: 'Documentos',
    nameOption: 'documentos',
    icon: 'folder',
    url: 'documents',
  },
  {
    id: 11,
    title: 'Desconectar',
    nameOption: '',
    icon: 'sign-out-alt',
    url: '',
  },
];
