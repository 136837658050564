import React, { useEffect, useState } from 'react';

import { url } from 'inspector';
import { Menu } from '../../components/Menu';

import LoadingCondomob from '../../components/LoadingCondomob';

import { useAuth } from '../../contexts/AuthContext';

import condoDefaultImg from '../../assets/condoImgDefault.svg';
import adminDefaultImg from '../../assets/adminDefaultImgCropped.jpg';

import {
  Container,
  BodyContainer,
  CondoBkgImg,
  CondoInfoContainer,
  CondoLogo,
  CondoUserInfoContainer,
  CondoUserInfo,
} from './styles';

const Dashboard: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const { userCondomob } = useAuth();

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <Container className="dashboardDefault">
        {loading ? (
          <LoadingCondomob />
        ) : (
          <>
            <CondoBkgImg
              style={{
                backgroundImage:
                  userCondomob.condominio.imgAppLink === ''
                    ? `url(${adminDefaultImg})`
                    : `url("${userCondomob.condominio.imgAppLink}")`,
              }}
            >
              <CondoInfoContainer>
                {
                  userCondomob?.condominio.contratoAdm ? (
                    userCondomob?.condominio.administradora.imgLink !== '' ? (
                      <CondoLogo
                        src={userCondomob?.condominio.administradora.imgLink}
                        alt="Condomínio sem imagem"
                      />
                    ) : (
                      <CondoLogo
                        src={condoDefaultImg}
                        alt="Condomínio sem imagem"
                      />
                    )
                  ) : userCondomob?.condominio.imgLink !== '' ? (
                    <CondoLogo
                      src={userCondomob?.condominio.imgLink}
                      alt="Condomínio sem imagem"
                    />
                  ) : (
                    <CondoLogo
                      src={condoDefaultImg}
                      alt="Condomínio sem imagem"
                    />
                  )

                  // // eslint-disable-next-line no-nested-ternary
                  // userCondomob.condominio.administradora === undefined &&
                  // userCondomob.condominio.imgLink === '' ? (
                  //   <CondoLogo
                  //     src={condoDefaultImg}
                  //     alt="Condomínio sem imagem"
                  //   />
                  // ) : userCondomob.condominio.administradora === undefined &&
                  //   userCondomob.condominio.imgLink !== '' ? (
                  //   <CondoLogo
                  //     src={userCondomob.condominio.imgLink}
                  //     alt={userCondomob.condominio.nome}
                  //   />
                  // ) : (
                  //   <CondoLogo
                  //     src={userCondomob.condominio.administradora.imgLink}
                  //     alt={userCondomob.condominio.administradora.nome}
                  //   />
                  // )
                }
                <CondoUserInfoContainer>
                  <CondoUserInfo>{`${userCondomob.unidade.identificador} - ${userCondomob.perfil}`}</CondoUserInfo>
                  <CondoUserInfo>{`${userCondomob.usuario.nome}`}</CondoUserInfo>
                  <CondoUserInfo>{`${userCondomob.condominio.nome}`}</CondoUserInfo>
                </CondoUserInfoContainer>
              </CondoInfoContainer>
            </CondoBkgImg>
            <BodyContainer>
              <Menu />
            </BodyContainer>
          </>
        )}
      </Container>
    </>
  );
};

export default Dashboard;
