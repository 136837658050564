import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import { Menu } from '../../../components/Menu';
import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  CardContainer,
  CardHeader,
  CardContent,
  CardContentPoolInfoVoteContainer,
  CardContentPollDate,
  CardContentPollInfoContainer,
  CardContentPollInfoPercentage,
  CardContentPollVotesInfo,
  CardContentPollSecretVote,
  CardFooter,
} from './styles';

interface IdProps {
  id: number;
}

interface OpcoesProps {
  id: number;
  opcao: string;
  percentual: number;
  peso: number;
  votos: number;
}

interface DescriptionPoolProps {
  dataEnquete: number;
  dataFim: string;
  dataInicio: string;
  id: number;
  minimoEscolhas: number;
  maximoEscolhas: number;
  opcoes: Array<OpcoesProps>;
  percentualVotos: number;
  pergunta: string;
  por: string;
  status: {
    cor: string;
    descricao: string;
    encerrada: boolean;
  };
  titulo: string;
  totalUnidades: number;
  totalVotos: number;
  unidadeVotou: boolean;
  voto?: {
    dataVoto: number;
    dvcAddress: string;
    dvcManufacturer: string;
    dvcModel: string;
    dvcPlatform: string;
    dvcPlatformVersion: string;
    dvcUuid: string;
    id: number;
    opcao: {
      id: number;
      ordem: number;
      votos: number;
    };
    opcoes: Array<number>;
  };
  votoAberto: boolean;
}

const DescriptionPoll: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [poolDetails, setPoolDetails] = useState<DescriptionPoolProps>(
    {} as DescriptionPoolProps,
  );

  const { id }: IdProps = location.state;

  const history = useHistory();
  const { t } = useTranslation();

  const getPoolDetails = async (): Promise<void> => {
    return api
      .get(`/enquete/get`, {
        params: { id },
      })
      .then(res => {
        if (res.status === 200) {
          setPoolDetails(res.data);
        }
      })
      .catch(err => {
        console.error('@getPoolDetails', err.response.data.error);
      });
  };

  useEffect(() => {
    getPoolDetails();
  }, []);

  useEffect(() => {
    if (Object.keys(poolDetails).length > 0) {
      setLoading(false);
    }
  }, [poolDetails]);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('polls.descriptionPoll.title')}</Title>
          </TitleContainer>
          <Content>
            <CardContainer>
              <CardHeader>
                <FontAwesomeIcon icon={['fas', 'user']} />
                <strong>{poolDetails.por}</strong>
              </CardHeader>
              <CardContent>
                <strong>{poolDetails.titulo}</strong>
                <CardContentPollDate color={poolDetails?.status.cor}>
                  <FontAwesomeIcon icon={['fas', 'calendar']} />
                  {poolDetails.dataInicio} a {poolDetails.dataFim} -
                  <span>{poolDetails.status?.descricao}</span>
                </CardContentPollDate>
                {poolDetails.voto !== undefined && (
                  <CardContentPoolInfoVoteContainer>
                    <p>
                      {t('polls.descriptionPoll.pollVoteProtocol')}
                      <strong>{poolDetails.voto.id}</strong>
                    </p>
                    <p>
                      {t('polls.descriptionPoll.pollVoteDate')}
                      {dayjs(poolDetails.voto.dataVoto).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                    </p>
                    <span>{`${poolDetails.voto.dvcPlatform} | ${poolDetails.voto.dvcManufacturer} | ${poolDetails.voto.dvcAddress}`}</span>
                  </CardContentPoolInfoVoteContainer>
                )}
                <CardContentPollInfoContainer>
                  <p
                    dangerouslySetInnerHTML={{ __html: poolDetails.pergunta }}
                  />
                  {poolDetails.opcoes.length > 0 &&
                    poolDetails.opcoes.map(opcao => {
                      return (
                        <CardContentPollInfoPercentage
                          percentage={opcao.percentual}
                          chosenOption={
                            poolDetails.voto !== undefined &&
                            poolDetails.voto.opcoes.filter(
                              item => item === opcao.id,
                            ).length > 0
                          }
                        >
                          <label
                            htmlFor={opcao.opcao}
                          >{`${opcao.opcao}`}</label>
                          <span id={opcao.opcao}>
                            <div>
                              <text>{opcao.percentual}%</text>
                            </div>
                          </span>
                          {poolDetails.voto !== undefined &&
                            poolDetails.voto.opcoes.filter(
                              item => item === opcao.id,
                            ).length > 0 && (
                              <p>
                                {t('polls.descriptionPoll.pollUserVotedDate')}
                                {dayjs(poolDetails.voto.dataVoto).format(
                                  'DD/MM/YYYY HH:mm:ss',
                                )}
                              </p>
                            )}
                        </CardContentPollInfoPercentage>
                      );
                    })}
                  {poolDetails.voto === undefined &&
                    !poolDetails?.status.encerrada && (
                      <Button
                        title={t('polls.descriptionPoll.vote')}
                        width={15}
                        icon="check"
                        onClick={() => {
                          history.push({
                            pathname: `/voteInPool`,
                            state: {
                              id,
                              opcoes: poolDetails.opcoes,
                              pergunta: poolDetails.pergunta,
                              minimo: poolDetails.minimoEscolhas,
                              maximo: poolDetails.maximoEscolhas,
                            },
                          });
                        }}
                      />
                    )}
                </CardContentPollInfoContainer>
                <CardContentPollVotesInfo
                  percentage={poolDetails.percentualVotos}
                >
                  <div>
                    <strong>
                      {t('polls.descriptionPoll.participants')}
                      {poolDetails.totalVotos} {t('common.words.of')}{' '}
                      {poolDetails.totalUnidades}
                    </strong>
                    <p>&nbsp;({poolDetails.percentualVotos}%)</p>
                  </div>
                  <span>
                    <div />
                  </span>
                  <CardContentPollSecretVote>
                    <FontAwesomeIcon
                      icon={[
                        'fas',
                        poolDetails.votoAberto ? 'square' : 'check-square',
                      ]}
                    />
                    {t('polls.descriptionPoll.secretVote')}
                  </CardContentPollSecretVote>
                </CardContentPollVotesInfo>
              </CardContent>
              <CardFooter>
                <FontAwesomeIcon icon={['fas', 'clock']} />
                {dayjs(poolDetails.dataEnquete).format('DD/MM/YYYY HH:mm:ss')}
              </CardFooter>
            </CardContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default DescriptionPoll;
