import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../../components/LoadingCondomob';
import Tabs from '../../../components/Tabs';

import List from './List';
import Form from './Form';

import { Container, TitleContainer, TitleBackButton, Title } from './styles';

const CopyTicket: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('tickets.ticket')}`}</Title>
          </TitleContainer>
          <Tabs
            tabElement={[
              {
                tabTitle: `${t('common.words.list')}`,
                tabIcon: 'file-alt',
              },
              {
                tabTitle: `${t('tickets.normalRequest.requestTitle')}`,
                tabIcon: 'file-check',
              },
            ]}
            tabContentItem={[List, Form]}
            tabActive={0}
          />
        </Container>
      )}
    </>
  );
};

export default CopyTicket;
