import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { Scrollbars } from 'react-custom-scrollbars';

import { Input } from '../../../components/Input';
import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import { normalizedContains } from '../../../constants/Utils';

import {
  Container,
  FilterContainer,
  ListContainer,
  ListItem,
  InputContainer,
  CategoryContainer,
  ButtonCategoryContainer,
  BorderBottom,
} from './styles';

interface DataProps {
  categoria: string;
  dataAtualizacao: number;
  fileName: string;
  id: number;
  link: string;
  tags: string;
  titulo: string;
  usuario: string;
}
interface CategoryProps {
  key: string;
  title: string;
}

const DocumentsContracts: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);

  const [category, setCategory] = useState<CategoryProps[]>([]);
  const [categorySelected, setCategorySelected] = useState('all');

  const [arrayHolder, setArrayHolder] = useState<DataProps[]>([]);

  const [data, setData] = useState<DataProps[]>([]);

  const { t } = useTranslation();

  dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

  function handleCategorySelected(cat: string): void {
    setCategorySelected(cat);
    // OnPress dos botões da categoria, para filtrar a lista por Categoria
    if (cat === categorySelected) {
      setCategorySelected('all');
      setArrayHolder(data);
      setSearchTerm('');
    } else {
      setCategorySelected(cat);
      const newData = data.filter(item => {
        const categoria = item?.categoria ? item.categoria : '';
        const textoFiltro = categoria === '' ? 'sem categoria' : categoria;
        return normalizedContains(textoFiltro, cat);
      });
      setArrayHolder(newData);
    }
  }

  function renderHeaderCategoryList(): JSX.Element {
    const listButtons = () => {
      return category.map(elem => {
        return (
          <ButtonCategoryContainer
            key={elem.key}
            type="button"
            selected={categorySelected === elem.title}
            onClick={() => {
              handleCategorySelected(elem.title);
            }}
          >
            <p>{`${elem.title}`}</p>
          </ButtonCategoryContainer>
        );
      });
    };
    return (
      category && (
        <Scrollbars
          style={{ width: '100%', height: 60 }}
          renderThumbHorizontal={({ style, ...props }) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              style={{
                ...style,
                backgroundColor: '#336699',
                borderRadius: 5,
                opacity: 0.8,
              }}
            />
          )}
        >
          <CategoryContainer>
            <ButtonCategoryContainer
              type="button"
              selected={categorySelected === 'all'}
              onClick={() => {
                handleCategorySelected('');
              }}
            >
              <p>Todos</p>
            </ButtonCategoryContainer>
            {listButtons()}
          </CategoryContainer>
        </Scrollbars>
      )
    );
  }

  async function getCategories(dt: any): Promise<void> {
    const unique = dt
      .map(item => item?.categoria)
      .filter((value, index, self) => self.indexOf(value) === index);

    const categoriasList = unique.map((item: string, index) => {
      return {
        key: item === '' ? 'sem categoria' : item,
        title:
          item === ''
            ? 'Sem categoria'
            : item.charAt(0).toUpperCase() + item.slice(1),
      };
    });

    setCategory(categoriasList);
  }

  async function getData(loadmore = false): Promise<void> {
    if (!loadmore) {
      setLoading(true);
    }

    setSearchTerm('');
    try {
      const url = `/documento/list`;
      await api
        .get(url)
        .then(res => {
          setLoading(false);
          getCategories(res.data);
          setData(res.data);
          setArrayHolder(res.data);
        })
        .catch((error: any) => {
          setLoading(false);
          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: `${error.response.data.error}`,
            icon: `error`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
        });
    } catch (err: any) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${err.message}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      setLoading(false);
    }
  }

  function SearchFilterFunction(text) {
    if (data.length > 0) {
      const newData = data.filter(item => {
        const textoFiltro = item.titulo + item.categoria;
        return normalizedContains(textoFiltro, text);
      });
      setArrayHolder(newData);
      setSearchTerm(text);
    }
  }

  const sendByEmail = async id => {
    return api
      .post(`/documento/receberEmail`, null, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('documents.sendByEmailMessage')}`;
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        console.error('@sendNoticeByEmail', err.response.data.error);
        const str = `${t('documents.documentNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const downloadFile = fileURL => {
    window.open(fileURL);
  };

  const renderItem = () => {
    return arrayHolder.map(item => (
      <ListItem key={item.id}>
        <button
          type="button"
          onClick={() => {
            Swal.fire({
              text: `${item.fileName}`,
              showConfirmButton: item.link.length > 0,
              confirmButtonText:
                '<i class="fas fa-cloud-download-alt"></i> Fazer download',
              confirmButtonColor: `#3c5f8b`,
              backdrop: `rgba(0,0,0,0.9)`,
              showDenyButton: true,
              denyButtonText:
                '<i class="fas fa-envelope"></i> Receber por e-mail',
              denyButtonColor: '#3c5f8b',
            }).then(result => {
              if (result.isConfirmed) {
                downloadFile(item.link);
              } else if (result.isDenied) {
                sendByEmail(item.id);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={['fal', 'file']} />
          <div>
            <strong>{item.titulo}</strong>
            <div>
              <p>{item.categoria}</p>
            </div>
            <div>
              <p>
                {format(new Date(item.dataAtualizacao), 'dd/MM/yyyy HH:mm:ss')}
              </p>
            </div>
          </div>
        </button>
        <BorderBottom />
      </ListItem>
    ));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            {renderHeaderCategoryList()}
            <InputContainer>
              <Input
                name="termSearch"
                type="text"
                placeholder="Filtrar na lista..."
                value={searchTerm}
                onChange={e => {
                  SearchFilterFunction(e.target.value);
                }}
                icon="search"
              />
            </InputContainer>
          </FilterContainer>
          <ListContainer>{renderItem()}</ListContainer>
        </>
      )}
    </Container>
  );
};

export default DocumentsContracts;
