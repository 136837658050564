import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  color: var(--text-body);
  font-size: 0.8rem;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const ContentMain = styled.div`
  display: flex;
  padding: 1rem;
`;

// *** Specific of the page ***

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const MessageHeader = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 3rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: var(--backgroundHeaderDark);
  border: solid 0.5px var(--borderExtraLight);
  align-items: center;
  color: var(--white);

  svg {
    margin: 0 0.5rem 0 1rem;
  }

  p {
  }
`;

export const MessageContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  border-left: solid 0.5px var(--borderExtraLight);
  border-right: solid 0.5px var(--borderExtraLight);
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: var(--white);
`;

export const MessageTitle = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
`;

export const MessageReadDate = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }

  p {
    font-size: 0.8rem;
  }
`;

export const MessageInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: 1rem 0;

  font-size: 0.9rem;

  table {
    border-collapse: collapse;
    width: 100%;
    margin: 0.5rem 0;
  }

  th,
  td {
    border: solid 1px var(--primary);
    text-align: left;
    padding: 0.2rem;
    font-size: 0.9rem;

    @media (max-width: 1185px) {
      font-size: 0.7rem;
    }

    @media (max-width: 976px) {
      font-size: 0.6rem;
    }

    @media (max-width: 872px) {
      font-size: 0.5rem;
      padding: 0.1rem;
    }
  }

  th {
    background-color: var(--secundary);
    color: #fff;
  }

  td {
    a {
      font-weight: 700;
      color: var(--pirmary);
      text-decoration: underline;
    }

    button {
      border: none;
      background-color: transparent;
      padding: 0.2rem;
      svg {
        font-size: 1rem;
        color: var(--primary);
      }
    }
  }

  p {
    margin-top: 0.3rem;
  }
`;

export const InfrationDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfrationTitle = styled.text`
  font-size: 1.2rem;
  font-weight: bold;
`;

export const InfrationDetailItem = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  background-color: var(--cultured);

  padding: 10px;
  margin: 5px 0px;

  align-items: flex-end;
`;

export const InfrationDetailTitle = styled.text`
  font-size: 1rem;
  font-weight: bold;
`;

export const MessageShareContainer = styled.div`
  display: flex;
  justify-content: space-between;

  button {
    margin: 10px;
  }
`;

export const MessageFooter = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 3rem;
  border-radius: 0 0 0.5rem 0.5rem;
  background-color: var(--backgroundHeaderDark);
  color: var(--white);
  border: solid 0.5px var(--borderExtraLight);
  align-items: center;

  svg {
    margin: 0 0.5rem 0 1rem;
  }

  p {
  }
`;

export const ModalContent = styled.div`
  margin-top: 20px;
  padding: 0px 10px;
`;
