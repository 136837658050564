import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import qs from 'qs';

import Swal from 'sweetalert2';
import LoadingCondomob from '../../../../components/LoadingCondomob';
import { Button } from '../../../../components/Button';

import { AppCommonContext } from '../../../../contexts/AppCommonContext';

import imgEmptyList from '../../../../assets/emptyData.svg';
import vaNoMessagesImgDefault from '../../../../assets/vaNoMsgsImgDefault.png';

import api from '../../../../services/api';

import {
  Container,
  LineRow,
  VAScheduleItemList,
  VAScheduleItemListButton,
  VAScheduleItemMessageContainer,
  VASCheduleItemMessageList,
  VASCheduleItemMessageSenderContainer,
  VAScheduleItemMessageElement,
  VAScheduleItemListContainer,
  VASCheduleItemListTitle,
  VAScheduleItemMessageSenderTextInput,
  VAScheduleItemMessageSenderButton,
  VAMessagesNotificationContainer,
} from './styles';

type ScheduleMessageProps = {
  cursor: string;
  hasMore: boolean;
};

type ScheduleMessagesListProps = {
  assembleia: number;
  assembleiaPauta: number;
  dataPostagem: number;
  id: number;
  mensagem: string;
  unidade: string;
  usuario: string;
};

type ScheduleListProps = {
  assembleia: number;
  id: number;
  ordem: number;
  titulo: string;
};

const VAScheduleMessageList: React.FC = ({ dataItem }: any) => {
  const [loading, setLoading] = useState(true);
  const [scheduleList, setScheduleList] = useState<ScheduleListProps[]>([]);
  const [scheduleMessagesList, setScheduleMessagesList] = useState<
    ScheduleMessagesListProps[]
  >([]);

  const [scheduleMessages, setScheduleMessages] =
    useState<ScheduleMessageProps>({
      cursor: '',
      hasMore: true,
    } as ScheduleMessageProps);
  const [scheduleSelected, setScheduleSelected] = useState<number | undefined>(
    0,
  );
  const [userScheduleMessage, setUserScheduleMessage] = useState('');

  const {
    assemblyEndTime,
    assemblyId,
    assemblyStartTime,
    mensagemPresente,
    registraPresencaApp,
  } = dataItem;
  const { assemblyUserPresence, setAssemblyUserPresence } =
    useContext(AppCommonContext);

  const { t } = useTranslation();

  async function getScheduleList() {
    const url = `assembleia/pautas/list`;
    const body = {
      params: {
        id: assemblyId,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setScheduleList(res.data);
      })
      .catch(err => {
        console.error('@getScheduleList', err.data.error);
      });
  }

  async function vaMessageHandleUnitAssemblyPresence() {
    const body = {
      assembleia: assemblyId,
    };
    const url = `assembleia/presencaUnidade/save`;

    await api
      .post(url, qs.stringify(body))
      .then(() => {
        setAssemblyUserPresence(true);
        toast.success(`Presença registrada com sucesso!!`, {
          autoClose: 3000,
          closeOnClick: true,
          draggable: true,
          hideProgressBar: false,
          pauseOnHover: true,
          position: 'top-center',
          progress: undefined,
        });
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          icon: `error`,
          text: error,
          title: `${t('common.words.oops')}`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function releaseAssemblyUnityPresence() {
    if (
      registraPresencaApp &&
      !assemblyUserPresence &&
      mensagemPresente &&
      dayjs(assemblyStartTime, 'DD-MM-YYYY HH:mm').diff(Date.now()) < 0 &&
      dayjs(assemblyEndTime, 'DD-MM-YYYY HH:mm').diff(Date.now()) > 0
    ) {
      Swal.fire({
        title: `Atenção!`,
        html: `<p>Você deseja registrar a sua presença na Assembleia?</p></br><p ${
          !mensagemPresente && `hidden`
        }>• Obrigatório para enviar mensagens.</p>`,
        icon: `question`,
        showCancelButton: true,
        confirmButtonText: `${t('common.words.yes')}`,
        confirmButtonColor: `#3c5f8b`,
        cancelButtonText: `${t('common.words.no')}`,
        cancelButtonColor: '#6c757d',
        reverseButtons: true,
        backdrop: `rgba(0,0,0,0.9)`,
      }).then(result => {
        if (result.isConfirmed) {
          vaMessageHandleUnitAssemblyPresence();
        } else {
          return null;
        }

        return true;
      });
    }
  }

  function handleSelectSchedule(scheduleId?: number): void {
    setLoading(true);
    if (scheduleList.length > 0) {
      if (scheduleSelected === 0) {
        setScheduleSelected(scheduleList[0].id);
      } else {
        setScheduleSelected(scheduleId);
      }
      setScheduleMessages({
        cursor: '',
        hasMore: true,
      });
      setScheduleMessagesList([]);
    }
  }

  async function getAssemblyScheduleMessages(): Promise<void> {
    const url = `assembleia/mensagem/list`;
    const body = {
      params: {
        assembleia: assemblyId,
        assembleiaPauta: scheduleSelected,
        cursor: scheduleMessages.cursor,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setScheduleMessages({
          cursor: res.data.cursor,
          hasMore: res.data.hasMore,
        });
        const messageListSorted = [...res.data.list].sort(
          (a, b) => b.dataPostagem - a.dataPostagem,
        );
        setScheduleMessagesList(prevState => [
          ...prevState,
          ...messageListSorted,
        ]);

        setLoading(false);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function handleScroll(event: any): void {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const minScrollHeightToTrigger = 0.001; // % from the bottom of the scroll
    const scrollPosCalculations =
      scrollHeight -
      scrollTop -
      (scrollHeight - scrollTop) * minScrollHeightToTrigger;

    if (scrollPosCalculations < clientHeight) {
      if (scheduleMessages.hasMore) {
        getAssemblyScheduleMessages();
      }
    }
  }

  async function sendScheduleMessage() {
    const url = `/assembleia/mensagem/save`;
    const body = {
      assembleia: assemblyId,
      mensagem: userScheduleMessage,
      assembleiaPauta: scheduleSelected,
    };

    await api
      .post(url, qs.stringify(body))
      .then(res => {
        setScheduleMessagesList(prevState => [res.data, ...prevState]);
        setUserScheduleMessage('');
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function validateMessage() {
    if (userScheduleMessage.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: 'Por favor, insira algum texto para poder enviar a mensagem',
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });

      setUserScheduleMessage('');
      return;
    }

    sendScheduleMessage();
  }

  useEffect(() => {
    getScheduleList();
  }, []);

  useEffect(() => {
    if (scheduleList.length > 0) {
      handleSelectSchedule();
    }
  }, [scheduleList]);

  useEffect(() => {
    if (scheduleSelected !== 0) {
      getAssemblyScheduleMessages();
      releaseAssemblyUnityPresence();
    }
  }, [scheduleSelected]);

  return loading ? (
    <LoadingCondomob />
  ) : (
    <Container>
      <VAScheduleItemListContainer>
        <VASCheduleItemListTitle>Pauta:</VASCheduleItemListTitle>
        <VAScheduleItemList>
          {scheduleList.map(el => {
            return (
              <VAScheduleItemListButton
                onClick={() => {
                  handleSelectSchedule(el?.id);
                }}
                itemSelected={el?.id === scheduleSelected}
              >
                <div>{el?.ordem}</div>
                <p>{el?.titulo}</p>
              </VAScheduleItemListButton>
            );
          })}
        </VAScheduleItemList>
      </VAScheduleItemListContainer>

      {dayjs(assemblyStartTime, 'DD/MM/YYYY HH:mm', 'pt-br').diff(Date.now()) <
        0 &&
      dayjs(assemblyEndTime, 'DD/MM/YYYY HH:mm', 'pt-br').diff(Date.now()) >
        0 ? (
        mensagemPresente && !assemblyUserPresence ? (
          <VAMessagesNotificationContainer>
            {registraPresencaApp ? (
              <span>
                Para poder ler as mensagens, é necessário registrar a sua
                presença na assembleia.
              </span>
            ) : (
              <span>
                Para poder ler as mensagens, é necessário que a sua presença
                seja registrada na assembleia. Procure a administração do
                condomínio.
              </span>
            )}
            {registraPresencaApp && (
              <Button
                icon="hand-point-up"
                iconFamily="fas"
                onClick={() => {
                  vaMessageHandleUnitAssemblyPresence();
                }}
                title="REGISTRAR PRESENÇA"
              />
            )}
          </VAMessagesNotificationContainer>
        ) : (
          <VAScheduleItemMessageContainer>
            <VASCheduleItemMessageList onScroll={event => handleScroll(event)}>
              {scheduleMessagesList.length === 0 ? (
                <img
                  src={vaNoMessagesImgDefault}
                  alt="Nenhuma mensagem até o momento"
                  width={200}
                  style={{ alignSelf: 'center', marginTop: '5rem' }}
                />
              ) : (
                scheduleMessagesList.map(el => {
                  return (
                    <VAScheduleItemMessageElement>
                      <LineRow>
                        <FontAwesomeIcon
                          icon={['fas', 'user']}
                          color="var(--primary)"
                        />
                        <p>
                          {el?.unidade}
                          {el?.usuario}
                        </p>
                      </LineRow>
                      <span>{el?.mensagem}</span>
                      <LineRow>
                        <FontAwesomeIcon
                          icon={['fas', 'clock']}
                          color="var(--americanGray)"
                        />
                        <h5>
                          {dayjs(el?.dataPostagem).format('DD/MM/YYYY HH:mm')}
                        </h5>
                      </LineRow>
                    </VAScheduleItemMessageElement>
                  );
                })
              )}
            </VASCheduleItemMessageList>
            <VASCheduleItemMessageSenderContainer>
              <VAScheduleItemMessageSenderTextInput
                placeholder="Digite aqui sua mensagem"
                onChange={e => {
                  setUserScheduleMessage(e.target.value);
                }}
                value={userScheduleMessage}
              />
              <VAScheduleItemMessageSenderButton
                onClick={() => {
                  validateMessage();
                }}
              >
                <FontAwesomeIcon icon={['fas', 'paper-plane']} size="lg" />
              </VAScheduleItemMessageSenderButton>
            </VASCheduleItemMessageSenderContainer>
          </VAScheduleItemMessageContainer>
        )
      ) : (
        <VAMessagesNotificationContainer>
          <text>
            É necessário aguardar o início da assembleia para poder iteragir com
            o chat.
          </text>
        </VAMessagesNotificationContainer>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default VAScheduleMessageList;
