import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../../assets/lottie/NewLoadingCondomob.json';

import { Container, LoadingContainer } from './styles';

const LoadingCondomob: React.FC = () => {
  return (
    <Container>
      <LoadingContainer>
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData,
            rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
          }}
        />
      </LoadingContainer>
    </Container>
  );
};

export default LoadingCondomob;
