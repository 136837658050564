import { useState, useEffect, ReactNode } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import MenuAccordion from '../MenuAccordion';
import InvisibleItem from '../InvisibleItem';

import { useAuth } from '../../contexts/AuthContext';

import { listButtons } from './listButtons';

import { MenuButton } from './MenuButton';
import { Container, MenuContainer, ResponsiveMenuContent } from './styles';

export function Menu(): JSX.Element {
  const [active, setActive] = useState(0);
  // const [menuSwitch, setMenuSwitch] = useState(false);

  const history = useHistory();
  const actualPage = useLocation();
  const { logout, userCondomob } = useAuth();

  // const handleResize = () => {
  //   if (window.innerWidth <= 500) {
  //     setMenuSwitch(true);
  //   } else {
  //     setMenuSwitch(false);
  //   }
  // };

  const selectActiveButton = async item => {
    setActive(item.id);
    if (item.title === 'Desconectar') {
      await logout().then(() => {
        history.push('/');
      });
    } else {
      history.push(item.url);
    }
  };

  const { t } = useTranslation();

  const renderListButtons = (): ReactNode => {
    return (
      <MenuContainer>
        <ResponsiveMenuContent>
          {listButtons.map(
            (item, index) =>
              (userCondomob.opcoes[item.nameOption] ||
                userCondomob.opcoes[item.nameOption] === undefined) && (
                <div key={index.toString()}>
                  <MenuButton
                    key={item.id}
                    title={t(`components.menu.${item.id}`)}
                    icon={item.icon}
                    active={active === item.id}
                    onClick={() => {
                      selectActiveButton(item);
                    }}
                  />
                </div>
              ),
          )}
          <InvisibleItem width={40} />
        </ResponsiveMenuContent>
      </MenuContainer>
      // <>
      //   {window.addEventListener('resize', handleResize)}
      //   {menuSwitch ? (
      //     <MenuAccordion />
      //   ) : (
      //     <MenuContainer>
      //       <ResponsiveMenuContent>
      //         {listButtons.map((item, index) => (
      //           <div key={index.toString()}>
      //             <MenuButton
      //               key={item.id}
      //               title={item.title}
      //               icon={item.icon}
      //               active={active === item.id}
      //               onClick={() => {
      //                 selectActiveButton(item);
      //               }}
      //             />
      //           </div>
      //         ))}
      //       </ResponsiveMenuContent>
      //     </MenuContainer>
      //   )}
      // </>
    );
  };

  useEffect(() => {
    if (actualPage.pathname === '/dashboard') setActive(0);
  }, [actualPage]);

  // useEffect(() => {
  //   handleResize();
  // }, []);

  return <Container>{renderListButtons()}</Container>;
}
