import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

interface AppCommonChildrenProps {
  children: ReactNode;
}

type AppCommonContextData = {
  assemblyUserPresence: boolean;
  setAssemblyUserPresence: (data: boolean) => void;
};

const AppCommonContext = createContext<AppCommonContextData>(
  {} as AppCommonContextData,
);

const AppCommonProvider = ({ children }: AppCommonChildrenProps) => {
  const [assemblyUserPresence, setAssemblyUserPresence] = useState(false);

  const value = {
    assemblyUserPresence,
    setAssemblyUserPresence,
  };

  return (
    <AppCommonContext.Provider value={value}>
      {children}
    </AppCommonContext.Provider>
  );
};

const useAppCommon = () => {
  return useContext(AppCommonContext);
};

export { AppCommonContext, AppCommonProvider, useAppCommon };
