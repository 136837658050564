import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../components/LoadingCondomob';
import Tabs from '../../components/Tabs';

import MyReservation from './MyReservation';
import RequestReservation from './RequestReservation';
import PastReservations from './PastReservations';

import { Container, TitleContainer, TitleBackButton, Title } from './styles';

const SpaceReservation: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const state = history.location.state || {};
  const activeTab = state.activeTab !== undefined ? state.activeTab : 0;

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.replace({
                  pathname: '/dashboard',
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('spaceReservation.title')}</Title>
          </TitleContainer>
          <Tabs
            tabElement={[
              {
                tabTitle: `${t('spaceReservation.requestReservation')}`,
                tabIcon: 'calendar-plus',
              },
              {
                tabTitle: `${t('spaceReservation.myReservation.title')}`,
                tabIcon: 'calendar-alt',
              },
              {
                tabTitle: `${t('spaceReservation.pastReservations.title')}`,
                tabIcon: 'calendar-week',
              },
            ]}
            tabContentItem={[
              RequestReservation,
              MyReservation,
              PastReservations,
            ]}
            tabActive={activeTab}
          />
        </Container>
      )}
    </>
  );
};

export default SpaceReservation;
