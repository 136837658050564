import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: 0;
  z-index: 700;
  max-height: 90vh;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
  z-index: 500;
`;

export const StyledModal = styled.div`
  z-index: 100;
  background: white;
  position: relative;
  margin: auto;
  border-radius: 8px;
`;

export const Header = styled.div`
  position: relative;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: var(--primary);
`;

export const HeaderText = styled.div`
  color: #fff;
  align-self: center;
  font-weight: 700;
  max-width: 90%;

  /* numberOfLines */
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const CloseButton = styled.button`
  position: absolute;
  border: none;
  right: 5px;
  background: none;
  :hover {
    cursor: pointer;
  }
`;

export const Content = styled.div`
  padding: 1.5rem 0.5rem;
  max-height: 90vh;
  overflow-x: hidden;
  overflow-y: auto;

  textarea {
    display: flex;
    width: 100%;
    resize: none;
    color: var(--black);
    border-radius: 0.5rem;
    padding: 0.5rem;
  }
`;

export const ShareButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: -webkit-fill-available;
`;

// *** CONFIRMATION MODAL ***

export const ConfirmationButtons = styled.div`
  display: flex;
  justify-content: center;
`;

export const Message = styled.div`
  font-size: 0.9rem;
  margin-bottom: 10px;
  text-align: center;
`;

export const YesButton = styled.button`
  width: 6rem;
  background-color: yellow;
  :hover {
    background-color: red;
  }
`;

export const NoButton = styled.button`
  width: 3rem;
  background-color: lightgrey;
  :hover {
    background-color: grey;
  }
`;
