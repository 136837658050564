import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import api from '../../../../services/api';

import LoadingCondomob from '../../../../components/LoadingCondomob';
import EmptyList from '../../../../components/EmptyList';

import {
  Container,
  LoadingContainer,
  NoticeBoard,
  NoticeBoardMessage,
  NoticeBoardButton,
  ListContainer,
  ListItem,
  ListItemTitle,
  ListItemValue,
  ListItemIcon,
  ListItemDescription,
  BorderBottom,
} from './styles';

interface TicketProps {
  dataVencimento: string;
  descricao: string;
  expirado: boolean;
  id: number;
  link: string;
  nossoNumero: number;
  parcela: string;
  periodo: string;
  status: {
    cor: string;
    descricao: string;
    descricaoCurta: string;
    dias: number;
    icone: string;
    vencida: boolean;
  };
  tipo: string;
  valor: string;
}

const CTOpen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [expirado, setExpirado] = useState(false);
  const [tickets, setTickets] = useState<TicketProps[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const renderItem = () =>
    tickets.map(item => (
      <ListItem
        key={item.id}
        onClick={() => {
          history.replace({
            pathname: '/tickets/ctOpenReadMessage',
            state: {
              item,
            },
          });
        }}
      >
        <ListItemTitle>
          <strong>{`Venc. ${item.dataVencimento}`}</strong>
          <p>{item.tipo}</p>
          <div>
            <FontAwesomeIcon
              icon={['fal', 'calendar']}
              color={
                item?.expirado || item?.status?.vencida ? '#dc3545' : '#3c608b'
              }
            />
            <ListItemDescription
              expired={item?.expirado || item?.status?.vencida}
            >
              {item?.expirado
                ? `${t('tickets.copyTickets.expired')}`
                : item?.status?.descricao}
            </ListItemDescription>
          </div>
        </ListItemTitle>
        <ListItemValue>
          <ListItemDescription
            expired={item?.expirado || item?.status?.vencida}
          >
            R$ {item.valor}
          </ListItemDescription>
        </ListItemValue>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ListItemIcon>
        <BorderBottom />
      </ListItem>
    ));

  function getData() {
    const url = '/financeiro/list';
    api
      .get(url)
      .then(res => {
        setLoading(false);
        const exibeExpirado = res.data.find(e => e.expirado);
        setExpirado(!!exibeExpirado);
        setTickets(res.data);
      })
      .catch(error => {
        setLoading(false);
        console.log(error);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: 'aaa', // `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <>
          {tickets.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              {expirado && (
                <NoticeBoard level="warning">
                  <NoticeBoardMessage level="warning">
                    {`${t('tickets.copyTickets.expiredTickets')}`}
                  </NoticeBoardMessage>
                  <NoticeBoardButton
                    level="warning"
                    type="button"
                    onClick={() => {
                      // history.push('/talkCondo'); // acordoBoleto
                      // onClick={() => {
                      //   return null;
                      // }}
                      history.push({
                        pathname: '/talkCondo',
                        state: {
                          acordoBoleto: true,
                        },
                      });
                    }}
                  >
                    {`${t('tickets.copyTickets.requestAgreement')}`}
                  </NoticeBoardButton>
                </NoticeBoard>
              )}
              <ListContainer>{renderItem()}</ListContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CTOpen;
