import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  padding: 10px;

  max-height: 89%;
  overflow-y: auto;

  background-color: var(--riverstone);
`;

export const ListItemContainer = styled.button`
  position: relative;
  display: flex;
  padding: 10px;

  border-radius: 5px;
  margin: 5px 0px;

  background-color: var(--white);

  outline-width: 0px;
  border: 1px solid var(--primary50);
`;

export const ListItemColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;

  padding: 5px;
`;

export const ListItemColumnRight = styled.div`
  display: flex;
  flex-grow: 0;
  height: 100%;

  padding: 5px;

  align-items: center;
  justify-content: center;

  svg {
    font-size: 1.5rem;
    color: var(--textDark);
  }
`;

export const ListItemRow = styled.div`
  display: flex;
  align-items: center;

  margin: 3px 0px;

  svg {
    color: var(--primary);
    margin-right: 5px;
  }
`;

export const ListItemTitle = styled.text`
  font-size: 1rem;
  font-weight: bold;

  color: var(--primary);
`;

export const ListItemDataText = styled.text`
  font-size: 0.8rem;
  color: var(--textDark);
`;

export const CardReadedPill = styled.div`
  position: absolute;
  bottom: 15px;
  right: 5%;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  padding: 3px 10px;

  border-radius: 50px;
  background-color: var(--primary25);
`;

export const CardReadedPillText = styled.text`
  font-size: 10px;
  margin-left: 5px;

  color: var(--primary);
`;

export const CardMessageTypePill = styled.div<{ pillColor: string }>`
  position: absolute;
  flex-direction: row;
  bottom: 45px;
  right: 5%;
  width: 130px;

  align-items: center;
  justify-content: center;
  border-radius: 10px;

  background-color: ${props => props.pillColor};

  padding: 2px 8px;
`;

export const CardMessageTypePillText = styled.text`
  font-size: 12px;
  color: var(--white);

  margin-left: 5px;
`;

export const LoadMoreRef = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'flex' : 'none')};

  align-items: center;
  justify-content: center;

  border-radius: 5px;

  svg {
    margin-right: 5px;
    color: var(--primary);
  }
`;

export const LoadMoreText = styled.text`
  font-size: 1rem;
  color: var(--primary);
`;

export const AnimDots01 = styled.div`
  width: 30px;
  margin-right: 10px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, var(--primary) 90%, #0000) 0 /
    calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: d1 1s steps(4) infinite;
  @keyframes d1 {
    to {
      clip-path: inset(0 -34% 0 0);
    }
  }
`;
