import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  align-self: center;
  flex-direction: column;
  width: 70vw;
  /* height: 70vh; */
  border-radius: 0.5rem;
  border: 1px solid #f5f5f6;
  background-color: var(--itemBackground);
  overflow: hidden;
  padding: 2rem;
  margin: 1rem 0;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  /* height: 60%; */
  color: var(--text);
  overflow-y: auto;
  padding: 0 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
    overflow-y: auto;
  }
`;

export const SharePostContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  button {
    :first-child {
      margin-right: 0.5rem;
    }
  }
`;
