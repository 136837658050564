import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { Button } from '../../components/Button';
import { Input } from '../../components/Input';
import LoadingCondomob from '../../components/LoadingCondomob';
import { Modal } from '../../components/Modal';
import LoadMoreAnimation from '../../components/LoadMore';
import EmptyList from '../../components/EmptyList';
import InvisibleItem from '../../components/InvisibleItem';

import { useModal } from '../../hooks/useModal';

import NoImage from '../../assets/noImage.svg';

import api from '../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  InputContainer,
  CardPool,
  CardContainer,
  CardContent,
  CardContentImage,
  CardContentText,
  CardContentTextPostDate,
  CardContentLink,
  SharePostContainer,
  CardFooter,
  CardFooterAuthor,
} from './styles';

interface ListItemProps {
  dataAviso: number;
  html: string;
  id: number;
  imgLink: string;
  link: string;
  titulo: string;
  usuario: string;
}

const NoticeBoard: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [noticeBoardItems, setNoticeBoardItems] = useState<ListItemProps[]>([]);
  const [listCursor, setListCursor] = useState('');
  const [listMoreItems, setListMoreItems] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [rawHTMLMessage, setRawHTMLMessage] = useState('');
  const [htmlURLEncoded, setHTMLURLEncoded] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searched, setSearched] = useState(false);

  const history = useHistory();
  const { isShown, toggle } = useModal();
  const { t } = useTranslation();

  const getNotices = async (): Promise<void> => {
    setRefreshing(true);
    return api
      .get(`/aviso/list`, {
        params: {
          cursor: listCursor,
          ...(searchTerm.trim() !== '' ? { titulo: searchTerm } : {}),
        },
      })
      .then(res => {
        if (res.status === 200) {
          setListMoreItems(res.data.hasMore);
          setListCursor(res.data.cursor);
          if (searchTerm) {
            setLoading(true);
            setSearched(true);
            setNoticeBoardItems([]);
            setNoticeBoardItems(res.data.list);
          } else {
            if (searched) {
              setNoticeBoardItems([]);
              setSearched(false);
            }
            // eslint-disable-next-line no-shadow
            setNoticeBoardItems(noticeBoardItems => [
              ...noticeBoardItems,
              ...res.data.list,
            ]);
          }
          setLoading(false);
          setRefreshing(false);
        }
      })
      .catch(err => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${err.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
        setRefreshing(false);
        console.error('@getNotices', err.response);
      });
  };

  const listenToScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (!refreshing && listMoreItems) {
        getNotices();
      }
    }
  };

  const htmlToEncodeURL = ({
    dataAviso,
    html,
    imgLink,
    titulo,
    usuario,
  }: {
    dataAviso: number;
    html: string;
    imgLink: string;
    titulo: string;
    usuario: string;
  }) => {
    const divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    const rawHTMLText =
      divContainer.textContent || divContainer.innerText || '';
    setRawHTMLMessage(
      `${titulo}\nEm: ${dayjs(dataAviso).format(
        'DD/MM/YYYY HH:mm:ss',
      )}\nPor: ${usuario}\n\n${rawHTMLText}\n${imgLink}\n\n\nCondomob\nSeu condomínio na palma da mão!\nhttps://condomob.net`,
    );
    toggle();
  };

  const sendNoticeByEmail = async id => {
    return api
      .post(`/aviso/receberEmail`, null, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('noticeBoard.msgEmailSentSuccess')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: `${t('noticeBoard.msgEmailSentSuccess')}`,
          });
        }
      })
      .catch(err => {
        console.error('@sendNoticeByEmail', err.response.data.error);
        const str = `${t('noticeBoard.msgEmailSentSuccess')} ${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  useEffect(() => {
    setRefreshing(true);
    getNotices();
  }, []);

  useEffect(() => {
    const encoded = encodeURI(rawHTMLMessage);
    setHTMLURLEncoded(encoded);
  }, [rawHTMLMessage]);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <Modal
            headerText={`${t('common.words.share')}`}
            isShown={isShown}
            hide={toggle}
            modalContent={rawHTMLMessage}
            whatsApp
            shareContent={htmlURLEncoded}
          />

          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('noticeBoard.title')}`}</Title>
          </TitleContainer>
          <InputContainer>
            <Input
              name="termSearch"
              type="text"
              placeholder={`${t('noticeBoard.inputPlaceholder')}`}
              value={searchTerm}
              onChange={e => {
                setSearchTerm(e.target.value);
              }}
              model="search"
              icon="search"
              handleSearchFunction={getNotices}
            />
          </InputContainer>
          <Content onScroll={listenToScroll}>
            <CardPool>
              {noticeBoardItems.length === 0 ? (
                <EmptyList />
              ) : (
                noticeBoardItems.map(item => {
                  return (
                    <CardContainer
                      key={item.id}
                      onClick={() => {
                        return null;
                      }}
                    >
                      <CardContent>
                        <CardContentImage
                          onClick={() =>
                            item.imgLink !== '' &&
                            window.open(`${item.imgLink}`, '_blank')
                          }
                        >
                          {item.imgLink === '' ? (
                            <img src={NoImage} alt="NoImage" />
                          ) : (
                            <img src={item.imgLink} alt={item.titulo} />
                          )}
                        </CardContentImage>
                        <CardContentText>
                          <strong>{item.titulo}</strong>
                          <CardContentTextPostDate>
                            <FontAwesomeIcon icon={['fas', 'clock']} />{' '}
                            {dayjs(item.dataAviso).format(
                              'DD/MM/YYYY HH:mm:ss',
                            )}
                          </CardContentTextPostDate>
                          <p dangerouslySetInnerHTML={{ __html: item.html }} />
                          {item.link !== '' && (
                            <CardContentLink>
                              <Button
                                title={`${t('noticeBoard.openAttachment')}`}
                                model="default"
                                width={50}
                                icon="cloud-download"
                                onClick={() => window.open(item.link, '_blank')}
                              />
                            </CardContentLink>
                          )}
                        </CardContentText>
                        <SharePostContainer>
                          <Button
                            title={`${t('common.words.share')}`}
                            model="new"
                            width={50}
                            icon="share-alt"
                            onClick={() => htmlToEncodeURL(item)}
                          />
                          <Button
                            title={`${t('common.words.receiveByEmail')}`}
                            model="new"
                            width={50}
                            icon="envelope"
                            onClick={() => {
                              sendNoticeByEmail(item.id);
                            }}
                          />
                        </SharePostContainer>
                      </CardContent>
                      <CardFooter>
                        <CardFooterAuthor>
                          <FontAwesomeIcon icon={['fas', 'user']} />
                          {item.usuario}
                        </CardFooterAuthor>
                      </CardFooter>
                    </CardContainer>
                  );
                })
              )}
              <InvisibleItem width={20} />
              {listMoreItems && <LoadMoreAnimation />}
            </CardPool>
          </Content>
        </Container>
      )}
    </>
  );
};

export default NoticeBoard;
