import {
  useContext,
  useState,
  useEffect,
  createContext,
  ReactNode,
} from 'react';

import { getAuth } from 'firebase/auth';
import firebase from 'firebase/firebase-app';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { onSignIn, onSignOut } from '../services/auth';
import StorageService, { KEYS } from '../services/storage';
import { UserUnit } from '../types/User';

interface AuthContextData {
  userCredentials: firebase.User | null;
  userUnits: UserUnit[];
  setUserUnits: (units: UserUnit[]) => void;
  userCondomob: any;
  setUserCondomob: any;
  logout: () => any;
  loading: boolean;
  tokenValidated: boolean;
  setTokenValidated: any;
  setAdminLinkIntegrationId: any;
  adminLinkIntegrationId: number | any;
  firebaseToken: string | null;
}

interface UserCondomobProps {
  token?: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC<{
  children?: ReactNode;
}> = ({ children }: { children?: ReactNode }) => {
  const [userCredentials, setUserCredentials] = useState<firebase.User | null>(
    null,
  );

  const [userUnits, setUnits] = useState(
    (StorageService.get(KEYS.UNITS_KEY) ?? []) as UserUnit[],
  );

  const setUserUnits = (units: UserUnit[]) => {
    setUnits(units);
    StorageService.set(KEYS.UNITS_KEY, units);
  };

  const [userCondomob, setUserCondomob] = useState<UserCondomobProps>(
    {} as UserCondomobProps,
  );
  const [loading, setLoading] = useState(true);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [adminLinkIntegrationId, setAdminLinkIntegrationId] = useState(null);
  const [firebaseToken, setFirebaseToken] = useState<string | null>(null);

  const auth = getAuth();
  const { t } = useTranslation();

  // Logout app
  async function logout() {
    return Swal.fire({
      text: `Deseja realmente sair?`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        localStorage.removeItem('@CondomobWeb:tokenCondomob');
        localStorage.removeItem('@CondomobWeb:userCondomob');
        setTokenValidated(false);
        onSignOut();
        setUserCredentials(null);
        setUserCondomob({});
        setUserUnits([]);
        StorageService.remove(KEYS.UNITS_KEY);
      } else {
        return null;
      }
      return auth.signOut();
    });
  }

  const value = {
    userCredentials,
    userUnits,
    setUserUnits,
    userCondomob,
    setUserCondomob,
    logout,
    loading,
    tokenValidated,
    setTokenValidated,
    setAdminLinkIntegrationId,
    adminLinkIntegrationId,
    firebaseToken,
    setFirebaseToken,
  };

  useEffect(() => {
    const subscribe = auth.onAuthStateChanged(async user => {
      if (user) {
        onSignIn(await user?.getIdToken());
        setFirebaseToken(await user.getIdToken());
        setUserCredentials(user);
      } else {
        setUserCredentials(null);
        setUserCondomob({});
        setUserUnits([]);
      }
      setLoading(false);
    });

    return subscribe;
  }, [auth]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = (): AuthContextData => {
  return useContext(AuthContext);
};

export { AuthContext, AuthProvider, useAuth };
