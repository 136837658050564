import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  .teste {
    border-color: red !important;
    color: darkred !important;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.4rem 1rem;

  svg {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

export const ListContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 10rem);
  padding: 0 1rem;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  position: relative;
  width: 100%;

  button {
    display: flex;
    background-color: #fff;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    text-decoration: none;
    border: none;

    &:hover {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    svg {
      color: var(--primary);
      font-size: 2rem;
      margin-right: 1rem;
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: left;

      strong {
        font-size: 1.2rem;
        color: var(--primary);
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        p {
          font-size: 0.8rem;
          color: var(--primary);
          margin-top: 4px;

          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
`;

export const InputSelect = styled(Select).attrs({
  styles: {
    option: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    input: base => ({
      ...base,
      fontSize: '0.8rem',
      margin: 0,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      border: `solid 1px var(--inputBorder)`,
      display: 'flex',
      height: 35,
      borderRadius: 5,
      fontSize: '0.9rem',
      marginBottom: 5,
      marginLeft: -2,
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: () => ({
      color: 'var(--primary)',
    }),
  },
})`
  width: 100%;
  margin-left: 3px;
`;

export const CategoryContainer = styled.div`
  display: flex;
  width: 100%;

  /* ::-webkit-scrollbar-thumb {
    background-color: blue;
    border-radius: 20px;
    border: 3px solid orange;
  } */
`;

export const ButtonCategoryContainer = styled.button<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props =>
    props.selected ? `var(--primary)` : `var(--white)`};
  border-radius: 1.2rem;
  border: ${props =>
    props.selected ? 'none' : `solid 1px var(--borderExtraLight)`};

  height: 2.2rem;
  min-width: 8rem;

  padding: 0.5rem;
  margin: 0.2rem;
  margin-bottom: 1rem;

  p {
    color: ${props => (props.selected ? `var(--white)` : `var(--primary)`)};
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;

    line-height: 0.875rem;
  }
`;

export const ButtonSelectContainer = styled.div``;

export const CategoryTitle = styled.p`
  margin: 5px 17px;
  color: var(--primary);
`;

export const BorderBottom = styled.div`
  position: absolute;
  width: 98%;
  border: none;
  border-bottom: solid 1px var(--borderExtraLight);
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
