import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TimePicker from 'react-time-picker';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
import Swal from 'sweetalert2';
import qs from 'qs';

import { Button } from '../../../../components/Button';
import LoadingCondomob from '../../../../components/LoadingCondomob';

import api from '../../../../services/api';

import {
  Container,
  InputSelect,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  ContentContainer,
  RRHeader,
  RRHeaderImage,
  RRHeaderInfo,
  RRHeaderTitle,
  RRHeaderDate,
  RRRow,
  RRRowColumn,
  RRRowElement,
  RRLabel,
  RRTimePickerContainer,
  RRInput,
  RRInputInfo,
} from './styles';

interface ItemProps {
  capacidade: number;
  id: number;
  imgLink: string;
  motivoReserva: boolean;
  nome: string;
  numeroConvidadosObrigatorio: boolean;
}

interface ScheduledListProps {
  id: number;
  horarioInicial: string;
  horarioFinal: string;
  segunda: boolean;
  terca: boolean;
  quarta: boolean;
  quinta: boolean;
  sexta: boolean;
  sabado: boolean;
  domingo: boolean;
  feriado: boolean;
  bloqueado: boolean;
}

const RequestReservation: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [scheduledList, setScheduledList] = useState<ScheduledListProps[]>([]);
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [reasonReserve, setReasonReserve] = useState('');
  const [guestNumber, setGuestNumber] = useState('0');
  const [guestList, setGuestList] = useState('');
  const [observText, setObservText] = useState('');

  const { item, dateSelected } = location.state;
  const {
    capacidade,
    id,
    imgLink,
    nome,
    motivoReserva,
    numeroConvidadosObrigatorio,
  }: ItemProps = item;
  const history = useHistory();
  const { t } = useTranslation();

  const nameDay = dayjs
    .utc(dateSelected)
    .locale('pt-br')
    .format('dddd')
    .toLowerCase()
    .split('-')[0]
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  const registerSchedule = async (): Promise<void> => {
    const body = {
      espaco: id,
      dataReserva: dayjs.utc(dateSelected).format('DD/MM/YYYY'),
      motivo: reasonReserve,
      horarioInicio: startTime,
      horarioFim: endTime,
      convidados: guestList,
      numeroConvidados: guestNumber,
      observacao: observText,
    };

    return api
      .post(`/reservaEspaco/save`, qs.stringify(body))
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            title: `${t('common.words.success')}`,
            text: `${t(
              'spaceReservation.reservationRequest.reservationSuccessfully',
            )}`,
            icon: `success`,
            backdrop: `rgba(0,0,0,0.9)`,
          }).then(result => {
            if (result.isConfirmed) {
              history.push(`spaceReservation`);
            }
          });
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const getScheduleList = async (): Promise<void> => {
    const body = {
      params: {
        espaco: id,
        dataReserva: dayjs.utc(dateSelected).format('DD/MM/YYYY'),
      },
    };
    await api
      .get(`/reservaEspaco/listHorarios`, body)
      .then(res => {
        if (res.status === 200) {
          let tempArray = [];
          tempArray = res.data
            .filter(el => el[nameDay])
            .map(
              el =>
                el[nameDay] && {
                  label: `${el.horarioInicial} - ${el.horarioFinal}`,
                  value: [el.horarioInicial, el.horarioFinal],
                },
            );
          setScheduledList(tempArray);
        }
      })
      .catch(err => {
        console.error('@getScheduleList', err.response.data.error);
      });
  };

  const confirUsageRules = () => {
    Swal.fire({
      text: `${t(
        'spaceReservation.requestReservationDetail.confirmUsageRulesText',
      )}`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        registerSchedule();
      } else {
        return null;
      }
      return null;
    });
  };

  const validateForm = () => {
    if (startTime === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t(
          'spaceReservation.reservationRequest.validateTime.startTime',
        )}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (endTime === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t(
          'spaceReservation.reservationRequest.validateTime.endTime',
        )}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (dayjs(endTime).isBefore(dayjs(startTime))) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t(
          'spaceReservation.reservationRequest.validateTime.diffTime',
        )}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (
      dayjs(
        `${dayjs(dateSelected).format('DD/MM/YYYY')} ${startTime}`,
      ).isBefore(dayjs(new Date()).format('DD/MM/YYYY HH:mm'))
    ) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t(
          'spaceReservation.reservationRequest.validateTime.beforeStartTime',
        )}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (reasonReserve.trim() === '' && motivoReserva) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('spaceReservation.reservationRequest.reasonReservation')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (
      (guestNumber === '0' || guestList.trim() === '') &&
      numeroConvidadosObrigatorio
    ) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('spaceReservation.reservationRequest.numberOfGuestsInfo')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    confirUsageRules();
  };

  useEffect(() => {
    getScheduleList();
  }, []);

  useEffect(() => {
    if (scheduledList) {
      setLoading(false);
    }
  }, [scheduledList]);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon
                icon={['far', 'angle-left']}
                size="lg"
                color="#3c5f8b"
              />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('spaceReservation.reservationRequest.title')}`}</Title>
          </TitleContainer>
          <Content>
            <ContentContainer>
              <RRHeader>
                <RRHeaderImage src={imgLink} alt={nome} />
                <RRHeaderInfo>
                  <RRHeaderTitle>{nome}</RRHeaderTitle>
                  <RRHeaderDate>
                    {dayjs(dateSelected).utc().local().format('DD/MM/YYYY')}
                  </RRHeaderDate>
                </RRHeaderInfo>
              </RRHeader>
              {scheduledList.length > 0 ? (
                <RRRowColumn>
                  <label htmlFor="inputSelectDestination">
                    {t('spaceReservation.reservationRequest.time')}
                  </label>
                  <InputSelect
                    id="inputSelectDestination"
                    options={scheduledList}
                    placeholder={`${t('common.phrases.selectOption')}`}
                    onChange={(choice: any) => {
                      setStartTime(choice.value[0]);
                      setEndTime(choice.value[1]);
                    }}
                    isSearchable={false}
                  />
                </RRRowColumn>
              ) : (
                <RRRow>
                  <RRTimePickerContainer>
                    <RRLabel>{`${t('common.phrases.startTime')}`}</RRLabel>
                    <TimePicker
                      onChange={setStartTime}
                      value={startTime}
                      locale="pt-BR"
                      disableClock
                    />
                  </RRTimePickerContainer>
                  <RRTimePickerContainer>
                    <RRLabel>{`${t('common.phrases.endTime')}`}</RRLabel>
                    <TimePicker
                      onChange={setEndTime}
                      value={endTime}
                      locale="pt-BR"
                      disableClock
                    />
                  </RRTimePickerContainer>
                </RRRow>
              )}
              <RRRow>
                <RRRowElement>
                  <RRLabel>{`${t(
                    'spaceReservation.reservationRequest.reasonForBooking',
                  )}`}</RRLabel>
                  <RRInput>
                    <input
                      type="text"
                      id="reasonTxt"
                      name="reasonTxt"
                      value={reasonReserve}
                      onChange={e => {
                        setReasonReserve(e.target.value);
                      }}
                    />
                  </RRInput>
                </RRRowElement>
              </RRRow>
              {item?.opcaoNumeroConvidados?.id !== -1 && (
                <RRRow>
                  <RRRowElement>
                    <RRLabel>{`${t(
                      'spaceReservation.reservationRequest.numberOfGuests',
                    )}`}</RRLabel>
                    <RRInput>
                      <input
                        type="text"
                        id="numberGuest"
                        name="numberGuest"
                        value={guestNumber}
                        onChange={e => {
                          const result = e.target.value.replace(/\D/g, '');
                          setGuestNumber(result);
                        }}
                        max={capacidade}
                        min={0}
                      />
                    </RRInput>
                  </RRRowElement>
                </RRRow>
              )}
              {item?.opcaoConvidado?.id !== -1 && (
                <RRRow>
                  <RRRowElement>
                    <RRLabel>{`${t(
                      'spaceReservation.reservationRequest.guestList',
                    )}`}</RRLabel>
                    <RRInput>
                      <textarea
                        id="text03"
                        name="text03"
                        value={guestList}
                        onChange={e => {
                          setGuestList(e.target.value);
                        }}
                        rows={4}
                      />
                    </RRInput>
                    <RRInputInfo>
                      <ul>
                        <li>
                          <strong>
                            {`${t(
                              'spaceReservation.info.capacity',
                            )}: ${capacidade} ${t(
                              'spaceReservation.info.people',
                            )}`}
                          </strong>
                        </li>
                        <li>{`${t('spaceReservation.info.info1')}`}</li>
                        <li>{`${t('spaceReservation.info.info2')}`}</li>
                        <li>{`${t('spaceReservation.info.info3')}`}</li>
                        <li>{`${t('spaceReservation.info.info4')}`}</li>
                      </ul>
                    </RRInputInfo>
                  </RRRowElement>
                </RRRow>
              )}

              <RRRow>
                <RRRowElement>
                  <RRLabel>{`${t('common.words.observation')}`}</RRLabel>
                  <RRInput>
                    <textarea
                      id="obsText"
                      name="obsText"
                      rows={5}
                      value={observText}
                      onChange={e => setObservText(e.target.value)}
                    />
                  </RRInput>
                </RRRowElement>
              </RRRow>
              <RRRow style={{ justifyContent: 'center' }}>
                <Button
                  title={`${t(
                    'spaceReservation.reservationRequest.requestReservationButtonDescription',
                  )}`}
                  width={25}
                  icon="check"
                  onClick={validateForm}
                />
              </RRRow>
            </ContentContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default RequestReservation;
