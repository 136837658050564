import React, { useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';

import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import {
  Container,
  ListItemContainer,
  ListItemColumnLeft,
  ListItemColumnRight,
  ListItemRow,
  ListItemTitle,
  ListItemDataText,
  CardReadedPill,
  CardReadedPillText,
  CardMessageTypePill,
  CardMessageTypePillText,
  LoadMoreRef,
  LoadMoreText,
  AnimDots01,
} from './styles';

interface MessageListProps {
  assunto: string;
  dataEnvio: number;
  id: number;
  para: {
    id: number;
    nome: string;
  };
  status: {
    id: number;
    nome: string;
    statusApp: string;
  };
  tipo: {
    cor: string;
    id: number;
    nome: string;
  };
  visualizado: boolean;
}

const TalkCondoListMessages: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [cursor, setCursor] = useState('');
  const [hasMore, setHasMore] = useState(false);
  const [messageList, setMessageList] = useState<MessageListProps[]>([]);
  const [loadMore, setLoadMore] = useState(false);

  const { t } = useTranslation();
  const loadMoreRef = useRef(null);
  const history = useHistory();

  const getData = async (): Promise<void> => {
    const url = `/mensagem/list`;
    const body = {
      params: {
        cursor,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        if (res.status === 200) {
          setCursor(res.data.cursor);
          setHasMore(res.data.hasMore);
          setMessageList(oldList => [...oldList, ...res.data.list]);

          setLoading(false);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.data.error,
          icon: `warning`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const renderItem = (item: MessageListProps) => {
    return (
      <ListItemContainer
        onClick={() => {
          history.push({
            pathname: '/tcMessageDetails',
            state: {
              id: item?.id,
              activeTab: 1,
            },
          });
        }}
      >
        {!item?.visualizado && (
          <CardReadedPill>
            <FontAwesomeIcon color="var(--primary" icon={['fas', 'envelope']} />
            <CardReadedPillText>Não lido</CardReadedPillText>
          </CardReadedPill>
        )}
        <CardMessageTypePill pillColor={item.tipo.cor}>
          <FontAwesomeIcon color="var(--white)" icon={['fas', 'comment']} />
          <CardMessageTypePillText>{item?.tipo?.nome}</CardMessageTypePillText>
        </CardMessageTypePill>

        <ListItemColumnLeft>
          <ListItemRow>
            <ListItemTitle>{item?.assunto}</ListItemTitle>
          </ListItemRow>
          <ListItemRow>
            <FontAwesomeIcon icon={['fas', 'clock']} />
            <ListItemDataText>
              {dayjs(item?.dataEnvio).format('DD/MM/YYYY HH:mm')}
            </ListItemDataText>
          </ListItemRow>
          <ListItemRow>
            <>
              <FontAwesomeIcon icon={['fas', 'envelope']} />
              <ListItemDataText>{item?.para?.nome}</ListItemDataText>
            </>
          </ListItemRow>
        </ListItemColumnLeft>
        <ListItemColumnRight>
          <FontAwesomeIcon icon={['fal', 'chevron-right']} />
        </ListItemColumnRight>
      </ListItemContainer>
    );
  };

  useEffect(() => {
    getData();
    const options = {
      root: null,
      rootMargin: '20px',
      threshold: 0.1,
    };

    const observer = new IntersectionObserver(entities => {
      const target = entities[0];
      if (target?.isIntersecting) {
        setLoadMore(true);
      }
    }, options);

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }
  }, []);

  useEffect(() => {
    if (loadMore) {
      getData();
      setLoadMore(false);
    }
  }, [cursor, loadMore]);

  return (
    <Container>
      {loading ? (
        <LoadingCondomob />
      ) : (
        messageList.map(item => renderItem(item))
      )}

      <LoadMoreRef ref={loadMoreRef} visible={hasMore}>
        <AnimDots01 />
        <LoadMoreText>Carregando</LoadMoreText>
      </LoadMoreRef>
    </Container>
  );
};

export default TalkCondoListMessages;
