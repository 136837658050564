import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 1rem;
  flex: 1;
  flex-flow: row wrap;
  overflow-y: auto;
`;

// *** Specific of the page ***

export const ListContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  overflow-y: auto;
`;

export const ListItem = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  padding: 10px 20px;
  align-items: center;

  border: none;
  background-color: var(--white);

  :hover {
    background-color: #f9f9f9;
  }

  svg {
    :last-child {
      margin-left: auto;
    }
  }
`;

export const ListItemContent = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;

  text-align: left;

  strong {
    font-size: 1.2rem;
    color: var(--primary);
  }

  div {
    display: flex;
    margin: 0.5rem 0;

    svg {
      margin-right: 0.5rem;
    }

    span {
      color: ${props => props.color};
    }
  }
`;

export const ListItemVoted = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-right: 0.5rem;
    color: var(--elementOn);
  }

  strong {
    font-weight: 700;
    color: var(--elementOn);
  }
`;

export const BorderBottom = styled.div`
  position: absolute;
  width: 98%;
  border: none;
  border-bottom: solid 1px var(--borderExtraLight);
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
