import styled from 'styled-components';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const Container = styled.div`
  /* padding: 1rem; */
  overflow-x: hidden;
  overflow-y: auto;
`;

export const NewMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 1rem;
  margin: 1.5rem;

  border-radius: 0.4rem;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  padding: 3px 0px;
  margin: 2px 0px;

  label {
    color: var(--primary);
    font-weight: 700;
    font-size: 0.7rem;
    margin: 5px 0 5px 3px;
  }

  input,
  select,
  textarea {
    width: 100%;
    margin-left: 3px;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }

  textarea {
    resize: none;
  }
`;

export const NoticeBoard = styled.div<{ level: 'ok' | 'warning' | 'alert' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.3rem;

  background-color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBkg)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBkg)',
          'var(--noticeWarningBkg)',
        )};
  border: solid 0.5px
    ${props =>
      props.level === 'alert'
        ? 'var(--noticeAlertBorder)'
        : iff(
            props.level === 'ok',
            'var(--noticeOkBorder)',
            'var(--noticeWarningBorder)',
          )};
`;

export const NoticeBoardMessage = styled.text<{
  level: 'ok' | 'warning' | 'alert';
}>`
  font-weight: 700;
  margin-right: 0.5rem;
  color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertText)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkText)',
          'var(--noticeWarningText)',
        )};
`;

export const NoticeBoardButton = styled.button<{
  level: 'ok' | 'warning' | 'alert';
}>`
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-weight: 700;
  background-color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBtnBkg)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBtnBkg)',
          'var(--noticeWarningBtnBkg)',
        )};
  color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBtnText)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBtnText)',
          'var(--noticeWarningBtnText)',
        )};
  border: solid 0.5px
    ${props =>
      props.level === 'alert'
        ? 'var(--noticeAlertBtnBorder)'
        : iff(
            props.level === 'ok',
            'var(--noticeOkBtnBorder)',
            'var(--noticeWarningBtnBorder)',
          )};
`;

export const ExpiredText = styled.text<{
  expired: true | false;
}>`
  color: ${props => (props.expired ? '#dc3545' : '#3c608b')};
`;

export const ListItemDescription = styled.text<{
  expired: true | false;
}>`
  color: ${props => (props.expired ? '#dc3545' : '#3c608b')};
`;
