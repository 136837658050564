import styled from 'styled-components';

export const Container = styled.div``;

export const ButtonMenu = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px;
  background-color: transparent;
  border: none;

  svg {
    font-size: 1.5rem;
    color: var(--white);

    @media (max-width: 500px) {
      font-size: 1.2rem;
    }
  }
`;

export const DialogBalloon = styled.div`
  div {
    // position
    position: absolute;
    top: 4.5rem;
    left: 0.5rem;
    width: 11em;
    z-index: 100;

    // layout
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 0.3rem 0;
    background-color: #fff;
    min-height: 5rem;
    border-radius: 0.3rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);

    li {
      list-style-type: circle;
      margin-bottom: 0.5rem;
    }

    button {
      display: flex;
      align-items: center;
      height: 2rem;
      border: none;
      padding: 0.3rem;
      background-color: transparent;
      border-bottom: solid 0.5px #eee;

      svg {
        font-size: 1rem;
        color: var(--buttonText);
      }

      text {
        margin-left: 5px;
        font-size: 0.8rem;
      }

      :hover {
        cursor: pointer;
        background-color: rgba(60, 95, 139, 0.1);
        font-weight: 700;
      }
    }
  }

  div::before {
    // layout
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 3%; // offset should move with padding of parent
    border: 0.5rem solid transparent;
    border-top: none;

    // looks
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }

  .fadeView {
    animation: fadeIn linear 0.3s;
    -webkit-animation: fadeIn linear 0.3s;
    -moz-animation: fadeIn linear 0.3s;
    -o-animation: fadeIn linear 0.3s;
    -ms-animation: fadeIn linear 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const VersionApp = styled.text`
  font-weight: 700;
  color: var(--text-body);
  font-size: 0.5rem;
  align-self: center;
  margin-top: 3px;
`;
