import styled from 'styled-components';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const Container = styled.div`
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  border-radius: 0.25rem;
  border: 1px solid #f5f5f6;
  background-color: var(--itemBackground);
  overflow: hidden;
  padding: 1rem;
  margin: 1rem;
  align-self: center;
`;

export const TitleDescription = styled.p`
  text-align: justify;
  margin: 5px 0;
`;

export const AccessLinkDescription = styled.p`
  font-weight: bold;
  text-align: justify;
  margin: 5px 0;
`;

export const RulesContainer = styled.div`
  background-color: #d1ecf1;
  border: 0.0625rem solid #bee5eb;
  border-radius: 0.25rem;
  padding: 1rem;
`;

export const RulesDescription = styled.p`
  text-align: justify;
`;
