import styled from 'styled-components';
import Select from 'react-select';

export const ListMsg = styled.text`
  font-size: 1rem;
  color: #333;
  padding: 0px 1.5rem;
  margin: 0.5rem 10px;
  align-self: flex-start;
`;

export const CondoContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-radius: 0.4rem;
  padding: 0.5rem;
  margin: 1rem;
`;

export const MsgBox = styled.div`
  display: flex;
  width: 93%;
  flex-direction: column;
  margin: 15px 10px;
  padding: 10px;
  color: #333;
  border: solid 2px #2c9cef;
  position: relative;
`;

export const MsgBoxLabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.7rem;
`;

export const MsgBoxLabel = styled.text`
  background-color: #fff;
  text-align: center;
  font-weight: 700;
  color: #2c9cef;
  padding: 1px 5px;
`;

export const MsgBoxText = styled.text`
  font-weight: 400;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    font-size: 0.85rem;
  }
`;

export const MsgBoxTextBold = styled.text`
  font-weight: 700;
`;

export const MsgBoxButton = styled.button`
  width: 90%;
  height: 2rem;
  border-radius: 4px;
  align-self: center;
  background-color: var(--secundary);
  border: none;
  color: #fff;
  font-weight: 700;
  margin: 1rem 0;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 57vh;
  overflow-y: auto;
`;

export const FormUserData = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px;
  align-items: center;

  button {
    width: 50%;
    height: 2rem;
    border-radius: 4px;
    align-self: center;
    background-color: var(--secundary);
    border: none;
    color: #fff;
    font-weight: 700;
    margin: 20px 0px;
  }
`;

export const FormUserRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 3px 0px;
  margin: 2px 0px;

  label {
    color: var(--primary);
    font-weight: 700;
    font-size: 0.7rem;
    margin-left: 3px;
  }

  input,
  select,
  textarea {
    width: 100%;
    margin-left: 3px;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }

  textarea {
    height: 4rem;
  }
`;

export const CondoInfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 5px;
`;

export const CondoInfoName = styled.text`
  font-weight: 700;
  font-size: 1.2rem;
  margin: 15px 0px 10px;
`;

export const CondoInfoContact = styled.div`
  display: flex;
  margin: 2px 0px;
  line-height: 1.5rem;
  align-items: center;
  a {
    text-decoration: none;
    font-size: 0.9rem;
    font-weight: 400;
    margin-left: 5px;
    color: #333;
  }

  .whatsApp {
    color: #28a745;
  }

  .email {
    font-weight: 700;
  }

  p {
    font-size: 0.9rem;
    font-weight: 400;
    color: #333;
    margin-left: 5px;
  }
`;

export const SelectFormat = styled(Select).attrs({
  styles: {
    option: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    input: base => ({
      ...base,
      fontSize: '0.8rem',
      margin: 0,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      border: `solid 1px var(--primary)`,
      display: 'flex',
      height: 30,
      borderRadius: 5,
      fontSize: '0.9rem',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: base => ({
      color: 'var(--primary)',
    }),
  },
})`
  width: 100%;
  margin-left: 3px;
`;

export const ContactsGroup = styled.div`
  position: relative;
  display: flex;
  width: 80%;
  flex-direction: column;
  margin: 20px 5px 20px 12px;
  padding: 10px 0px;
  border: solid 2px #2c9cef;
  border-left: none;
  border-right: none;
  border-bottom: none;
`;

export const ContactsGroupLabel = styled.text`
  position: absolute;
  top: -15px;
  width: 50%;
  font-size: 18px;
  align-self: center;
  text-align: center;
  color: #2c9cef;
  background-color: #fff;
`;
