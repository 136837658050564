import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import ReactGA from 'react-ga';


import { firebaseConfig } from './config/config';

const app = firebase.initializeApp(firebaseConfig);
ReactGA.initialize(firebaseConfig.measurementId);

export const auth = app.auth()
export default app
