import styled from 'styled-components';
import logoGoogle from '../../assets/google-logo.svg';
import logoFacebook from '../../assets/facebook-logo.svg';
import logoApple from '../../assets/apple-logo.svg';

export const Container = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 3.8rem);

  /* background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: transparent !important;
  background-position: center; */

  @media (max-width: 500px) {
    height: calc(100vmax - 3.1rem);
  }
`;

export const BackGroundImg = styled.img`
  position: absolute;
  width: 100vw;
  height: calc(100vh - 3.9rem);
  /* filter: blur(1px); */

  z-index: 0;
`;

export const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
  width: 22rem;

  padding: 2rem 1rem;
  border-radius: 0.25rem;

  @media (max-width: 500px) {
    width: 18rem;
  }

  z-index: 0;
`;

export const Form = styled.form``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 0.7rem;
`;

export const RegisterContainer = styled.div`
  display: flex;

  justify-content: space-around;
  align-items: center;

  height: 3rem;
  width: auto;

  margin-top: 1rem;

  p,
  a {
    color: var(--secundary);
    font-size: 0.875rem;
    text-decoration: none;

    @media (max-width: 500px) {
      font-size: 0.8rem;
    }
  }
`;

export const SocialButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1rem 0 0 0;
`;

interface ProviderProps {
  provider: string;
}

export const SocialButton = styled.button`
  background: ${(props: ProviderProps) => {
    if (props.provider === 'google') {
      return `url(${logoGoogle})`;
    }
    if (props.provider === 'facebook') {
      return `url(${logoFacebook})`;
    }
    return `url(${logoApple})`;
  }};
  background-repeat: no-repeat;
  background-size: 6%;
  background-position: 1rem center;
  background-color: #eee;

  height: 2rem;
  width: 100%;
  padding-left: 6rem;

  font-weight: 700;
  color: var(--buttonText);

  border-radius: 1rem;
  border-width: 0.5px;
  border-color: var(--inputBorder);
  border-style: solid;

  transition: background-color 0.3s;

  text-align: left;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    font-size: 0.8rem;
    padding-left: 4rem;
  }

  &:hover {
    background-color: var(--primary);
    color: #fff;
  }

  & + button {
    margin-top: 0.5rem;
  }
`;

// ### Login screen customized by Admin

export const AdminPlateContainer = styled.div`
  position: absolute;
  display: flex;
  width: -webkit-fill-available;
  top: -16vh;
  left: 0px;

  padding: 0.5rem;
  gap: 0.5rem;

  justify-content: center;
  align-items: center;

  border-radius: 4px;
  /* background-color: var(--adminPlateBackground); */
  background-color: rgba(198, 213, 231, 0.9);
`;

export const AdminPlateLogo = styled.img`
  width: 5rem;
  height: 5rem;

  border-radius: 4px;
  background-color: var(--adminPlateBackground);
`;

export const AdminPlateInfoContainer = styled.a`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 5rem;

  border-radius: 4px;
  align-items: center;
  justify-content: center;

  padding: 0.3rem;

  background-color: var(--white);
`;

export const AdminPlateInfoName = styled.text`
  font-size: 11pt;
  font-weight: bold;
  color: var(--primary);
  text-align: center;
`;

export const AdminPlateInfoButton = styled.button`
  display: flex;
  width: 90%;
  height: 30px;
  align-items: center;
  justify-content: center;

  background-color: var(--white);
  color: var(--primary);

  border: none;
  border-radius: 15px;

  padding: 5px;

  transition: background-color 0.3s;

  svg {
    margin-right: 5px;
  }

  :hover {
    background-color: var(--waiting-background);
    color: var(--white);
  }
`;

// Modal elements

export const ContentContainer = styled.div`
  padding: 1rem;

  overflow-x: hidden;
  overflow-y: auto;
`;

export const ModalCloseButton = styled.button``;

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;

  border-radius: 0.3rem;
  background-color: var(--white);
`;

export const AdminLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdminLogoContent = styled.img`
  border-radius: 0.5rem;
  height: 10rem;
`;

export const AdminDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminName = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-body);
`;

export const AdminAddress = styled.text`
  font-weight: 700;
  font-size: 1rem;
  color: var(--text-body);
  margin: 5px 0;
`;

export const AdminContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminContactItem = styled.div`
  display: flex;
  width: 100%;
  height: 1.5rem;
  align-items: center;
  margin: 2px 5px 0 0;
  color: var(--secundary);
  font-weight: 700;

  svg {
    font-size: 1rem;
  }

  :hover {
    cursor: pointer;
    background-color: var(--itemBackground);
  }
`;

export const AdminContactData = styled.text`
  margin-left: 5px;
`;

export const AdminCondoInfoUnitsContent = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 1rem;
  width: -webkit-fill-available;
  margin-top: 1rem;
  flex-direction: column;
  overflow-y: auto;

  img {
    max-width: -webkit-fill-available;
    height: auto;
  }

  iframe {
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
`;
