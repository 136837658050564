import styled from 'styled-components';

export const Container = styled.div`
  color: var(--text-body);
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  padding: 1rem;
`;

// *** Specific of the page ***

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  min-height: 50vh;

  border-radius: 0.5rem;
  background-color: var(--itemBackground);
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  height: 3.2rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--backgroundHeaderDark);
  color: var(--white);
  border-bottom: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 50vh;
  overflow-y: auto;
  padding: 1rem;

  background-color: var(--white);

  strong {
    font-size: 1.5rem;
    color: var(--primary);
  }
`;

export const CardContentPollDate = styled.div<{ color: string }>`
  display: flex;
  margin: 0.5rem 0;

  svg {
    margin-right: 0.5rem;
  }

  span {
    color: ${props => props.color};
  }
`;

export const CardContentPollInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 0.5rem;

  p {
  }
`;

export const CardContentPollInfoPercentage = styled.div<{
  percentage: number;
  chosenOption: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0rem 0;
  background-color: ${props =>
    props.chosenOption && 'var(--poolChosenOptionBkg)'};
  border: ${props =>
    props.chosenOption && 'solid 1px var(--poolChosenOptionBorder)'};
  border-radius: 0.2rem;
  padding: 1rem;

  label {
    font-weight: 700;
    font-size: 0.8rem;
  }

  span {
    display: flex;
    width: 100%;
    height: 1.4rem;
    border: solid 1px #ccc;
    border-radius: 0.7rem;
    overflow: hidden;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 0.7rem;
      width: ${props => props.percentage}%;
      background-color: var(--primary);

      text {
        font-weight: 700;
        color: var(--white);
        font-size: 0.8rem;
      }
    }
  }

  p {
    font-size: 0.8rem;
  }
`;

export const CardContentPoolInfoVoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--pollVoted);
  border-radius: 0.2rem;
  width: -webkit-fill-available;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.8rem;

  p {
    margin-bottom: 0.3rem;
    strong {
      color: var(--white);
      font-size: 0.8rem;
    }
  }

  span {
    font-size: 0.7rem;
    color: var(--white);
  }
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  color: var(--text);
  overflow-y: auto;
  padding: 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }
`;

export const CardContentPollVotesInfo = styled.div<{ percentage: number }>`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.5rem 0 0.5rem 0;

    strong {
      font-size: 1rem;
      color: var(--textDark);
    }

    p {
      font-size: 0.7rem;
    }
  }

  span {
    display: flex;
    width: 100%;
    height: 1.4rem;
    border: solid 1px #ccc;
    border-radius: 0.7rem;
    overflow: hidden;

    div {
      align-self: center;
      height: 100%;
      width: ${props => props.percentage}%;
      background-color: var(--primary);
    }
  }
`;

export const CardContentPollSecretVote = styled.div`
  display: flex;
  margin: 0.5rem 0;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  height: 3.2rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--backgroundHeaderDark);
  color: var(--white);
  border-bottom: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;
