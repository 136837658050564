import React from 'react';

import { useTranslation } from 'react-i18next';

import { Container, Spinner } from './styles';

const LoadMore: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Spinner />
      <p>{`${t('common.phrases.loadingMore')}`}</p>
    </Container>
  );
};

export default LoadMore;
