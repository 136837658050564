import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: -webkit-fill-available;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const LoadingContainer = styled.div`
  width: 10rem;
  height: 10rem;
  margin-bottom: 20vh;
`;
