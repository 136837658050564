import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

// *** Specific of the page ***
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  overflow-y: auto;
`;

export const ContentContainer = styled.div`
  background-color: var(--white);
  border-radius: 0.4rem;
  padding: 1rem;
`;

export const RRHeader = styled.div`
  display: flex;
  width: -webkit-fill-available;
`;

export const RRHeaderImage = styled.img`
  display: flex;
  width: 7%;
  margin-right: 1rem;
`;

export const RRHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RRHeaderTitle = styled.text`
  font-size: 1.2rem;
  font-weight: 700;
`;

export const RRHeaderDate = styled.text``;

export const RRRow = styled.div`
  display: flex;
  margin: 1rem 0;
  width: -webkit-fill-available;
`;

export const RRRowColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`;

export const RRRowElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  width: 100%;
`;

export const RRTimePickerContainer = styled.div`
  max-width: 9rem;
`;

export const RRLabel = styled.text``;

export const RRInput = styled.div`
  display: flex;
  background-color: khaki;
  input,
  textarea {
    width: 100%;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }
`;

export const RRInputInfo = styled.div`
  display: flex;

  ul {
    padding-left: 1rem;
  }
`;

export const InputSelect = styled(Select).attrs({
  styles: {
    option: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    input: base => ({
      ...base,
      fontSize: '0.8rem',
      margin: 0,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      border: `solid 1px var(--primary)`,
      display: 'flex',
      height: 30,
      borderRadius: 5,
      fontSize: '0.9rem',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: () => ({
      color: 'var(--primary)',
    }),
  },
})`
  width: 30%;
`;
