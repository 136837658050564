import ReactModal from 'react-modal';
import styled from 'styled-components';
import { Logo } from '../../../components/Header/styles';

export const Modal = styled(ReactModal)`
  border-radius: 20px;
  background-color: var(--white);
  margin: auto auto;
  width: 30vw;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  justify-items: center;
  position: relative;
  font-size: 16px;

  @media (max-width: 1024px) {
    width: 50vw;
  }

  @media (max-width: 700px) {
    position: absolute;
    height: 300px;
    width: 100%;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    padding: 0;
  }

  @media (max-width: 500px) {
    padding: 0;
  }
`;

export const LogoImg = styled(Logo)`
  height: 90px;
  border-radius: 22px;

  margin: 0 auto;
  align-self: center;
  border: 4px solid var(--white);

  @media (max-width: 700px) {
    height: 95px;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
  }
`;

export const Content = styled.div`
  margin: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 1.4rem;
  margin: 2rem 1.5rem;

  @media (max-width: 700px) {
    margin: 2rem 4rem 0;
    padding: 0;
    gap: 1rem;
    justify-content: space-between;
  }

  @media (max-width: 500px) {
    margin: 2rem 2rem 0;
    padding: 0;
  }
`;

export const BadgeList = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 1rem;

  a {
    display: flex;
    align-content: center;
    align-items: center;
  }

  @media (max-width: 500px) {
    gap: 0.5rem;
  }
`;

export const Badge = styled.img`
  height: 50px;

  @media (min-width: 700px) {
    height: auto;
    width: 100%;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  text-align: center;
  display: block;
  white-space: break-spaces;
  overflow: hidden;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Button = styled.button`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;

  background: var(--primary);
  color: var(--white);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;
  text-decoration: none;

  border: none;
  border-radius: 2rem;
  padding: 1rem;
  margin: 0;
`;

export const BtnLink = styled.button`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;

  color: var(--primary);
  background: var(--white);
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-align: center;

  border: none;
  border-radius: 2rem;
  padding: 1rem 1rem;
  margin: 0;
`;
