import styled from 'styled-components';

export const Container = styled.header`
  background: var(--primary);

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid var(--gray);
  padding: 10px;
`;

export const ContainerElements = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerLeftElement = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerRightElement = styled.div`
  display: flex;
  /* display: inline-flex; */
  align-items: center;
  justify-content: center;
`;

export const ContainerCenterElement = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Title = styled.h2`
  color: var(--white);
  margin-left: 1rem;

  @media (max-width: 500px) {
    font-size: 1.2rem;
    margin-left: 0.7rem;
  }
`;

export const Logo = styled.img`
  height: 2.5rem;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    height: 1.8rem;
  }
`;
