import { useEffect, useState } from 'react';
import { Switch, Route, useHistory, Router } from 'react-router-dom';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

import TicketRoutes from './TicketRoutes';

import IndividualMessage from '../pages/IndividualMessage';
import IMReadPage from '../pages/IndividualMessage/ReadPage';

import Dashboard from '../pages/Dashboard';

import Documents from '../pages/Documents';
import DocAccountability from '../pages/Documents/Accountability';
import DocDocumentsContracts from '../pages/Documents/DocumentsContracts';
import DocMinutesMeeting from '../pages/Documents/MinutesMeeting';

import MyAdmin from '../pages/MyAdmin';

import NoticeBoard from '../pages/NoticeBoard';

import Polls from '../pages/Polls';
import DescriptionPoll from '../pages/Polls/DescriptionPoll';
import VoteInThePool from '../pages/Polls/VoteInPool';

import SpaceReservation from '../pages/SpaceReservation';
import SRRReservationDetail from '../pages/SpaceReservation/RequestReservation/ReservationDetail';
import SRRReservationAvailability from '../pages/SpaceReservation/RequestReservation/ReservationAvailability';
import SRRReservationRequest from '../pages/SpaceReservation/RequestReservation/ReservationRequest';
import SRReservationGuestList from '../pages/SpaceReservation/MyReservation/GuestList';
import SRReservationCancel from '../pages/SpaceReservation/MyReservation/CancelReservation';

import TalkCondo from '../pages/TalkCondo';
import tcMessageDetails from '../pages/TalkCondo/MessageDetails';

import VirtualAssemblies from '../pages/VirtualAssemblies';
import VATabs from '../pages/VirtualAssemblies/VATabs';
import VAVoteInfo from '../pages/VirtualAssemblies/VATabs/VAVoteList/VAVoteInfo';
import VAVote from '../pages/VirtualAssemblies/VATabs/VAVoteList/VAVoteInfo/VAVote';

import ListUnits from '../pages/LoginUnit';

import { useAuth } from '../contexts/AuthContext';
import { getAdm, storeAdm } from '../services/adm';

const PrivateRoutes = () => {
  const { userCondomob } = useAuth();
  const history = useHistory();
  const historyGA = createBrowserHistory();
  const [admParam, setAdmParam] = useState('');

  useEffect(() => {
    const admSessionId = getAdm();
    const queryParams = new URLSearchParams(history.location.search);
    const admQueryParam = queryParams.get('administradora');

    if (admQueryParam !== null) {
      storeAdm(admQueryParam);
      setAdmParam(`?administradora=${admQueryParam}`);
    }

    if (admSessionId !== null) {
      setAdmParam(`?administradora=${admSessionId}`);
    }
  }, []);

  historyGA.listen((location: any) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [historyGA]);

  return (
    <Router history={history}>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/listunits" component={ListUnits} />
        <Route path="/tickets" component={TicketRoutes} />
        <Route path="/dashboard" component={Dashboard} />
        <Route path="/documents" component={Documents} />
        <Route path="/dAccountability" component={DocAccountability} />
        <Route path="/dDocumentsContracts" component={DocDocumentsContracts} />
        <Route path="/dMinutesMeeting" component={DocMinutesMeeting} />
        <Route path="/individualMessage" component={IndividualMessage} />
        <Route path="/imReadPage" component={IMReadPage} />
        <Route path="/myadmin" component={MyAdmin} />
        <Route path="/noticeBoard" component={NoticeBoard} />
        <Route path="/polls" component={Polls} />
        <Route path="/descriptionPoll" component={DescriptionPoll} />
        <Route path="/voteInPool" component={VoteInThePool} />
        <Route path="/spaceReservation" component={SpaceReservation} />
        <Route path="/srrGuestList" component={SRReservationGuestList} />
        <Route path="/srrReservationCancel" component={SRReservationCancel} />
        <Route path="/srrReservationDetail" component={SRRReservationDetail} />
        <Route
          path="/srrReservationAvailability"
          component={SRRReservationAvailability}
        />
        <Route
          path="/srrReservationRequest"
          component={SRRReservationRequest}
        />
        <Route path="/talkCondo" component={TalkCondo} />
        <Route path="/tcMessageDetails" component={tcMessageDetails} />
        <Route path="/virtualAssemblies" component={VirtualAssemblies} />
        <Route path="/vaTabs" component={VATabs} />
        <Route path="/vaVoteInfo" component={VAVoteInfo} />
        <Route path="/vaVote" component={VAVote} />
        {Object.keys(userCondomob).length > 0 && history.push(`/${admParam}`)}
      </Switch>
    </Router>
  );
};

export default PrivateRoutes;
