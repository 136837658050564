import { useHistory } from 'react-router-dom';

import { useAuth } from '../../contexts/AuthContext';

import Language from '../Language';
import AppMenu from '../AppMenu';

import condomobLogo from '../../assets/logoCondomob_novo2021.svg';

import {
  Container,
  ContainerElements,
  Title,
  ContainerLeftElement,
  ContainerCenterElement,
  ContainerRightElement,
  Logo,
} from './styles';

const Header: React.FC = () => {
  const { userCondomob } = useAuth();
  const history = useHistory();

  return (
    <Container>
      <ContainerElements>
        <ContainerLeftElement>
          <AppMenu />
        </ContainerLeftElement>
        <ContainerCenterElement
          onClick={() => {
            if (Object.keys(userCondomob).length > 0) {
              history.push('/dashboard');
            }
          }}
        >
          <Logo src={condomobLogo} alt="Condomob Logo" />
          <Title translate="no">Condomob</Title>
        </ContainerCenterElement>
        <ContainerRightElement>
          <Language />
        </ContainerRightElement>
      </ContainerElements>
    </Container>
  );
};

export default Header;
