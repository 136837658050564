import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import QRCode from 'qrcode.react';

import { Button } from '../../../../../components/Button';
// import { Menu } from '../../../../../components/Menu';
import LoadingCondomob from '../../../../../components/LoadingCondomob';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  MessageHeader,
  MessageData,
  MessageComposition,
  MessageInstructions,
  QRCodeContainer,
  MessageCode,
  MessageCodeElement,
  MessageCodeNumberText,
  MessageShare,
  MessageHeaderDescription,
} from './styles';
import api from '../../../../../services/api';

interface TicketProps {
  dataVencimento: string;
  descricao: string;
  expirado: true;
  id: number;
  instrucao1: string;
  instrucao2: string;
  instrucao3: string;
  instrucao4: string;
  instrucao5: string;
  instrucao6: string;
  instrucao7: string;
  instrucao8: string;
  instrucoes: [string];
  itens: [
    {
      descricao: string;
      valor: number;
    },
  ];
  linhaDigitavel: string;
  link: string;
  nossoNumero: number;
  parcela: string;
  periodo: string;
  pix: string;
  status: {
    icone: string;
    descricao: string;
    descricaoCurta: string;
    cor: string;
    dias: number;
    vencida: boolean;
  };
  tipo: string;
  valor: string;
  vencimento: string;
}

const CTPaidReadMessage = (location: any) => {
  const [loading, setLoading] = useState(true);
  const [showQrCode, setShowQrCode] = useState(false);
  const [ticket, setTicket] = useState<TicketProps>({} as TicketProps);

  const { state } = location;
  const { item } = state;

  const history = useHistory();
  const { t } = useTranslation();

  const downloadFile = (fileURL: string): void => {
    if (fileURL?.length > 0) {
      window.open(fileURL);
    } else {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('common.phrases.fileNotFound')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    }
  };

  const sendByEmail = async id => {
    return api
      .get(`/financeiro/receberEmail`, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('tickets.copyTickets.ticketTitleModal')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        console.error('@sendNoticeByEmail', err.response.data.error);
        const str = `${t('tickets.copyTickets.ticketNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const renderComposition = () => {
    return ticket.itens.map((elem, index) => (
      <li className="item" key={index.toString()}>
        {elem.descricao}
      </li>
    ));
  };

  const renderInstructions = () => {
    return ticket.instrucoes.map((elem, index) => (
      <li className="item" key={index.toString()}>
        {elem}
      </li>
    ));
  };

  async function getData() {
    const url = '/financeiro/get';
    const body = {
      id: item.id,
    };
    await api
      .get(url, { params: body, timeout: 5000 })
      .then(res => {
        setTicket(res.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.push({
                  pathname: '/tickets',
                  state: { ...state, activeTab: 1 },
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('tickets.ticket')}`}</Title>
          </TitleContainer>
          <Content>
            <MessageHeader>
              <p className="title">{ticket?.descricao}</p>
              <MessageHeaderDescription color={ticket.status.cor}>
                {ticket.status?.descricao}
              </MessageHeaderDescription>
            </MessageHeader>
            <MessageData>
              <div>
                <p className="label">{`${t('tickets.maturity')}`}</p>
                <p className="data">{ticket?.dataVencimento}</p>
              </div>
              <div>
                <p className="labelRight">{`${t('tickets.value')}`}</p>
                <p className="dataRight">R$ {ticket?.valor}</p>
              </div>
            </MessageData>

            {ticket.itens?.length > 0 && (
              <MessageComposition>
                <p className="label">{`${t(
                  'tickets.copyTickets.composition',
                )}`}</p>
                <ul>{renderComposition()}</ul>
              </MessageComposition>
            )}

            {ticket.instrucoes?.length > 0 && (
              <MessageInstructions>
                <p className="label">{`${t(
                  'tickets.copyTickets.instructions',
                )}`}</p>
                <ul>{renderInstructions()}</ul>
              </MessageInstructions>
            )}

            {ticket?.pix?.length > 0 && (
              <MessageCode>
                <p className="label">Pix</p>
                <>
                  {showQrCode && (
                    <QRCodeContainer>
                      <QRCode value={ticket.pix} size={200} />
                    </QRCodeContainer>
                  )}
                  <MessageShare>
                    <Button
                      title={`${t('tickets.copyCode')}`}
                      model="new"
                      width={25}
                      icon="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(ticket.pix);
                      }}
                    />
                    <Button
                      title="QRCode"
                      model="new"
                      width={25}
                      icon="qrcode"
                      onClick={() => setShowQrCode(!showQrCode)}
                    />
                  </MessageShare>
                </>
              </MessageCode>
            )}
            <MessageCode>
              <p className="label">{`${t('tickets.copyTickets.barCode')}`}</p>
              {ticket?.linhaDigitavel?.length > 0 ? (
                <>
                  <MessageCodeElement>
                    <MessageCodeNumberText>
                      {ticket.linhaDigitavel}
                    </MessageCodeNumberText>
                    <Button
                      title={`${t('tickets.copyCode')}`}
                      model="new"
                      width={30}
                      icon="copy"
                      onClick={() => {
                        navigator.clipboard.writeText(ticket.linhaDigitavel);
                      }}
                    />
                  </MessageCodeElement>
                </>
              ) : (
                <p style={{ color: 'red' }}>{`${t('tickets.noTicket')}`}</p>
              )}
            </MessageCode>
            {!ticket?.expirado && ticket?.linhaDigitavel?.length > 0 && (
              <MessageShare>
                <Button
                  title={`${t('common.words.downloadFile')}`}
                  model="new"
                  width={25}
                  icon="file-download"
                  onClick={() => downloadFile(ticket.link)}
                />
                <Button
                  title={`${t('common.words.receiveByEmail')}`}
                  model="new"
                  width={25}
                  icon="envelope-open-dollar"
                  onClick={() => sendByEmail(ticket.id)}
                />
              </MessageShare>
            )}
          </Content>
        </Container>
      )}
    </>
  );
};

export default CTPaidReadMessage;
