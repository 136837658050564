import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
`;

export const TabItem = styled.button<{ activated: boolean }>`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: #fff;
  color: ${props => (props.activated ? 'var(--primary)' : 'var(--textDark)')};
  font-weight: ${props => (props.activated ? 700 : 400)};
  border: none;
  border-bottom: ${props =>
    props.activated
      ? 'solid 2px var(--primary)'
      : 'solid 2px var(--textDarkLight)'};

  :hover {
    cursor: pointer;
    background-color: var(--primaryBackground);
  }

  svg {
    margin-right: 1rem;
    font-size: 1.5rem;

    @media (max-width: 600px) {
      font-size: 0.8rem;
    }

    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }
`;

export const TabItemTitle = styled.text`
  font-size: 1rem;

  @media (max-width: 600px) {
    font-size: 0.8rem;
  }

  @media (max-width: 500px) {
    font-size: 0.6rem;
  }
`;
