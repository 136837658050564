import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import LoadingCondomob from '../../components/LoadingCondomob';
import { Input } from '../../components/Input';

import api from '../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  InputContainer,
  ListContainer,
  ListItem,
  BorderBottom,
} from './styles';

interface MessageProps {
  assunto: string;
  dataCancelamento: number;
  dataComunicado: number;
  dataVisualizacao: number;
  financeiro: boolean;
  html: string;
  id: number;
  link: string;
  perfil: string;
  usuario: string;
}

const IndividualMessage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [messageList, setMessageList] = useState<MessageProps[]>([]);
  const [messageCursor, setMessageCursor] = useState('');
  const [messageHasMore, setMessageHasMore] = useState(false);
  const [searchMessage, setSearchMessage] = useState('');
  const [messagesFiltered, setMessagesFiltered] = useState<MessageProps[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const getListMessages = async () => {
    return api
      .get(`/comunicado/list`, { params: { cursor: messageCursor } })
      .then(res => {
        if (res.status === 200) {
          setMessageList(res.data.list);
          setMessageCursor(res.data.cursor);
          setMessageHasMore(res.data.hasMore);
          setLoading(false);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });

        console.error('@getListMessages', err.response.data.error);
        setLoading(false);
      });
  };

  const listenToScroll = (e: any) => {
    if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight) {
      if (messageHasMore) {
        getListMessages();
      }
    }
  };

  const handleFilter = event => {
    const searchWord = event.target.value;
    setSearchMessage(searchWord);
    let newFilter = [] as any;
    newFilter = messageList.filter(value => {
      return value.assunto.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === '') {
      setMessagesFiltered(messageList);
    } else {
      setMessagesFiltered(newFilter);
    }
  };

  useEffect(() => {
    getListMessages();
  }, []);

  useEffect(() => {
    setMessagesFiltered(messageList);
  }, [messageList]);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('individualMessage.title')}`}</Title>
          </TitleContainer>

          <Content>
            <InputContainer>
              <Input
                type="text"
                name="termFindMessage"
                placeholder={`${t('common.words.search')}`}
                value={searchMessage}
                onChange={handleFilter}
                icon="search"
              />
            </InputContainer>
            <ListContainer onScroll={listenToScroll}>
              {messagesFiltered.length > 0 &&
                messagesFiltered.map(item => {
                  return (
                    <ListItem
                      key={item.id.toString()}
                      viewed={item.dataVisualizacao}
                    >
                      <button
                        type="button"
                        onClick={() => {
                          history.push({
                            pathname: '/imReadPage',
                            state: { item },
                          });
                        }}
                      >
                        <div>
                          <strong>{item.assunto}</strong>
                          <div>
                            <p>
                              {dayjs(item.dataComunicado).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}{' '}
                              -{' '}
                            </p>
                            <FontAwesomeIcon
                              icon={[
                                'fas',
                                item.dataVisualizacao !== 0
                                  ? 'envelope-open'
                                  : 'envelope',
                              ]}
                            />
                          </div>
                        </div>
                        <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                      </button>
                      <BorderBottom />
                    </ListItem>
                  );
                })}
            </ListContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default IndividualMessage;
