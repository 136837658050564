import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../components/LoadingCondomob';

import { useAuth } from '../../contexts/AuthContext';
import api from '../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  ContentContainer,
  ContentData,
  AdminLogoContainer,
  AdminLogoContent,
  AdminDataContainer,
  AdminName,
  AdminAddress,
  AdminContactContainer,
  AdminContactData,
  AdminContactItem,
  AdminCondoInfoUnitsContent,
} from './styles';

interface AdminInfoProps {
  coordenadas: {
    latitude: number;
    longitude: number;
  };
  emails: Array<string>;
  endereco: Array<string>;
  fones: Array<string>;
  imgLink: string;
  infoHtml: any;
  nome: string;
  site: string;
  whatsapps: Array<string>;
}

const MyAdmin: React.FC = () => {
  const [adminInfo, setAdminInfo] = useState<AdminInfoProps>(
    {} as AdminInfoProps,
  );
  const [loading, setLoading] = useState(true);

  const { userCondomob } = useAuth();
  const history = useHistory();
  const { t } = useTranslation();

  const getAdminInfo = async () => {
    return api
      .get(`/administradora/getInfo`, {
        params: { condominio: userCondomob.condominio.id },
      })
      .then(res => {
        setAdminInfo(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('@getAdminInfo', err);
        // setLoading(false);
      });
  };

  const sendWhatsApp = (phoneNumber: string) => {
    const numberFormated = phoneNumber.replace(/\D/g, '');
    window.open(`https://wa.me/55${numberFormated}`, '_blank');
  };

  const sendMail = (email: string) => {
    window.open(`mailto:${email}`, '_blank');
  };

  const openWebPage = (webpage: string) => {
    window.open(webpage, '_blank');
  };

  useEffect(() => {
    getAdminInfo();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <>
          <Container className="containerDefault">
            <TitleContainer>
              <TitleBackButton
                onClick={() => {
                  history.goBack();
                }}
              >
                <FontAwesomeIcon icon={['far', 'angle-left']} />
                {t('common.words.back')}
              </TitleBackButton>
              <Title>{t('myAdmin.title')}</Title>
            </TitleContainer>
            <ContentContainer>
              <ContentData>
                <AdminLogoContainer>
                  <AdminLogoContent
                    src={adminInfo.imgLink}
                    alt={adminInfo.nome}
                  />
                </AdminLogoContainer>
                <AdminDataContainer>
                  <AdminName>{adminInfo.nome}</AdminName>
                  <AdminAddress>
                    {adminInfo.endereco.map((info, index) => (
                      <p key={index.toString()}>{info}</p>
                    ))}
                  </AdminAddress>
                </AdminDataContainer>
                <AdminContactContainer key="2">
                  {adminInfo.whatsapps.map((item, index) => {
                    return (
                      <AdminContactItem
                        key={index.toString()}
                        onClick={() => {
                          sendWhatsApp(item);
                        }}
                      >
                        <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                        <AdminContactData>{item}</AdminContactData>
                      </AdminContactItem>
                    );
                  })}
                  {adminInfo.fones.map((item, index) => {
                    return (
                      <AdminContactItem key={index.toString()}>
                        <FontAwesomeIcon icon={['fas', 'phone']} />
                        <AdminContactData>{item}</AdminContactData>
                      </AdminContactItem>
                    );
                  })}
                  {adminInfo.emails.map((item, index) => {
                    return (
                      <AdminContactItem
                        key={index.toString()}
                        onClick={() => {
                          sendMail(item);
                        }}
                      >
                        <FontAwesomeIcon icon={['fas', 'envelope']} />
                        <AdminContactData>{item}</AdminContactData>
                      </AdminContactItem>
                    );
                  })}
                  {adminInfo.site !== null && (
                    <AdminContactItem
                      onClick={() => {
                        openWebPage(adminInfo.site);
                      }}
                    >
                      <FontAwesomeIcon icon={['fal', 'globe']} />
                      <AdminContactData>{adminInfo.site}</AdminContactData>
                    </AdminContactItem>
                  )}
                </AdminContactContainer>
                <div key="3">
                  <AdminCondoInfoUnitsContent
                    dangerouslySetInnerHTML={{ __html: adminInfo.infoHtml }}
                  />
                </div>
              </ContentData>
            </ContentContainer>
          </Container>
        </>
      )}
    </>
  );
};

export default MyAdmin;
