import styled from 'styled-components';

export const Container = styled.div``;

export const MsgBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40vw;
  height: 50vh;
  border-radius: 0.5rem;
  background-color: var(--white);

  padding: 2rem;

  align-self: center;
  margin-top: 20vh;

  strong {
    text-transform: uppercase;
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  p {
    font-size: 0.9rem;
    color: var(--text);

    text-align: justify;
    width: 80%;
    line-height: 1.3rem;
  }
`;

export const IconContent = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 5rem;
  height: 5rem;

  border-radius: 50%;

  margin: 1rem 0;

  background-color: #dbe3ed;

  svg {
    color: #295887;
    font-size: 2rem;
  }
`;

export const AcceptanceBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  div {
    display: flex;
    align-items: center;

    span {
      font-size: 1rem;
      color: var(--primary);
      text-transform: uppercase;
      margin-left: 0.3rem;
    }
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    border-radius: 0.3rem;
    background-color: var(--primary);
    border: none;

    margin-top: 1rem;
    padding: 0.5rem;

    font-weight: 700;
    color: var(--white);
  }
`;
