import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-height: 2.5rem;
  border-radius: 0.3rem;
  border: solid 1px var(--inputBorder);

  background-color: var(--white);

  overflow: hidden;

  svg {
    margin: 0 0.3rem;
  }
`;

export const InputText = styled.input`
  height: 2rem;
  width: 100%;
  padding: 0.8125rem;

  font-size: 0.8rem;

  border: none;
  outline: none;
  background-color: transparent;
`;

export const IconRight = styled.button`
  display: flex;
  background-color: var(--primary);

  align-items: center;
  justify-content: center;

  min-height: 100vh;
  width: 3vw;

  border: none;

  svg {
    font-size: 1rem;
    color: var(--white);
  }

  :hover {
    filter: brightness(0.9);
  }
`;
