import { useState, ReactElement, useEffect } from 'react';
import { Link, useHistory, RouteComponentProps } from 'react-router-dom';
import { Location } from 'history';
import { StaticContext } from 'react-router';
import { useTranslation } from 'react-i18next';

import Swal from 'sweetalert2';

import { auth } from '../../../firebase';

import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';

import {
  Card,
  Container,
  InputContainer,
  ButtonContainer,
  RegisterContainer,
} from './styles';

type LocationState = {
  from: Location;
  userEmail: string;
  userPassword: string;
};

// TypeScript Reference https://stackoverflow.com/questions/59752515/react-typescript-add-location-state-to-react-router-component
const SignUp = (
  props: RouteComponentProps<string, StaticContext, LocationState>,
): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [userLogin, setUserLogin] = useState({
    email: '',
    password: '',
    passwordConfirm: '',
  });

  const { location } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const signUp = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    return auth
      .createUserWithEmailAndPassword(email, password)
      .then((userCredential: any) => {
        userCredential.user.sendEmailVerification();
        if (userCredential.additionalUserInfo.isNewUser === true) {
          Swal.fire(
            `${t('common.words.success')}`,
            `${t('signup.signupSuccess')}`,
            'success',
          ).then(result => result.isConfirmed && history.push('/'));
        }
      })
      .catch((err: any) => {
        switch (err.code) {
          case 'auth/weak-password': {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('signup.signupWeakPass')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          case 'auth/email-already-in-use': {
            Swal.fire({
              icon: 'error',
              title: `${t('common.words.oops')}`,
              text: `${t('signup.signupEmailAlreadyInUse')}`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          case 'auth/invalid-email': {
            Swal.fire({
              icon: 'error',
              title: `${t('common.words.oops')}`,
              text: `${t('signup.signupInvalidEmail')}`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          default: {
            console.error('@handleSubmit', err.code);
          }
        }
      });
  };

  const handleSubmit = async (e: any): Promise<void> => {
    e.preventDefault();

    if (userLogin.password !== userLogin.passwordConfirm) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('signup.signupPassComparison')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (
      userLogin.email.trim() === '' ||
      userLogin.password.trim() === '' ||
      userLogin.passwordConfirm.trim() === ''
    ) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('signup.signupRequiredField')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    try {
      setLoading(true);
      signUp({ email: userLogin.email, password: userLogin.password });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: `${t('common.words.oops')}`,
        text: `${t('signup.signupRegisterError')}`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    }

    setLoading(false);
  };

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    setUserLogin({ ...userLogin, [name]: value });
  };

  useEffect(() => {
    if (location.state !== undefined) {
      const { userEmail, userPassword } = location?.state;
      setUserLogin({
        email: userEmail,
        password: userPassword,
        passwordConfirm: userPassword,
      });
    }
  }, [location.state]);

  return (
    <Container>
      <Card>
        <InputContainer>
          <Input
            type="text"
            placeholder={`${t('common.words.email')}`}
            value={userLogin.email}
            name="email"
            onChange={handleOnChange}
          />
          <Input
            type="password"
            placeholder={`${t('common.words.password')}`}
            value={userLogin.password}
            name="password"
            onChange={(event: any) => {
              handleOnChange(event);
            }}
          />
          <Input
            type="password"
            placeholder={`${t('signup.signupPassConfirm')}`}
            value={userLogin.passwordConfirm}
            name="passwordConfirm"
            onChange={(event: any) => {
              handleOnChange(event);
            }}
          />
        </InputContainer>
        <ButtonContainer>
          <Button
            onClick={handleSubmit}
            title={`${t('signup.signupCreateAccount')}`}
            disabled={loading}
            model="default"
          />
        </ButtonContainer>
        <RegisterContainer>
          <Link to="/">{`${t('signup.signupBack')}`}</Link>
        </RegisterContainer>
      </Card>
    </Container>
  );
};

export default SignUp;
