import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  color: var(--text-body);
  font-size: 0.9rem;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  padding: 0.5rem 1rem;
`;

// *** Specific of the page ***

export const InputContainer = styled.div`
  display: flex;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  gap: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const ListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ListItem = styled.div<{ viewed: number }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    display: flex;
    width: 100%;
    padding: 10px 20px;
    /* display: block; */
    align-items: center;
    text-decoration: none;
    border: none;
    background-color: var(--white);

    &:hover {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 2px;
      margin: 0px 0px 0px 15px;
    }

    div {
      strong {
        font-size: 1.2rem;
        color: var(--primary);

        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }

      div {
        display: flex;
        flex-direction: row;
        width: 9rem;
        align-items: baseline;

        p {
          font-size: 0.8rem;
          color: var(--primary);
          margin-top: 4px;

          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }

        svg {
          color: ${props =>
            props.viewed !== 0 ? 'var(--elementOn)' : 'var(--elementGray)'};
        }
      }
    }

    svg {
      margin-left: auto;
      color: #999;
      font-size: 0.8rem;
      margin-left: auto;
    }
  }
`;

export const BorderBottom = styled.div`
  position: absolute;
  width: 98%;
  border: none;
  border-bottom: solid 1px var(--borderExtraLight);
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
