import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

// *** Specific of the page ***

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const CalendarDaySelected = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: center;
  align-self: center;

  strong {
    font-size: 1.2rem;
    font-weight: 700;
    margin: 1rem 0;
  }
`;

export const ListReservationsDayContainer = styled.div`
  display: flex;
  flex-flow: row wrap;

  margin: 1rem 0;
  padding: 0.5rem;

  min-height: 1rem;
`;

export const ListReservationsDayItem = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 0.5rem;
  border: 1px solid var(--borderExtraLight);
  background-color: var(--white);
  overflow: hidden;

  margin: 0.2rem;
  padding: 0.5rem;

  min-height: 1rem;
  min-width: 10rem;
  max-width: 18rem;
  width: 100%;

  :hover {
    -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  }
`;

export const ListReservationDayItemRow = styled.div`
  position: relative;
  display: flex;

  width: -webkit-fill-available;
  min-height: 1rem;

  align-items: center;

  padding: 0.2rem;

  font-size: 0.8rem;

  svg {
    margin-right: 0.5rem;
    font-size: 1rem;
    max-width: 1rem;
    min-width: 1rem;
  }

  p {
    font-weight: bold;
    color: var(--americanGray);
  }

  span {
    color: var(--americanGray);
  }
`;

export const ListReservationStatusContainer = styled.div<{ status: string }>`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 20px;

  padding: 3px 10px;
  border-radius: 20px;
  align-items: center;
  justify-content: center;

  background-color: var(--success);
`;

export const ListReservationDayStatus = styled.text`
  margin-left: 0.3rem;

  font-weight: 700;
  color: var(--white);
`;

export const CalendarSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 5px;

  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CalendarSubtitleItemContainer = styled.div`
  display: flex;
  padding: 3px;

  align-items: center;

  margin-right: 1rem;
`;

export const CalendarSubtitleItemColor = styled.div<{
  color: string;
  borderColor: string;
}>`
  min-width: 16px;
  min-height: 16px;
  border-radius: 8px;

  margin-right: 5px;

  border: solid 1px ${props => `var(--${props.borderColor})`};
  background-color: ${props => `var(--${props.color})`};
`;

export const CalendarSubtitleItemInfo = styled.text`
  font-size: 0.8rem;
  color: var(--dark);
`;
