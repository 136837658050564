import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: var(--white);
  border-radius: 0.4rem;
`;

// *** Specific of the page ***

export const ReservationInfo = styled.div`
  display: flex;
  flex-direction: column;
  p {
    svg {
    }
  }

  strong {
    font-size: 1.3rem;
  }
`;

export const CRRow = styled.div`
  display: flex;
  margin: 1rem 0;
  width: -webkit-fill-available;
`;

export const CRRowElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  width: 100%;
`;

export const CRLabel = styled.text``;

export const CRInput = styled.div`
  display: flex;
  background-color: khaki;
  margin-bottom: 1rem;

  textarea {
    width: 100%;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }
`;

export const TalkToAdmTitle = styled.text`
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 20px;
  text-align: center;
  color: var(--americanGray);
  p {
    margin-top: 10px;
  }

  svg {
    color: var(--danger);
  }
`;

export const TalkToAdmContainer = styled.div`
  align-items: center;
  background-color: var(--white);
  padding: 0;

  .alert {
    border-radius: 6px;
    padding: 10px;
    margin: 10px 0px;
    flex-direction: row;
    align-items: center;
    background-color: var(--danger25);
    color: var(--danger);
    font-weight: bold;
    font-size: 1rem;

    span {
      margin: 5px;
    }
  }

  p {
    color: var(--americanGray);
    text-align: center;
    margin: 20px;
    padding: 0;
    font-size: 0.9rem;
  }
`;
