import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h2,
  p,
  strong {
    color: var(--text-body);
  }

  p {
    font-size: 0.9rem;
  }
`;

export const ImgApp = styled.img`
  width: 100%;
  height: 20vh;
`;
