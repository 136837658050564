import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AppCommonContext } from '../../contexts/AppCommonContext';
import LoadingCondomob from '../../components/LoadingCondomob';

import { Button } from '../../components/Button';

import api from '../../services/api';

import {
  Container,
  LineRow,
  TitleContainer,
  TitleBackButton,
  Title,
  ListContainer,
  ListItem,
  ListItemContent,
  ListItemStatus,
  ListItemAssemblyProps,
} from './styles';

interface AssmebliesListProps {
  categoria: string;
  dataFim: string;
  dataInicio: string;
  formato: string;
  id: number;
  mensagemPresente: boolean;
  registraPresencaApp: boolean;
  status: {
    aberta: boolean;
    botao: string;
    cor: string;
    descricao: string;
  };
  titulo: string;
  unidadePresente: boolean;
  votantePresente: boolean;
}

// let arrayList: AssmebliesListProps[] = [];

const VirtualAssemblies: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [assembliesList, setAssembliesList] = useState<AssmebliesListProps[]>(
    [],
  );
  const [listCursor, setListCursor] = useState('');
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();
  const { setAssemblyUserPresence } = useContext(AppCommonContext);

  const getAssembliesList = async (): Promise<void> => {
    setRefreshing(true);
    setButtonDisabled(true);

    const url = `/assembleia/list/`;
    const body = {
      params: {
        cursor: listCursor,
      },
    };

    return api
      .get(url, body)
      .then(res => {
        console.info('@AssemblyInfo', res.data);
        setListCursor(res.data.cursor);
        setHasMoreItems(res.data.hasMore);
        // eslint-disable-next-line no-shadow
        setAssembliesList(assembliesList => [
          ...assembliesList,
          ...res.data.list,
        ]);
        setLoading(false);
        setRefreshing(false);
        setButtonDisabled(false);
      })
      .catch(err => {
        console.error('@getAssembliesList', err.response.data.error);
        setLoading(false);
        setRefreshing(true);
        setButtonDisabled(false);
      });
  };

  const listenToScroll = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight * 1.15
    ) {
      if (!refreshing && hasMoreItems) {
        getAssembliesList();
      }
    }
  };

  useEffect(() => {
    setRefreshing(true);
    getAssembliesList();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.replace({
                  pathname: '/dashboard',
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('virtualAssemblies.title')}</Title>
          </TitleContainer>
          <ListContainer onScroll={listenToScroll}>
            {assembliesList.length > 0 &&
              assembliesList.map(item => {
                return (
                  <ListItem
                    key={item.id.toString()}
                    onClick={() => {
                      setAssemblyUserPresence(item?.unidadePresente);
                      history.push({
                        pathname: '/vaTabs',
                        state: {
                          activeTab: 0,
                          assemblyEndTime: item?.dataFim,
                          assemblyId: item.id,
                          assemblyStartTime: item?.dataInicio,
                          mensagemPresente: item?.mensagemPresente,
                          registraPresencaApp: item?.registraPresencaApp,
                          unidadePresente: item?.unidadePresente,
                          votantePresente: item?.votantePresente,
                        },
                      });
                    }}
                  >
                    <ListItemContent>
                      <strong>{item.titulo}</strong>
                      <div>
                        <FontAwesomeIcon icon={['fas', 'calendar']} />
                        <p>
                          {item.dataInicio} a {item.dataFim}
                        </p>
                      </div>
                      <LineRow>
                        <ListItemAssemblyProps>
                          <FontAwesomeIcon
                            icon={['fas', 'exclamation-circle']}
                          />
                          <p>{item.categoria}</p>
                          {item.formato === ''}
                          <FontAwesomeIcon
                            icon={[
                              'fas',
                              item?.formato === 'Presencial'
                                ? 'person'
                                : item?.formato === 'Híbrida'
                                ? 'screen-users'
                                : 'video-arrow-up-right',
                            ]}
                          />
                          <p>{item?.formato}</p>
                        </ListItemAssemblyProps>
                        <ListItemStatus statusCor={item.status.botao}>
                          {item.status.descricao}
                        </ListItemStatus>
                      </LineRow>
                    </ListItemContent>

                    <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                  </ListItem>
                );
              })}
            {hasMoreItems && (
              <Button
                title={`${t('common.words.loadMore')}`}
                model="default"
                width={25}
                icon="plus-circle"
                onClick={listenToScroll}
                disabled={buttonDisabled}
              />
            )}
          </ListContainer>
        </Container>
      )}
    </>
  );
};

export default VirtualAssemblies;
