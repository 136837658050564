import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

import LoadingCondomob from '../../../components/LoadingCondomob';
import InvisibleItem from '../../../components/InvisibleItem';

import { useAuth } from '../../../contexts/AuthContext';

import imgDefault from '../../../assets/noImage.svg';

import api from '../../../services/api';

import {
  Container,
  CardPool,
  CardContainer,
  CardContent,
  CardFooter,
  CardFooterRow,
  CardFooterSpaceTitle,
  CardFooterValue,
  CardFooterTotalReserved,
} from './styles';

interface SpacesListProps {
  ativo: boolean;
  capacidade: number;
  descricao: string;
  id: number;
  imgLink: string;
  motivoReserva: boolean;
  nome: string;
  reservaMorador: boolean;
  taxa: number;
}

const RequestReservation: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [spacesList, setSpacesList] = useState<SpacesListProps[]>([]);

  const history = useHistory();

  const { userCondomob } = useAuth();

  const getSpaces = async (): Promise<void> => {
    return api
      .get(`/reservaEspaco/listEspacos`)
      .then(res => {
        if (res.status === 200) {
          setSpacesList(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        console.error('@getSpaces', err.response.data.error);
      });
  };

  useEffect(() => {
    getSpaces();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <CardPool>
            {spacesList.map(item => {
              return (
                item.ativo && (
                  <CardContainer
                    key={item.id.toString()}
                    onClick={() => {
                      history.push({
                        pathname: '/srrReservationDetail',
                        state: { item },
                      });
                    }}
                    className="cardDefault"
                  >
                    <CardContent>
                      <img
                        src={item.imgLink === '' ? imgDefault : item.imgLink}
                        alt={item.nome}
                      />
                    </CardContent>
                    <CardFooter>
                      <CardFooterSpaceTitle>{item.nome}</CardFooterSpaceTitle>
                      <CardFooterRow>
                        <CardFooterValue>
                          <FontAwesomeIcon icon={['far', 'dollar-sign']} />-{' '}
                          {`R$ ${item.taxa
                            .toFixed(2) // always two decimal digits
                            .replace('.', ',') // replace decimal point character with ,
                            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} `}
                        </CardFooterValue>
                        <CardFooterTotalReserved>
                          <FontAwesomeIcon icon={['far', 'users']} />
                          {item.capacidade}
                        </CardFooterTotalReserved>
                      </CardFooterRow>
                    </CardFooter>
                  </CardContainer>
                )
              );
            })}
            <InvisibleItem width={30} />
          </CardPool>
        </Container>
      )}
    </>
  );
};

export default RequestReservation;
