import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  display: flex;
  overflow-y: auto;
`;

// *** Specific of the page ***
export const CardPool = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: row wrap;
  gap: 3rem;
  padding: 2rem;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 23rem;
  height: 70vh;
  border-radius: 0.5rem;
  border: 1px solid #f5f5f6;
  background-color: var(--itemBackground);
  overflow: hidden;

  :hover {
    -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  align-self: center;
  padding: 0.5rem 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContentImage = styled.div`
  display: flex;
  width: 100%;
  height: 30%;
  justify-content: center;
  img {
    object-fit: contain;
  }

  :hover {
    cursor: pointer;
  }
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  color: var(--text);
  overflow-y: auto;
  padding: 0 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
    overflow-y: auto;
  }
`;

export const CardContentTextPostDate = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContentLink = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  margin: 0.5rem 0;
`;

export const CardFooter = styled.div`
  display: flex;
  height: 3.2rem;
  align-items: center;
  width: -webkit-fill-available;
  background-color: var(--backgroundHeaderDark);
  color: var(--white);
  border-top: solid 1px var(--inputBorder);
`;

export const CardFooterAuthor = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

export const SharePostContainer = styled.div`
  display: flex;
  padding: 0 1rem;
  button {
    :first-child {
      margin-right: 0.5rem;
    }
  }
`;
