import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CondoImgDefault from '../../../assets/CondoImgDefault.jpg';
import imgEmptyResult from '../../../assets/noCondList.png';

import LoadingCondomob from '../../../components/LoadingCondomob';
import { Input } from '../../../components/Input';

import api from '../../../services/api';
import { useAuth } from '../../../contexts/AuthContext';

import {
  Container,
  FilterContainer,
  ListContainer,
  ListItem,
  NoItemMessage,
  NoItemImage,
} from '../styles';

interface RouteProps {
  state: any;
}

interface CondoProps {
  esconder: boolean;
  imgLink: string;
  administradora: {
    imgLink: string;
    nome: string;
  };
  nome: string;
}

const LoginUnitCondo: React.FC = () => {
  const [condos, setCondos] = useState<CondoProps[]>([]);
  const [filteredCondo, setFilteredCondo] = useState<CondoProps[]>([]);
  const [searchCondo, setSearchCondo] = useState('');
  const [loading, setLoading] = useState(true);

  const location: RouteProps = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const { item } = location.state;
  const { adminLinkIntegrationId } = useAuth();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleFilter = event => {
    const searchWord = event.target.value;

    setSearchCondo(searchWord);

    let newFilter = [] as any;

    if (searchWord.length > 2) {
      newFilter = condos.filter(cond =>
        cond.nome
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\W\u0300-\u036f-^(.+?)/*?$]/g, '')
          .includes(
            searchWord
              .trim()
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\W\u0300-\u036f-^(.+?)/*?$]/g, ''),
          ),
      );
    }

    setFilteredCondo(newFilter);
  };

  const getCondos = async () => {
    return api
      .get(`/condominio/list`, {
        params: {
          cidade: item.id,
          administradora: adminLinkIntegrationId,
        },
      })
      .then(res => {
        setCondos(res.data);
        setLoading(false);
        searchInputRef.current?.focus();
      })
      .catch(err => {
        setLoading(false);
        console.error('@LoginUnitCondo', err);
      });
  };

  const handleNavigation = condo => {
    history.push({
      pathname: '/listunitinfo',
      state: { condo },
    });
  };

  useEffect(() => {
    if (Object.keys(item).length > 0) {
      getCondos();
    }
  }, [item]);

  return (
    <Container className="containerDefault">
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            <Input
              refInput={searchInputRef}
              name="termSearch"
              type="text"
              placeholder={t('loginUnitCondo.FindItemInput.placeholder')}
              value={searchCondo}
              onChange={handleFilter}
              icon="search"
            />
          </FilterContainer>
          <ListContainer style={{ maxHeight: '100vh' }}>
            {Object.keys(filteredCondo).length === 0 ? (
              <>
                <NoItemImage src={imgEmptyResult} alt="empty result" />
                <NoItemMessage>
                  Digite o nome do seu condomínio.{' '}
                  <strong>Caso não encontre-o</strong> entre em contato com sua
                  administradora.
                </NoItemMessage>
              </>
            ) : (
              filteredCondo.map((condo, index) => {
                return (
                  <ListItem
                    key={index.toString()}
                    hideCondo={condo?.esconder}
                    searchLength={searchCondo?.length}
                  >
                    <button
                      type="button"
                      onClick={() => handleNavigation(condo)}
                    >
                      <img
                        src={
                          condo.imgLink !== '' ? condo.imgLink : CondoImgDefault
                        }
                        alt={condo.nome}
                      />
                      <div>
                        <strong>{condo?.nome}</strong>
                      </div>
                      <FontAwesomeIcon
                        icon={['far', 'chevron-right']}
                        size="sm"
                        color="#999"
                        style={{ marginLeft: 'auto' }}
                      />
                    </button>
                  </ListItem>
                );
              })
            )}
          </ListContainer>
        </>
      )}
    </Container>
  );
};

export default LoginUnitCondo;
