export const KEYS = {
  UNITS_KEY: '@CondomobWeb:units',
};

class StorageService {
  private static storage = localStorage;

  static set(key: string, data: unknown[] | unknown): void {
    this.storage.setItem(key, JSON.stringify(data));
  }

  static get(key: string): unknown | null {
    const result = this.storage.getItem(key);
    return result ? JSON.parse(result) : null;
  }

  static remove(key: string): void {
    this.storage.removeItem(key);
  }
}

export default StorageService;
