import styled from 'styled-components';

export const Container = styled.div`
  color: var(--text-body);
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

// *** Specific of the page ***

export const ReservationInfo = styled.div`
  display: flex;
  flex-direction: column;
  p {
    svg {
    }
  }

  strong {
    font-size: 1.3rem;
  }
`;

export const GLRow = styled.div`
  display: flex;
  margin: 1rem 0;
  width: -webkit-fill-available;
`;

export const GLRowElement = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
  width: 100%;
`;

export const GLLabel = styled.text``;

export const GLInput = styled.div`
  display: flex;
  background-color: khaki;
  textarea {
    width: 100%;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }
`;

export const GLInputInfo = styled.div`
  display: flex;
  margin: 0.5rem 0;

  ul {
    padding-left: 1rem;
  }
`;
