import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { toast, ToastContainer } from 'react-toastify';
import qs from 'qs';

import Swal from 'sweetalert2';
import { Button } from '../../../../../components/Button';
import LoadingCondomob from '../../../../../components/LoadingCondomob';

import { AppCommonContext } from '../../../../../contexts/AppCommonContext';

import protocolVoteBkg from '../../../../../assets/protocolVoteBkg.svg';

import api from '../../../../../services/api';

import {
  Container,
  LineRow,
  TitleContainer,
  TitleBackButton,
  Title,
  CardContainer,
  CardContent,
  CardContentPollDate,
  CardContentPollInfoContainer,
  CardContentPollInfoQuestion,
  CardContentPollInfoOptions,
  CardContentPollMessage,
  CardContentPollMessageButton,
  CardContentPollNotVoted,
  CardContentPollInfoPercentage,
  CardContentPollInfoTitle,
  CardContentPollVotedProtocol,
  CardContentPollVotedProtocolData,
  CardContentPollVotedProtocolLineLabel,
  CardContentPollVotedProtocolLineinfo,
  CardFooter,
  CardContentPollCanceVoteButton,
} from './styles';

interface ItemDataProps {
  id: number;
}

interface PollDataOptionsProps {
  id: number;
  opcao: string;
  percentual: number;
  peso: number;
  votos: number;
}

type VAVoteInfoAssemblyBlockProps = {
  descricao: string;
  fileGcs: boolean;
  fileName?: string;
  html: string;
  id: number;
  idAviso: number;
  link: string;
  ordem: number;
  tipo: {
    codigo: string;
    id: number;
    nome: string;
  };
};

type SurveyVoteDataOptionsProps = {
  id: number;
  opcao: string;
  percentual: number;
  votos: string;
};

type SurveyVoteProps = {
  dataVoto: number;
  dvcAddress: string;
  dvcManufacturer: string;
  dvcModel: string;
  dvcPlatform: string;
  dvcPlatformVersion: string;
  id: number;
  opcoes: Array<number>;
  userEmail: string;
};

type SurveyVoteDataProps = {
  blocos: Array<VAVoteInfoAssemblyBlockProps>;
  dataAtualizacao: number;
  dataEnquete: string;
  dataFim: string;
  dataInicio: string;
  id: number;
  maximoEscolhas: number;
  minimoEscolhas: number;
  opcoes: Array<SurveyVoteDataOptionsProps>;
  percentualVotos: number;
  pergunta: string;
  por: string;
  status: {
    cor: string;
    descricao: string;
    encerrada: boolean;
  };
  titulo: string;
  totalUnidades: number;
  totalVotos: number;
  unidadeVotou: boolean;
  voto: SurveyVoteProps;
  votoAberto: boolean;
  votoCancelavel: boolean;
  votoVinculado: boolean;
};

const VAVoteInfo: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [surveyVoteData, setSurveyVoteData] = useState<SurveyVoteDataProps>(
    {} as SurveyVoteDataProps,
  );

  const {
    pollId,
    assemblyId,
    assemblyEndTime,
    assemblyStartTime,
    mensagemPresente,
    registraPresencaApp,
    unidadePresente,
    votantePresente,
  } = location.state;

  const { assemblyUserPresence, setAssemblyUserPresence } =
    useContext(AppCommonContext);
  const history = useHistory();
  const { t } = useTranslation();

  async function vaVoteInfoHandleUnitAssemblyPresence() {
    const body = {
      assembleia: assemblyId,
    };
    const url = `assembleia/presencaUnidade/save`;

    await api
      .post(url, qs.stringify(body))
      .then(() => {
        setAssemblyUserPresence(true);
        toast.success(`Presença registrada com sucesso!!`, {
          closeOnClick: true,
          autoClose: 3000,
          hideProgressBar: false,
          draggable: true,
          pauseOnHover: true,
          progress: undefined,
          position: 'top-center',
        });
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          icon: `error`,
          text: error,
          backdrop: `rgba(0,0,0,0.9)`,
          title: `${t('common.words.oops')}`,
        });
      });
  }

  function releaseAssemblyUnityPresence() {
    if (
      !assemblyUserPresence &&
      registraPresencaApp &&
      votantePresente &&
      dayjs(assemblyStartTime, 'DD-MM-YYYY HH:mm').diff(Date.now()) < 0 &&
      dayjs(assemblyEndTime, 'DD-MM-YYYY HH:mm').diff(Date.now()) > 0
    ) {
      Swal.fire({
        title: `Atenção!`,
        html: `<p>Você deseja registrar a sua presença na Assembleia?</p></br><p ${
          !votantePresente && `hidden`
        }>• Obrigatório para votar.</p>`,
        icon: `question`,
        showCancelButton: true,
        confirmButtonText: `${t('common.words.yes')}`,
        confirmButtonColor: `#3c5f8b`,
        cancelButtonText: `${t('common.words.no')}`,
        cancelButtonColor: '#6c757d',
        reverseButtons: true,
        backdrop: `rgba(0,0,0,0.9)`,
      }).then(result => {
        if (result.isConfirmed) {
          vaVoteInfoHandleUnitAssemblyPresence();
        } else {
          return null;
        }

        return true;
      });
    }
  }

  async function getSurveyVoteData(): Promise<void> {
    const url = '/enquete/get';
    const body = {
      id: pollId,
    };

    await api
      .get(url, { params: body })
      .then(res => {
        setSurveyVoteData(res.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  async function handleUserCancelVote() {
    const url = `/enquete/cancelarVoto`;
    const body = {
      id: surveyVoteData?.voto.id,
    };

    await api
      .post(url, qs.stringify(body))
      .then(() => {
        toast.success(`Seu voto foi cancelado com sucesso!`, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        getSurveyVoteData();
      })
      .catch(err => {
        setLoading(false);
        console.error('@handleUserCancelVote', err);
        // const error = err.data.error
        //   ? err.data.error
        //   : `Ops! Algo não saiu como esperado!`;
        // Swal.fire({
        //   title: `${t('common.words.oops')}`,
        //   text: error,
        //   icon: `error`,
        //   backdrop: `rgba(0,0,0,0.9)`,
        // });
      });
  }

  useEffect(() => {
    getSurveyVoteData();
  }, []);

  useEffect(() => {
    if (Object.keys(surveyVoteData).length > 0) {
      setLoading(false);
      releaseAssemblyUnityPresence();
    }
  }, [surveyVoteData]);

  return loading ? (
    <LoadingCondomob />
  ) : (
    <>
      <TitleContainer>
        <TitleBackButton
          onClick={() => {
            // history.goBack();
            history.push({
              pathname: '/vaTabs',
              state: {
                assemblyId,
                assemblyEndTime,
                assemblyStartTime,
                mensagemPresente,
                registraPresencaApp,
                unidadePresente,
                votantePresente,
                activeTab: 2,
              },
            });
          }}
        >
          <FontAwesomeIcon icon={['far', 'angle-left']} />
          {`${t('common.words.back')}`}
        </TitleBackButton>
        <Title>Assembleias Virtuais - Votação</Title>
      </TitleContainer>
      <Container>
        {Object.keys(surveyVoteData).length > 0 && (
          <CardContainer>
            <CardContent>
              <>
                <strong>{surveyVoteData.titulo}</strong>
                <CardContentPollDate
                  encerrada={surveyVoteData.status.encerrada}
                >
                  <FontAwesomeIcon icon={['fas', 'calendar']} />
                  {surveyVoteData.dataInicio} a {surveyVoteData.dataFim} -{' '}
                  <span>&nbsp;{surveyVoteData.status.descricao}</span>
                </CardContentPollDate>
              </>
              <CardContentPollInfoContainer>
                <CardContentPollInfoQuestion
                  dangerouslySetInnerHTML={{ __html: surveyVoteData.pergunta }}
                />
                <CardContentPollInfoOptions>
                  {dayjs(assemblyStartTime, 'DD/MM/YYYY HH:mm', 'pt-br').diff(
                    Date.now(),
                  ) < 0 &&
                  dayjs(assemblyEndTime, 'DD/MM/YYYY HH:mm', 'pt-br').diff(
                    Date.now(),
                  ) > 0 ? (
                    !assemblyUserPresence ? (
                      <>
                        <CardContentPollMessage>
                          <p>
                            Para que você possa votar na assembleia é necessário
                            que confirme a sua presença.
                          </p>
                        </CardContentPollMessage>
                        <CardContentPollMessageButton
                          onClick={() => {
                            vaVoteInfoHandleUnitAssemblyPresence();
                          }}
                        >
                          <FontAwesomeIcon
                            icon={['fas', 'hand-point-up']}
                            size="lg"
                          />
                          <p>Registrar presença</p>
                        </CardContentPollMessageButton>
                      </>
                    ) : (
                      <CardContentPollMessage>
                        {surveyVoteData?.unidadeVotou ? (
                          <>
                            <CardContentPollVotedProtocol
                              imgBkg={protocolVoteBkg}
                            >
                              <LineRow>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineLabel>
                                    Protocolo do voto:
                                  </CardContentPollVotedProtocolLineLabel>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {surveyVoteData.voto.id}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                              </LineRow>
                              <LineRow>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineLabel>
                                    Data:
                                  </CardContentPollVotedProtocolLineLabel>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {dayjs(surveyVoteData.voto.dataVoto).format(
                                      'DD/MM/YYYY [às] HH:mm:ss',
                                    )}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                              </LineRow>
                              <LineRow>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineLabel>
                                    Dispositivo:
                                  </CardContentPollVotedProtocolLineLabel>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {surveyVoteData.voto.dvcPlatform}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineLabel>
                                    Marca:
                                  </CardContentPollVotedProtocolLineLabel>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {surveyVoteData.voto.dvcPlatformVersion}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                              </LineRow>
                              <LineRow>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {surveyVoteData.voto.dvcAddress}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                              </LineRow>
                              <LineRow>
                                <CardContentPollVotedProtocolData>
                                  <CardContentPollVotedProtocolLineLabel>
                                    E-mail:
                                  </CardContentPollVotedProtocolLineLabel>
                                  <CardContentPollVotedProtocolLineinfo>
                                    {surveyVoteData.voto.userEmail}
                                  </CardContentPollVotedProtocolLineinfo>
                                </CardContentPollVotedProtocolData>
                              </LineRow>
                            </CardContentPollVotedProtocol>
                            <CardContentPollInfoTitle>
                              <FontAwesomeIcon icon={['fas', 'chart-pie']} />
                              <p>Resultados</p>
                            </CardContentPollInfoTitle>
                            <>
                              {surveyVoteData.opcoes.map(opt => {
                                return (
                                  <CardContentPollInfoPercentage
                                    percentage={opt.percentual}
                                    chosenOption={
                                      surveyVoteData.voto !== undefined &&
                                      surveyVoteData.voto.opcoes.filter(
                                        item => item === opt.id,
                                      ).length > 0
                                    }
                                  >
                                    <label
                                      htmlFor={opt.opcao}
                                    >{`${opt.opcao}`}</label>
                                    <span id={opt.opcao}>
                                      <div>
                                        <text>{opt.percentual}%</text>
                                      </div>
                                    </span>
                                    {surveyVoteData.voto !== undefined &&
                                      surveyVoteData.voto.opcoes.filter(
                                        item => item === opt.id,
                                      ).length > 0 && (
                                        <p style={{ fontStyle: 'italic' }}>
                                          {t(
                                            'polls.descriptionPoll.pollUserVotedDate',
                                          )}
                                          {dayjs(
                                            surveyVoteData.voto.dataVoto,
                                          ).format('DD/MM/YYYY HH:mm:ss')}
                                        </p>
                                      )}
                                  </CardContentPollInfoPercentage>
                                );
                              })}
                            </>
                            {surveyVoteData.votoCancelavel && (
                              <CardContentPollCanceVoteButton
                                onClick={() => {
                                  Swal.fire({
                                    title: `Atenção!`,
                                    html: `<p>Você realmente deseja cancelar o seu voto?</p></br><p style={{fontStyle: 'italic'}}>Este processo é irreversível.</p>`,
                                    icon: `question`,
                                    showCancelButton: true,
                                    confirmButtonText: `${t(
                                      'common.words.yes',
                                    )}`,
                                    confirmButtonColor: `#3c5f8b`,
                                    cancelButtonText: `${t('common.words.no')}`,
                                    cancelButtonColor: '#6c757d',
                                    reverseButtons: true,
                                    backdrop: `rgba(0,0,0,0.9)`,
                                  }).then(result => {
                                    if (result.isConfirmed) {
                                      handleUserCancelVote();
                                    } else {
                                      return null;
                                    }

                                    return true;
                                  });
                                }}
                              >
                                <p>CANCELAR VOTO</p>
                              </CardContentPollCanceVoteButton>
                            )}
                          </>
                        ) : (
                          <CardContentPollNotVoted>
                            <p>
                              Até o momento não temos o registro do seu voto.
                              Deseja votar nessa enquete?
                            </p>
                            <Button
                              icon="check"
                              iconFamily="fas"
                              model="default"
                              onClick={() => {
                                history.push({
                                  pathname: `/vaVote`,
                                  state: { pollId },
                                });
                              }}
                              title={t('polls.voteInPoll.vote')}
                            />
                          </CardContentPollNotVoted>
                        )}
                      </CardContentPollMessage>
                    )
                  ) : (
                    <CardContentPollMessage>
                      <p>
                        Por favor, aguarde o início da assembleia para poder
                        votar
                      </p>
                    </CardContentPollMessage>
                  )}
                </CardContentPollInfoOptions>
              </CardContentPollInfoContainer>
            </CardContent>
            <CardFooter>
              <LineRow style={{ marginBottom: '0.4rem' }}>
                <FontAwesomeIcon icon={['fas', 'user']} />
                {surveyVoteData.por}
              </LineRow>
              <LineRow>
                <FontAwesomeIcon icon={['fas', 'clock']} />
                {dayjs(surveyVoteData.dataEnquete).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}
              </LineRow>
            </CardFooter>
          </CardContainer>
        )}
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Container>
    </>
  );
};

export default VAVoteInfo;
