import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  padding: 1rem;
`;

export const PoolQuestion = styled.text`
  font-weight: 700;
  margin-bottom: 0.5rem;
`;

export const FormRow = styled.div`
  display: flex;
  margin: 0.2rem 0;

  input {
    margin-right: 0.5rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  max-height: 75vh;

  border-radius: 0.5rem;
  background-color: var(--itemBackground);
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  height: 2.2rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--backgroundHeaderLight);
  border-bottom: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem;

  strong {
    font-size: 1.5rem;
    color: var(--primary);
  }

  overflow-y: scroll;
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text);
  overflow-y: auto;
  padding: 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  height: 2.2rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--backgroundHeaderLight);
  border-top: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;
