import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import LoadingCondomob from '../../../components/LoadingCondomob';

import {
  Container,
  LoadingContainer,
  CardContainer,
  TitleContainer,
  TitleBackButton,
  Title,
  RulesContainer,
  RulesDescription,
  TitleDescription,
  AccessLinkDescription,
} from './styles';

interface TicketModeProps {
  doGet: boolean;
  link: string;
  modo: {
    descricao: string;
    id: number;
    nome: string;
  };
  usuario: string;
  senha: string;
}

const ThirdPartyTicket = (item: { ticket: TicketModeProps }) => {
  const [loading, setLoading] = useState(false);

  const { ticket } = item;
  const { usuario, senha, link } = ticket;

  const history = useHistory();
  const { t } = useTranslation();

  const openLink = (): void => {
    if (link.length > 0) {
      window.open(link);
    } else {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('common.phrases.linkNotFound')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    }
  };

  return (
    <Container className="containerDefault">
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <>
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('tickets.ticket')}`}</Title>
          </TitleContainer>
          <CardContainer>
            <TitleDescription>
              {t('tickets.thirdParty.titleDescription')}
            </TitleDescription>

            <AccessLinkDescription>
              {t('tickets.thirdParty.accessLink')}
            </AccessLinkDescription>

            <p>{`Usuário: ${usuario || ''}`}</p>
            <p>{`Senha: ${senha || ''}`}</p>

            <Button
              title={`${t('tickets.thirdParty.openLink')}`}
              model="default"
              width={100}
              icon="external-link-alt"
              onClick={openLink}
            />
            <RulesContainer>
              <RulesDescription>
                {t('tickets.thirdParty.rulesDescription')}
              </RulesDescription>
            </RulesContainer>
          </CardContainer>
        </>
      )}
    </Container>
  );
};

export default ThirdPartyTicket;
