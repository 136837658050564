import axios from 'axios';
import deviceInfoInterceptor from './interceptors/device-info.interceptor';
import tokenInterceptor from './interceptors/token.interceptor';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(deviceInfoInterceptor);
api.interceptors.request.use(tokenInterceptor);

export default api;
