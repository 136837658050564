import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  height: calc(100vh - 100px);

  padding: 0.5rem;
`;

export const LineRow = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  padding: 1rem;
`;

// *** Specific of the page ***
export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;

  border-radius: 0.5rem;
  border: solid 1px var(--wolfgray);
  background-color: var(--itemBackground);

  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  height: 3.2rem;
  padding: 0.5rem;
  align-items: center;
  background-color: var(--backgroundHeaderLight);
  border-bottom: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1rem;

  strong {
    font-size: 1.5rem;
    color: var(--primary);
  }

  overflow: auto;
`;

export const CardContentPollDate = styled.div<{ encerrada: boolean }>`
  display: flex;
  margin: 0.5rem 0;

  span {
    font-weight: 700;
    color: ${props =>
      props.encerrada ? 'var(--pollClose)' : 'var(--pollOpen)'};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContentPollInfoContainer = styled.div`
  display: flex;
  padding: 1rem;
  flex: 1;
  background-color: var(--white);
  border-radius: 0.5rem;

  overflow-y: auto;
`;

export const CardContentPollInfoQuestion = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.5rem;

  overflow: auto;

  border: none;
  border-right: solid 1px var(--secondary25);
`;

export const CardContentPollInfoOptions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0.5rem;
  align-items: center;

  overflow: auto;
`;

export const CardContentPollMessage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const CardContentPollMessageButton = styled.button`
  display: flex;
  width: 90%;
  height: 2rem;

  border-radius: 1rem;
  border: none;

  align-items: center;
  justify-content: center;
  align-self: center;

  margin-top: 1rem;
  margin-left: 2rem;

  background-color: var(--secondary);

  p {
    color: var(--white);
    font-size: 1rem;
    font-weight: bold;
  }

  svg {
    font-size: 1.2rem;
    color: var(--white);

    margin-right: 0.5rem;
  }

  :hover {
    background-color: var(--primary);
  }
`;

export const CardContentPollNotVoted = styled.div`
  width: 100%;
  text-align: center;

  margin-bottom: 0.5rem;
  p {
    font-size: 1.5rem;
    font-weight: bold;
    color: var(--americanGray);
  }
`;

export const CardContentPollVotedProtocol = styled.div<{ imgBkg: any }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 400px;

  padding: 1rem 2rem;

  align-self: center;

  background-image: url(${props => props.imgBkg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  color: var(--white);
`;

export const CardContentPollVotedProtocolData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  margin: 0.2rem 0rem;
`;

export const CardContentPollVotedProtocolLineLabel = styled.text`
  font-size: 0.8rem;
  color: var(--white);
`;

export const CardContentPollVotedProtocolLineinfo = styled.text`
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--white);
`;

export const CardContentPollInfoTitle = styled.div`
  display: flex;

  padding: 0.5rem;

  svg {
    color: var(--primary);
    margin-right: 0.5rem;
  }

  p {
    color: var(--primary);
    font-weight: bold;
  }
`;

export const CardContentPollInfoPercentage = styled.div<{
  percentage: number;
  chosenOption: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0.5rem 0;
  background-color: ${props =>
    props.chosenOption && 'var(--poolChosenOptionBkg)'};
  border: ${props =>
    props.chosenOption && 'solid 1px var(--poolChosenOptionBorder)'};
  border-radius: 0.2rem;
  /* padding: ${props => props.chosenOption && '1rem'}; */
  padding: 1rem;

  label {
    font-weight: 700;
    font-size: 0.8rem;
  }

  span {
    position: relative;
    display: flex;
    width: 100%;
    height: 1.4rem;
    border: solid 1px #ccc;
    border-radius: 0.7rem;

    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      border-radius: 0.7rem;
      width: ${props => props.percentage}%;
      background-color: var(--primary);

      text {
        position: absolute;
        left: 50%;
        font-weight: 700;
        color: ${props =>
          props.percentage < 50 ? 'var(--secondary)' : 'var(--white)'};
        font-size: 0.8rem;
      }
    }
  }

  p {
    font-size: 0.8rem;
  }
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: 50%;
  color: var(--text);
  padding: 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }
`;

export const CardContentPollVotesInfo = styled.div<{ percentage: number }>`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    margin: 0.5rem 0 0.5rem 0;

    strong {
      font-size: 1rem;
      color: var(--textDark);
    }

    p {
      font-size: 0.7rem;
    }
  }

  span {
    display: flex;
    width: 100%;
    height: 1.4rem;
    border: solid 1px #ccc;
    border-radius: 0.7rem;

    div {
      align-self: center;
      height: 100%;
      width: ${props => props.percentage}%;
      background-color: var(--primary);

      text {
        font-weight: 700;
        color: var(--white);
        font-size: 0.8rem;
      }
    }
  }
`;

export const CardContentPollSecretVote = styled.div`
  display: flex;
  margin: 0.5rem 0;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5rem;
  justify-content: center;

  background-color: var(--backgroundHeaderLight);
  border-bottom: solid 1px var(--inputBorder);

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContentPoolInfoVoteContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--white);
  background-color: var(--pollVoted);
  border-radius: 0.2rem;
  width: -webkit-fill-available;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 0.8rem;

  p {
    margin-bottom: 0.3rem;
    strong {
      color: var(--white);
      font-size: 0.8rem;
    }
  }

  span {
    font-size: 0.7rem;
    color: var(--white);
  }
`;

export const CardContentPollCanceVoteButton = styled.button`
  display: flex;
  width: 100%;
  height: 2rem;

  border-radius: 1rem;
  border: solid 1px var(--danger);

  align-items: center;
  justify-content: center;

  background-color: var(--white);

  p {
    font-size: 1rem;
    font-weight: bold;

    color: var(--danger);
  }

  :hover {
    background-color: var(--danger);
    P {
      color: var(--white);
    }
  }
`;
