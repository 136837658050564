import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dom } from '@fortawesome/fontawesome-svg-core';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { normalizedContains } from '../../../constants/Utils';

import { Input } from '../../../components/Input';
import LoadingCondomob from '../../../components/LoadingCondomob';
import LoadMoreAnimation from '../../../components/LoadMore';

import api from '../../../services/api';

import {
  Container,
  FilterContainer,
  ListContainer,
  ListItem,
  BorderBottom,
} from './styles';

interface DadosListProps {
  dataAtualizacao: number;
  dataReuniao: string;
  id: number;
  link: string;
  tags: string;
  titulo: string;
  usuario: string;
}
interface DadosProps {
  list: Array<DadosListProps>;
}

const MinutesMeeting: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [refreshing, setRefreshing] = useState(false);
  const [arrayHolder, setArrayHolder] = useState({
    list: [],
  } as DadosProps);

  const [dados, setDados] = useState({
    list: [],
  } as DadosProps);

  const { t } = useTranslation();

  dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

  async function getData(loadmore = false) {
    if (!loadmore) {
      setLoading(true);
    }

    setSearchTerm('');
    try {
      const url = `/ata/list`;
      await api
        .get(url)
        .then(res => {
          setLoading(false);
          setRefreshing(false);

          const novosDados = {
            list: [...dados.list, ...res.data.list],
          };
          setDados(novosDados);
          setArrayHolder(novosDados);
        })
        .catch((error: any) => {
          setLoading(false);
          setRefreshing(false);
          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: `${error.response.data.error}`,
            icon: `error`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
        });
    } catch (err: any) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${err.message}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      setLoading(false);
    }
  }

  function SearchFilterFunction(text) {
    if (dados.list.length > 0) {
      const newData = dados.list.filter(item => {
        const textoFiltro = item.titulo + item.dataReuniao;
        return normalizedContains(textoFiltro, text);
      });
      setArrayHolder({
        list: newData,
      });
      setSearchTerm(text);
    }
  }

  const sendByEmail = async id => {
    return api
      .post(`/ata/receberEmail`, null, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('documents.sendByEmailMessage')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        console.error('@sendNoticeByEmail', err.response.data.error);
        const str = `${t('documents.documentNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const downloadFile = fileURL => {
    window.open(fileURL);
  };

  const renderItem = () =>
    arrayHolder.list.map(item => (
      <ListItem key={item.id}>
        <button
          type="button"
          onClick={() => {
            Swal.fire({
              text: `${item.titulo}`,
              confirmButtonText:
                '<i class="fas fa-cloud-download-alt"></i> Fazer download',
              confirmButtonColor: `#3c5f8b`,
              showDenyButton: true,
              denyButtonText:
                '<i class="fas fa-envelope"></i> Receber por e-mail',
              denyButtonColor: '#3c5f8b',
              backdrop: `rgba(0,0,0,0.9)`,
            }).then(result => {
              if (result.isConfirmed) {
                downloadFile(item.link);
              } else if (result.isDenied) {
                sendByEmail(item.id);
              }
            });
          }}
        >
          <FontAwesomeIcon icon={['fal', 'file-contract']} />
          <div>
            <strong>{item.dataReuniao}</strong>
            <div>
              <p>{item.titulo}</p>
            </div>
          </div>
        </button>
        <BorderBottom />
      </ListItem>
    ));

  useEffect(() => {
    setRefreshing(true);
    getData();
  }, []);

  return (
    <Container className="containerDefault">
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            <Input
              name="termSearch"
              type="text"
              placeholder={`${t('common.words.searchTermDescription')}`}
              value={searchTerm}
              onChange={e => {
                SearchFilterFunction(e.target.value);
              }}
              style={{ borderColor: 'var(--primary)' }}
              icon="search"
            />
          </FilterContainer>

          <ListContainer>{renderItem()}</ListContainer>
        </>
      )}
    </Container>
  );
};

export default MinutesMeeting;
