import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;

  .teste {
    border-color: red !important;
    color: darkred !important;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.4rem 1rem;

  svg {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  max-height: calc(100vh - 10rem);
  padding: 0 1rem;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  position: relative;
  width: 100%;

  button {
    display: flex;
    background-color: #fff;
    width: 100%;
    padding: 10px 20px;
    align-items: center;
    text-decoration: none;
    border: none;

    &:hover {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    svg {
      color: var(--primary);
      font-size: 2rem;
      margin-right: 1rem;
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: left;

      strong {
        font-size: 1.2rem;
        color: var(--primary);
      }

      div {
        display: flex;
        flex-direction: row;
        align-items: baseline;

        p {
          font-size: 0.8rem;
          color: var(--primary);
          margin-top: 4px;

          @media (max-width: 500px) {
            font-size: 0.7rem;
          }
        }
      }
    }
  }

  hr {
    width: 99%;
    margin: 0px auto 2px;
    color: red;
    border: 0px solid #fff;
    border-top-color: #ccc;
    border-top-width: 0.5px;
  }
`;

export const BorderBottom = styled.div`
  position: absolute;
  width: 98%;
  border: none;
  border-bottom: solid 1px var(--borderExtraLight);
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
