import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import qs from 'qs';

import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  CardContainer,
  HeaderContainer,
  HeaderRow,
  HeaderRowTextStrong,
  HeaderRowText,
  HeaderLine,
  MessagesListContainer,
  MessageContentItemContainer,
  MessageContentItemSideBar,
  MessageContentItemDate,
  MessageContentItemText,
  MessageContentItemUser,
  MesageNewMessageAttachFileListItem,
  MessageNewMessageRemoveFileButton,
  MessageContentFileButton,
  MessageContentFileList,
  MessageContentFileName,
  MessageNewMessageAttachFileContainer,
  MessageNewMessageAttachFileTitle,
  MessageNewMessageAttachFileListContainer,
  MessageNewMessageContainer,
  MessageNewMessageInputContainer,
  FileAttachmentButton,
  FileAttachmentInput,
  MessageNewMessageInput,
  SendMessageButton,
  MessageDetailsOptionsButton,
  ModalOptionsContainer,
  ModalOptionsContent,
  ModalOptionsCloseButton,
  ModalOptionsTitle,
} from './styles';
import { Button } from '../../../components/Button';

interface MessageDataMensagensProps {
  dataMensagem: number;
  fileName: string;
  fileType: string;
  isApp: boolean;
  link: string;
  remetente: string;
  texto: string;
}

interface MessageDataProps {
  assunto: string;
  comunicadoId: number;
  dataEnvio: number;
  id: number;
  mensagens: Array<MessageDataMensagensProps>;
  para: {
    nome: string;
  };
  status: {
    id: number;
    nome: string;
    statusApp: string;
  };
  tipo: {
    nome: string;
    cor: string;
  };
  visualizado: boolean;
}

interface FilesAttachedProps {
  fileId: number;
  fileName: string;
  fileType: string;
}

interface SelectedFileProps extends Blob {
  fileId: string;
  name: string;
  fileType: string;
}

interface ItemDataMensagensProps {
  dataMensagem: number;
  fileType: string;
  fileName: string;
  id: number;
  isApp: boolean;
  link: string;
  remetente: string;
  texto: string;
}

interface ItemDataProps {
  assunto: string;
  dataEnvio: number;
  id: number;
  mensagens: ItemDataMensagensProps[];
  para: {
    id: number;
    nome: string;
  };
  status: {
    id: number;
    nome: string;
    statusApp: string;
  };
  tipo: {
    botao?: string;
    cor: string;
    id: number;
    nome: string;
  };
  visualizado: boolean;
}

const MessageDetails: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [modalOptionsVisible, setModalOptionsVisible] = useState(false);
  const [messageData, setMessageData] = useState<MessageDataProps>(
    {} as MessageDataProps,
  );
  const [filesAttached, setFilesAttached] = useState<FilesAttachedProps[]>([]);
  const [deadlineMessage, setDeadlinemessage] = useState('');

  const [newMessageText, setNewMessageText] = useState('');
  const [selectedFile, setSelectedFile] = useState<SelectedFileProps>(
    {} as SelectedFileProps,
  );
  const { state } = location;
  const { id, activeTab } = state;
  const fileAttach = useRef<HTMLInputElement>(null);

  const history = useHistory();
  const { t } = useTranslation();

  const getMessageData = async (): Promise<void> => {
    const url = `/mensagem/get`;
    const body = {
      params: { id },
    };

    await api
      .get(url, body)
      .then(res => {
        if (res.status === 200) {
          setMessageData(res.data);
          setDeadlinemessage(res.data.prazo);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `warning`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const handleOpenFile = (itemUrl: string) => {
    window.open(itemUrl, '_blank', 'noopener,noreferrer');
  };

  const handleRemoveFileAttached = (fileId: number) => {
    const newArr = filesAttached;
    const arrFiltered = newArr.filter(e => e.fileId !== fileId);
    setFilesAttached(arrFiltered);
  };

  const documentListItem = ({ item }: { item: FilesAttachedProps }) => {
    return (
      <MesageNewMessageAttachFileListItem>
        <MessageContentFileButton>
          <FontAwesomeIcon
            color="var(--primary)"
            icon={[
              'fas',
              item?.fileType !== 'application/pdf' ? 'image' : 'file-pdf',
            ]}
          />
          <MessageContentFileName>{item?.fileName}</MessageContentFileName>
        </MessageContentFileButton>
        <MessageNewMessageRemoveFileButton
          onClick={() => {
            handleRemoveFileAttached(item?.fileId);
          }}
        >
          <FontAwesomeIcon color="var(--white)" icon={['fas', 'minus']} />
        </MessageNewMessageRemoveFileButton>
      </MesageNewMessageAttachFileListItem>
    );
  };

  const renderDocumentList = () => {
    return (
      <MessageNewMessageAttachFileContainer>
        {filesAttached.length > 0 && (
          <MessageNewMessageAttachFileTitle>
            Anexos da mensagem:
          </MessageNewMessageAttachFileTitle>
        )}
        <MessageNewMessageAttachFileListContainer>
          {filesAttached.map(item => {
            return documentListItem({ item });
          })}
        </MessageNewMessageAttachFileListContainer>
      </MessageNewMessageAttachFileContainer>
    );
  };

  const resetFields = () => {
    setNewMessageText('');
    setFilesAttached([]);
    setSelectedFile({} as SelectedFileProps);
  };

  const handleSubmitMessage = async (): Promise<void> => {
    setLoading(true);
    const filesId =
      filesAttached.length > 0
        ? filesAttached.map(item => item.fileId).join(',')
        : '';
    const url = `/mensagem/save/`;
    const body = {
      id,
      texto: newMessageText.trim(),
      arquivoId: filesId,
    };

    await api
      .post(url, qs.stringify(body))
      .then(res => {
        if (res.status === 200) {
          resetFields();
          getMessageData();
          setLoading(false);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `warning`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
        setLoading(false);
      });
  };

  const validateSend = () => {
    if (newMessageText.trim().length <= 0 && filesAttached.length <= 0) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `É necessário inserir um texto ou um arquivo para poder enviar a mensagem.`,
        icon: `warning`,
        backdrop: `rgba(0,0,0,0.9)`,
      });

      return;
    }

    handleSubmitMessage();
  };

  const handleFile = event => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSubmitFile = async (): Promise<void> => {
    setLoading(true);

    const formData = new FormData();

    formData.append('file', selectedFile);

    await api
      .post('/mensagem/file', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        transformRequest: (data, headers) => {
          return formData;
        },
      })
      .then(res => {
        if (res.status === 200) {
          setFilesAttached(oldState => [
            {
              ...oldState,
              ...{
                fileId: res.data.codigo,
                fileName: selectedFile?.name,
                fileType: selectedFile?.type,
              },
            },
          ]);
          setLoading(false);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `warning`,
          backdrop: `rgba(0,0,0,0.9)`,
        });

        setLoading(false);
      });
  };

  const renderMessageItem = (item: MessageDataMensagensProps) => {
    return (
      <MessageContentItemContainer isApp={item?.isApp}>
        <MessageContentItemSideBar isApp={item?.isApp} />
        <MessageContentItemUser isApp={item?.isApp}>
          {item?.remetente}
        </MessageContentItemUser>
        {item?.texto !== '' && (
          <MessageContentItemText isApp={item?.isApp}>
            {item?.texto}
          </MessageContentItemText>
        )}
        {item?.link !== '' && item?.link !== undefined && (
          <MessageContentFileList isApp={item?.isApp}>
            <MessageContentFileButton
              isApp={item?.isApp}
              onClick={() => {
                handleOpenFile(item?.link);
              }}
            >
              <FontAwesomeIcon
                color={
                  item?.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)'
                }
                icon={['fas', item?.fileType === 'jpeg' ? 'image' : 'file-pdf']}
              />
              <MessageContentFileName isApp={item?.isApp}>
                {item?.fileName}
              </MessageContentFileName>
            </MessageContentFileButton>
          </MessageContentFileList>
        )}
        <MessageContentItemDate isApp={item?.isApp}>
          {dayjs(item?.dataMensagem).format('DD/MM/YYYY - HH:mm:ss')}
        </MessageContentItemDate>
      </MessageContentItemContainer>
    );
  };

  async function handleSendbyMail(messageId: number) {
    setModalOptionsVisible(false);
    setLoading(true);
    const url = `/mensagem/receberEmail`;
    const body = {
      params: {
        id: messageId,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        if (res.status === 200) {
          const str = `${t('noticeBoard.msgEmailSentSuccess')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: `${t('noticeBoard.msgEmailSentSuccess')}`,
          });

          setLoading(false);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `warning`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
        setLoading(false);
      });
  }

  useEffect(() => {
    getMessageData();
  }, [id]);

  useEffect(() => {
    if (Object.keys(messageData).length > 0) {
      setLoading(false);
    }
  }, [messageData]);

  useEffect(() => {
    if (selectedFile?.name !== undefined) {
      handleSubmitFile();
    }
  }, [selectedFile]);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <ModalOptionsContainer visible={modalOptionsVisible}>
            <ModalOptionsContent>
              <ModalOptionsCloseButton
                onClick={() => {
                  setModalOptionsVisible(false);
                }}
              >
                <FontAwesomeIcon icon={['fas', 'circle-xmark']} />
              </ModalOptionsCloseButton>
              <ModalOptionsTitle>Selecione uma opção</ModalOptionsTitle>
              <Button
                title="RECEBER POR E-MAIL"
                model="other"
                btnColor="var(--platinum)"
                btnTextColor="var(--gray)"
                width={80}
                icon="envelope-open-text"
                onClick={() => {
                  handleSendbyMail(id);
                }}
              />
              <Button
                title="IR PARA O COMUNICADO"
                model="other"
                btnColor="var(--platinum)"
                btnTextColor="var(--gray)"
                width={80}
                icon="right-from-line"
                onClick={() => {
                  setModalOptionsVisible(false);
                  history.push({
                    pathname: '/imReadPage',
                    state: { item: { id: messageData?.comunicadoId } },
                  });
                }}
              />
            </ModalOptionsContent>
          </ModalOptionsContainer>
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.push({
                  pathname: '/talkCondo',
                  state: { activeTab: 1 },
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('common.words.message')}`}</Title>
          </TitleContainer>
          <Content>
            <MessageDetailsOptionsButton
              onClick={() => {
                setModalOptionsVisible(true);
              }}
            >
              <FontAwesomeIcon icon={['fas', 'ellipsis-vertical']} />
            </MessageDetailsOptionsButton>
            <CardContainer>
              <HeaderContainer>
                <HeaderRow>
                  <FontAwesomeIcon icon={['fas', 'comment']} />
                  <HeaderRowTextStrong>
                    {messageData?.assunto}
                  </HeaderRowTextStrong>
                </HeaderRow>
                <HeaderRow>
                  <FontAwesomeIcon icon={['fas', 'calendar-days']} />
                  <HeaderRowText>
                    {dayjs(messageData?.dataEnvio).format('DD/MM/YYYY HH:mm')}
                  </HeaderRowText>
                </HeaderRow>
                <HeaderRow>
                  <FontAwesomeIcon icon={['fas', 'envelope']} />
                  <HeaderRowText>{messageData?.para?.nome}</HeaderRowText>
                </HeaderRow>
                <HeaderRow>
                  <FontAwesomeIcon icon={['fas', 'circle-info']} />
                  <HeaderRowText>
                    Status: {messageData?.status?.nome}
                  </HeaderRowText>
                </HeaderRow>

                {deadlineMessage !== '' && (
                  <HeaderRow>
                    <FontAwesomeIcon icon={['fas', 'triangle-exclamation']} />
                    <HeaderRowText>{deadlineMessage}</HeaderRowText>
                  </HeaderRow>
                )}

                <HeaderLine />
              </HeaderContainer>
              <MessagesListContainer>
                {messageData?.mensagens?.map(item => renderMessageItem(item))}
              </MessagesListContainer>
              {messageData?.status?.id !== 3 && (
                <MessageNewMessageContainer>
                  {renderDocumentList()}

                  <MessageNewMessageInputContainer
                    filesAttached={filesAttached.length > 0}
                  >
                    {!(filesAttached.length > 0) && (
                      <>
                        <FileAttachmentButton
                          name="btnFile"
                          onClick={() => fileAttach?.current?.click()}
                        >
                          <FontAwesomeIcon icon={['fas', 'paperclip']} />
                        </FileAttachmentButton>
                        <FileAttachmentInput
                          ref={fileAttach}
                          type="file"
                          id="file"
                          name="file"
                          accept=".jpg,.jpeg,.gif,.png,.pdf"
                          onChange={handleFile}
                        />
                      </>
                    )}

                    <MessageNewMessageInput
                      onChange={e => {
                        setNewMessageText(e.target.value);
                      }}
                      value={newMessageText}
                      placeholder="Escreva a sua mensagem"
                    />
                    <SendMessageButton onClick={validateSend}>
                      <FontAwesomeIcon icon={['fas', 'paper-plane-top']} />
                    </SendMessageButton>
                  </MessageNewMessageInputContainer>
                </MessageNewMessageContainer>
              )}
            </CardContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default MessageDetails;
