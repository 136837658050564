import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ModalWindow from 'react-modal';
import dayjs from 'dayjs';
import qs from 'qs';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';
import LoadingCondomob from '../../../components/LoadingCondomob';
import { Modal } from '../../../components/Modal';
import { CloseButton } from '../../../components/Modal/styles';
import { useModal } from '../../../hooks/useModal';

import api from '../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  ContentMain,
  MessageContainer,
  MessageHeader,
  MessageContent,
  MessageFooter,
  MessageTitle,
  MessageReadDate,
  MessageInfo,
  MessageShareContainer,
  InfrationDetailContainer,
  InfrationTitle,
  InfrationDetailItem,
  InfrationDetailTitle,
  ModalContent,
} from './styles';

const modalStyle = {
  content: { width: '50%', margin: '0 auto', padding: 10, borderRadius: 10 },
};

interface InfracoesProps {
  descricao: string;
  id: number;
  tipo: string;
  titulo: string;
  valorMulta: number;
}

interface MessageProps {
  advertencia: boolean;
  assunto: string;
  dataCancelamento: number;
  dataComunicado: number;
  dataVisualizacao: number;
  fileName: string;
  financeiro: boolean;
  html: string;
  id: number;
  infracoes: Array<InfracoesProps>;
  link: string;
  mensagemId: number;
  multa: boolean;
  perfil: string;
  usuario: string;
  linkBoleto: string;
}

const IMReadPage = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const [rawHTMLMessage, setRawHTMLMessage] = useState('');
  const [htmlURLEncoded, setHTMLURLEncoded] = useState('');
  const [messageData, setMessageData] = useState<MessageProps>(
    {} as MessageProps,
  );

  const {
    id,
    assunto,
    dataComunicado,
    dataVisualizacao,
    html,
    usuario,
  }: MessageProps = location?.state.item;

  const history = useHistory();
  const { t } = useTranslation();
  const { isShown, toggle } = useModal();

  const getMessage = async () => {
    await api
      .get(`/comunicado/get`, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          setMessageData(res.data);
        }
      })
      .catch(err => {
        Swal.fire({
          icon: 'error',
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const setMessageViewed = () => {
    api.get(`/comunicado/view`, { params: { id } }).catch(err => {
      console.error('@setMessageViewed', err.response.data.error);
    });
  };

  const sendNoticeByEmail = async () => {
    return api
      .get(`/comunicado/receberEmail`, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          // const str = `A notícia foi enviada para o seu e-mail.`;
          const str = `${t('noticeBoard.msgEmailSentSuccess')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: `${t('noticeBoard.msgEmailSentSuccess')}`,
          });
        }
      })
      .catch(err => {
        console.error('@sendNoticeByEmail', err.response.data.error);
        const str = `${t('noticeBoard.msgEmailSentError')} ${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const htmlToEncodeURL = () => {
    const divContainer = document.createElement('div');
    divContainer.innerHTML = html;
    const rawHTMLText =
      divContainer.textContent || divContainer.innerText || '';
    setRawHTMLMessage(
      `${assunto}\nEm: ${dayjs(dataComunicado).format(
        'DD/MM/YYYY HH:mm:ss',
      )}\nPor: ${usuario}\n\n${rawHTMLText}\n\n\nCondomob\nSeu condomínio na palma da mão!\nhttps://condomob.net`,
    );
    toggle();
  };

  const contestStatement = async (msgId: number): Promise<void> => {
    setLoading(true);
    const url = '/mensagem/contestaComunicado';
    const body = {
      idComunicado: msgId,
    };

    await api
      .post(url, qs.stringify(body))
      .then(res => {
        setLoading(false);

        history.push({
          pathname: `/tcMessageDetails`,
          state: { id: res.data.id },
        });
      })
      .catch(err => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const handleModal = (data: InfracoesProps) => {
    setModalTitle(data?.titulo);
    setModalContent(data?.descricao);
    setModalVisible(true);
  };

  useEffect(() => {
    setMessageViewed();
    getMessage();
  }, []);

  useEffect(() => {
    const encoded = encodeURI(rawHTMLMessage);
    setHTMLURLEncoded(encoded);
  }, [rawHTMLMessage]);

  useEffect(() => {
    if (Object.keys(messageData).length > 0) {
      setLoading(false);
    }
  }, [messageData]);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <Modal
            headerText={`${t('common.words.share')}`}
            isShown={isShown}
            hide={toggle}
            modalContent={rawHTMLMessage}
            whatsApp
            shareContent={htmlURLEncoded}
          />

          <ModalWindow
            isOpen={modalVisible}
            onRequestClose={() => {
              setModalVisible(false);
            }}
            style={modalStyle}
          >
            <InfrationDetailTitle style={{ alignSelf: 'flex-start' }}>
              {modalTitle}
            </InfrationDetailTitle>

            <CloseButton
              onClick={() => setModalVisible(false)}
              title={`${t('common.words.close')}`}
            >
              <FontAwesomeIcon
                icon={['fas', 'times-circle']}
                size="2x"
                color="#4d6e98"
              />
            </CloseButton>

            <ModalContent dangerouslySetInnerHTML={{ __html: modalContent }} />
          </ModalWindow>

          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('individualMessage.title')}</Title>
          </TitleContainer>
          <ContentMain>
            <MessageContainer>
              <MessageHeader>
                <FontAwesomeIcon icon={['fas', 'user']} />
                <p>{usuario}</p>
              </MessageHeader>
              <MessageContent>
                <MessageTitle>{assunto}</MessageTitle>
                <MessageReadDate>
                  <FontAwesomeIcon icon={['fas', 'eye']} />
                  <p>{dayjs(dataVisualizacao).format('DD/MM/YYYY HH:mm:ss')}</p>
                </MessageReadDate>
                <InfrationDetailContainer>
                  <InfrationTitle>Advertências / Infrações</InfrationTitle>
                  {messageData?.infracoes?.map(item => {
                    return (
                      <InfrationDetailItem key={item?.id}>
                        <InfrationDetailTitle
                          style={{ alignSelf: 'flex-start' }}
                        >
                          {item?.titulo}
                        </InfrationDetailTitle>
                        <Button
                          title={t('common.words.toView')}
                          width={20}
                          icon="eye"
                          style={{ margin: 50 }}
                          onClick={() => {
                            handleModal(item);
                          }}
                        />
                      </InfrationDetailItem>
                    );
                  })}
                </InfrationDetailContainer>
                <MessageShareContainer>
                  {messageData.linkBoleto !== '' && (
                    <Button
                      title="Baixar boleto"
                      model="default"
                      width={30}
                      icon="dollar-sign"
                      onClick={() =>
                        window.open(messageData.linkBoleto, '_blank')
                      }
                    />
                  )}
                  <Button
                    title={`${t('common.words.share')}`}
                    model="default"
                    width={30}
                    icon="share"
                    onClick={htmlToEncodeURL}
                  />
                  <Button
                    title={`${t('common.words.receiveByEmail')}`}
                    model="default"
                    width={30}
                    icon="envelope"
                    onClick={() => {
                      sendNoticeByEmail();
                    }}
                  />
                  <Button
                    title={
                      messageData?.mensagemId === 0
                        ? `${t('individualMessage.contestStatementLabel')}`
                        : `${t('individualMessage.contestedStatementLabel')}`
                    }
                    model="other"
                    btnColor="var(--Defense)"
                    btnTextColor="var(--white)"
                    width={30}
                    icon="right-from-line"
                    onClick={() => {
                      if (messageData?.mensagemId !== 0) {
                        history.push({
                          pathname: `/tcMessageDetails`,
                          state: { id: messageData?.mensagemId },
                        });
                      } else {
                        contestStatement(messageData?.id);
                      }
                    }}
                  />
                </MessageShareContainer>
              </MessageContent>
              <MessageFooter>
                <FontAwesomeIcon icon={['fas', 'clock']} />
                <p>{dayjs(dataComunicado).format('DD/MM/YYYY HH:mm:ss')}</p>
              </MessageFooter>
            </MessageContainer>
          </ContentMain>
        </Container>
      )}
    </>
  );
};

export default IMReadPage;
