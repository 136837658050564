import React, { useState, useEffect } from 'react';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import api from '../../../../services/api';

import LoadingCondomob from '../../../../components/LoadingCondomob';
import EmptyList from '../../../../components/EmptyList';

import {
  Container,
  LoadingContainer,
  ListContainer,
  ListItem,
  ListItemTitle,
  ListItemValue,
  ListItemIcon,
} from './styles';

interface TicketProps {
  dataPagamento: string;
  dataVencimento: string;
  descricao: string;
  id: number;
  nossoNumero: number;
  parcela: string;
  periodo: string;
  tipo: string;
  valor: number;
  valorPago: number;
}

const CTPaid: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState<TicketProps[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const renderItem = () =>
    tickets.map(item => (
      <ListItem
        onClick={() => {
          history.replace({
            pathname: '/tickets/ctPaidReadMessage',
            state: {
              item,
            },
          });
        }}
      >
        <ListItemTitle>
          <strong>{`${item.tipo} - ${item.periodo}`}</strong>
          {/* <p>Subtítulo</p> */}
          <div>
            <FontAwesomeIcon icon={['fas', 'calendar']} />
            {`${t('tickets.copyTickets.payment')} - ${item.dataPagamento}`}
          </div>
        </ListItemTitle>
        <ListItemValue>R$ {item.valor}</ListItemValue>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ListItemIcon>
      </ListItem>
    ));

  function getData() {
    const url = '/financeiro/liquidadas';
    api
      .get(url)
      .then(res => {
        setLoading(false);
        setTickets(res.data);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <>
          {tickets.length === 0 ? (
            <EmptyList />
          ) : (
            <>
              <ListContainer>{renderItem()}</ListContainer>
            </>
          )}
        </>
      )}
    </Container>
  );
};

export default CTPaid;
