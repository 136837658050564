import styled from 'styled-components';

export const Container = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export const FlagImg = styled.img`
  height: 1.8rem;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    height: 1.2rem;
  }
`;

export const DialogBalloon = styled.div`
  div {
    // layout
    position: absolute;
    top: 4.5rem;
    right: 0.5rem;
    width: 10em;
    z-index: 100;

    // looks
    background-color: #fff;
    padding: 1.125em 1.5em;
    font-size: 1.25em;
    border-radius: 1rem;
    box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3),
      0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    text-align: center;

    li {
      list-style-type: none;
      margin-bottom: 0.5rem;
    }

    button {
      background-color: transparent;
      border: none;
    }

    button:hover {
      font-weight: 600;
      cursor: pointer;
    }
  }

  div::before {
    // layout
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 72%; // offset should move with padding of parent
    border: 0.75rem solid transparent;
    border-top: none;

    // looks
    border-bottom-color: #fff;
    filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
  }

  .fadeView {
    animation: fadeIn linear 0.3s;
    -webkit-animation: fadeIn linear 0.3s;
    -moz-animation: fadeIn linear 0.3s;
    -o-animation: fadeIn linear 0.3s;
    -ms-animation: fadeIn linear 0.3s;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const FlagButton = styled.button`
  width: 30%;
  font-size: 0.7rem;
  text-align: center;
  margin: 0.3rem 0.5rem;
`;

export const FlagButtonImg = styled.img``;
