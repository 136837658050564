import React from 'react';

import { InvisibleItemContainer } from './styles';

interface InvisibleItemProps {
  width: number;
}

const InvisibleItem = ({ width }: InvisibleItemProps) => {
  return <InvisibleItemContainer width={width} />;
};

export default InvisibleItem;
