import styled from 'styled-components';

export const Container = styled.div``;

export const UserIdContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--backgroundHeader);
`;

export const BackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const UserData = styled.div`
  display: flex;
  align-items: center;
`;

export const UserIdPhoto = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #999;
  margin: 5px 10px;
`;

export const UserIdEmail = styled.text`
  font-weight: 700;
  font-size: 1rem;
  color: #333;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;

  width: 100%;

  svg {
    margin-right: 0.5rem;
    font-size: 1rem;
  }
`;

export const FindItem = styled.div`
  display: flex;
  width: 93%;
  min-height: 3rem;
  border-radius: 4px;
  border: 0.5px solid #ddd;
  margin: 20px 0px 10px 0px;
  align-items: center;
`;

export const NoItemMessage = styled.div`
  width: 360px;
  margin: 5px auto;
  align-self: auto;
  text-align: center;
  justify-content: center;
  align-items: center;
`;

export const NoItemImage = styled.img`
  width: 250px;
`;

export const FindItemIcon = styled.button`
  width: 24px;
  height: 24px;
  margin: 0px 10px;
  color: #666;
  border: none;
  background-color: transparent;
`;

export const FindItemInput = styled.input`
  width: 100%;
  min-height: 2rem;
  border: 0px solid;
  color: #333;
  font-size: 1rem;
  margin: 0px 10px;

  @media (max-width: 500px) {
    font-size: 0.8rem;
  }

  :focus {
    outline-width: 0;
  }
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0.3rem 1rem;
  overflow-y: auto;
`;

export const ListItem = styled.div<{
  hideCondo?: boolean;
  searchLength?: number;
}>`
  display: ${props =>
    props.hideCondo
      ? props.searchLength && props.searchLength >= 3
        ? 'flex'
        : 'none'
      : 'flex'};
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    background-color: #fff;
    width: 100%;
    padding: 0.3rem 1rem 0.3rem 0rem;
    display: block;
    align-items: center;
    text-decoration: none;
    border: none;
    border-bottom: solid 1px var(--borderExtraLight);
    border-radius: 5px;
    display: flex;
    margin-bottom: 5px;

    &:hover {
      background-color: #f9f9f9;
      cursor: pointer;
    }

    div {
      display: flex;
      flex-direction: column;
      text-align: left;
    }

    img {
      width: 48px;
      height: 48px;
      border-radius: 2px;
      margin: 0px 0px 0px 15px;
    }

    div {
      margin-left: 1rem;

      strong {
        font-size: 1.2rem;
        color: #333;
        @media (min-width: 1100px) {
        }

        @media (min-width: 980px) {
        }

        @media (min-width: 740px) {
        }

        @media (max-width: 500px) {
          font-size: 1rem;
        }
      }

      p {
        font-size: 1rem;
        color: #999;
        margin-top: 0.2rem;
        @media (min-width: 1100px) {
        }

        @media (min-width: 980px) {
        }

        @media (min-width: 740px) {
        }

        @media (max-width: 500px) {
          font-size: 0.7rem;
        }
      }

      svg {
        margin-left: auto;
        color: #999;
      }
    }
  }
`;

export const ButtonUnitLocatorContainer = styled.div`
  position: absolute;
  display: flex;
  width: 40%;
  height: 4rem;
  bottom: 5px;
  right: 5px;
  align-items: center;
  justify-content: right;
  margin-right: 0.5rem;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 682px) {
    width: 55%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const ButtonUnitLocatorText = styled.div`
  width: 85%;
  text-align: right;
  font-size: 0.8rem;
  margin-right: 0;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    width: 70%;
    font-size: 0.7rem;
  }
`;

export const ButtonUnitLocatorIcon = styled.button`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--secundary);
  padding: 8px;
  margin-left: 5px;
  border: none;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }

  :hover {
    cursor: pointer;
  }
`;

export const ModalTextInfo = styled.text`
  font-family: 'Roboto, Arial, sans-serif';
`;
