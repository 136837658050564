import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import dayjs from 'dayjs';
import qs from 'qs';

import { Button } from '../../../../components/Button';
import { Menu } from '../../../../components/Menu';
import LoadingCondomob from '../../../../components/LoadingCondomob';

import api from '../../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  ContentContainer,
  ReservationInfo,
  CRRow,
  CRRowElement,
  CRLabel,
  CRInput,
  TalkToAdmContainer,
  TalkToAdmTitle,
} from './styles';

const MySwal = withReactContent(Swal);
const GuestList: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [reasonCancel, setReasonCancel] = useState('');

  const { dataSolicitacao, id, nome } = location.state;

  const history = useHistory();
  const { t } = useTranslation();

  async function handleCreateTicket(): Promise<void> {
    const body = {
      texto: reasonCancel,
      idReservaEspaco: id,
    };

    const url = '/mensagem/solicitaCancelamentoReserva';
    await api
      .post(url, qs.stringify(body))
      .then(res => {
        history.replace({
          pathname: 'tcMessageDetails',
          state: { ...location.state, id: res.data.id },
        });
      })
      .catch(err => {
        const error = err.response.data.error
          ? err.response.data.error
          : `${t('common.phrases.generalError')}`;

        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  const cancelReservation = async (): Promise<void> => {
    return api
      .get(`/reservaEspaco/cancelar`, {
        params: {
          id,
          motivo: reasonCancel,
        },
      })
      .then(res => {
        Swal.fire({
          icon: 'success',
          title: `${t('common.words.success')}`,
          text: `${t(
            'spaceReservation.myReservation.cancelReservation.cancelReservationText',
          )}`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.replace({
              pathname: 'SpaceReservation',
              state: { ...location.state, activeTab: 1 },
            });
          }
        });
      })
      .catch(err => {
        if (err.response.data.payload?.financeiro) {
          MySwal.fire({
            title: (
              <TalkToAdmTitle>
                <FontAwesomeIcon icon={['fal', 'warning']} size="3x" />
                <p>Já há uma cobrança gerada para a reserva:</p>
              </TalkToAdmTitle>
            ),
            html: (
              <TalkToAdmContainer>
                <div className="alert">
                  <FontAwesomeIcon icon={['far', 'calendar']} size="lg" />
                  <span>{err.response.data.error}</span>
                </div>
                <p>
                  Entre em contato com a administração para resolver a situação.
                </p>
                <p>Deseja falar com administração?</p>
              </TalkToAdmContainer>
            ),
            showCancelButton: true,
            confirmButtonText: `${t('common.words.yes')}`,
            confirmButtonColor: `#3c5f8b`,
            cancelButtonText: `${t('common.words.no')}`,
            cancelButtonColor: '#6c757d',
            reverseButtons: true,
          }).then(result => {
            if (result.isConfirmed) {
              handleCreateTicket();
            }
            return null;
          });

          return;
        }

        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const handleCancelReservation = () => {
    if (reasonCancel.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t(
          'spaceReservation.myReservation.cancelReservation.cancelReasonText',
        )}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    Swal.fire({
      text: `${t(
        'spaceReservation.myReservation.cancelReservation.cancelConfirmText',
      )}`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        cancelReservation();
      }
      return null;
    });
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.replace({
                  pathname: 'SpaceReservation',
                  state: { ...location.state, activeTab: 1 },
                });
              }}
            >
              <FontAwesomeIcon
                icon={['far', 'angle-left']}
                size="lg"
                color="#3c5f8b"
              />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t(
              'spaceReservation.myReservation.cancelReservation.title',
            )}`}</Title>
          </TitleContainer>
          <Content>
            <ContentContainer>
              <ReservationInfo>
                <p>
                  <FontAwesomeIcon icon={['fas', 'calendar-day']} />{' '}
                  {dayjs(dataSolicitacao).format('DD/MM/YYYY')}
                </p>
                <strong>{nome}</strong>
              </ReservationInfo>
              <CRRow>
                <CRRowElement>
                  <CRLabel>{`${t(
                    'spaceReservation.myReservation.cancelReservation.reasonCancel',
                  )}`}</CRLabel>
                  <CRInput>
                    <textarea
                      rows={5}
                      placeholder={`${t(
                        'spaceReservation.myReservation.cancelReservation.reasonCancelPlaceholder',
                      )}`}
                      value={reasonCancel}
                      onChange={e => setReasonCancel(e.target.value)}
                    />
                  </CRInput>
                  <Button
                    title={`${t('common.words.cancel')}`}
                    model="default"
                    width={15}
                    icon="times"
                    onClick={handleCancelReservation}
                  />
                </CRRowElement>
              </CRRow>
            </ContentContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default GuestList;
