//Função para usar no campo de filtro, (normalizedContains/removeDiacritics)
//EX: Vitória = vitoria, ä = A, ï = I
const removeDiacritics = (str) => {
  return str.normalize('NFD').replace(/[\W\u0300-\u036f-^(.+?)\/*?$]/g, '');
};
// Determines if a haystack contains a needle.  Case and accent insensitive.
// Example: normalizedContains('Le Samouraï', 'I') -> true
export const normalizedContains = (haystack, needle) => {
  const regExp = new RegExp(removeDiacritics(needle), 'gi');
  return regExp.test(removeDiacritics(haystack));
};
