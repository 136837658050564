import styled from 'styled-components';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const Container = styled.div`
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ExpiredText = styled.text<{
  expired: true | false;
}>`
  color: ${props => (props.expired ? '#dc3545' : '#3c608b')};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  display: flex;
  border: none;
  width: 3rem;
  font-size: 0.7rem;
  padding: 0.3rem;
  background-color: transparent;
  color: var(--secundary);
  justify-content: center;
  align-items: center;
  svg {
    margin-right: 0.3rem;
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  overflow-y: auto;
`;

// *** Specific of the page ***
export const CardPool = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-flow: row wrap;
  gap: 3rem;
  padding: 1rem;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 70vh;
  border-radius: 0.5rem;
  border: 1px solid #f5f5f6;
  background-color: var(--itemBackground);
  overflow: hidden;

  :hover {
    -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
    box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 92%;
`;

export const InputContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0.5rem 1rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  height: 60%;
  color: var(--text);
  overflow-y: auto;
  padding: 1rem 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }

  p {
    word-wrap: break-word;
    text-align: justify;
    text-justify: inter-word;
    overflow-y: auto;
  }
`;

export const CardContentTextPostDate = styled.div`
  display: flex;
  margin-bottom: 0.5rem;

  svg {
    margin-right: 0.5rem;
  }
`;

export const ReplyContainer = styled.div`
  border: 1px solid;
  border-radius: 0.25rem;
  padding: 1rem;
  margin: 2rem 0 0 0;
`;

export const SharePostContainer = styled.div`
  display: flex;
  /* padding: 0 1rem; */
  button {
    :first-child {
      margin-right: 0.5rem;
    }
  }
`;
