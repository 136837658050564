import React, { ReactNode, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Swal from 'sweetalert2';
import Calendar from 'react-calendar';
import './Calendar.css';

import { Button } from '../../../../components/Button';
import { Menu } from '../../../../components/Menu';
import LoadingCondomob from '../../../../components/LoadingCondomob';
import InvisibleItem from '../../../../components/InvisibleItem';

import api from '../../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  CalendarDaySelected,
  ListReservationsDayContainer,
  ListReservationsDayItem,
  ListReservationDayItemRow,
  ListReservationDayStatus,
  ListReservationStatusContainer,
  CalendarSubtitleItemContainer,
  CalendarSubtitleContainer,
  CalendarSubtitleItemColor,
  CalendarSubtitleItemInfo,
} from './styles';

interface ActiveDateProps {
  mes: string;
  ano: string;
}

interface BlockedDatesProps {
  dataReserva: string;
  dataSolicitacao: number;
  motivo: string;
  por: string;
  status: {
    nome: string;
  };
}

interface DateReservedProps {
  dataReserva: string;
  horarioInicio: string;
  horarioFim: string;
  numeroConvidados: number;
  observacao: string;
  status: {
    nome: string;
  };
  unidade: string;
}

type DateInfoProps = {
  data: number;
  id: number;
  nome: string;
};

type DateSelectedInfoProps = {
  dataIndisponivel: boolean;
  dataReserva: string;
  horarioFim: string;
  horarioInicio: string;
  motivo: string;
  numeroConvidados: number;
  pertenceUnidade: boolean;
  status: {
    cor: string;
    id: number;
    nome: string;
  };
  unidade: string;
};

const RequestReservation: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [datePicked, setDatePicked] = useState(new Date());
  const [dateInfoList, setDateInfoList] = useState<DateInfoProps[]>([]);
  const [dateSelectedInfo, setDateSelectedInfo] = useState<
    DateSelectedInfoProps[]
  >([]);
  const [clicked, setClicked] = useState(false);
  const [activeCalendarMonth, setActiveCalendarMonth] =
    useState<ActiveDateProps>({
      ano: '',
      mes: '',
    });
  const [showReservationButton, setShowReservationButton] = useState(true);

  const { item } = location.state;
  console.info('item', item);
  const { id } = item;
  const history = useHistory();
  const { t } = useTranslation();

  dayjs.extend(utc);
  dayjs.extend(isSameOrBefore);

  async function getData(mes: number, ano: number): Promise<void> {
    const url = '/reservaEspaco/listCalendarioByPeriodo';
    const body = {
      params: {
        espaco: id,
        mes, // mes: number 1,2 digits
        ano, // ano: number 4 digits
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setDateInfoList(res.data);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `${t('common.phrases.generalError')}`;

        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });
      });
  }

  async function getDayData(date: string) {
    const url = '/reservaEspaco/listByData';
    const body = {
      params: {
        dataReserva: date,
        idEspaco: id,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setDateSelectedInfo(res.data);
        // setDateSelectedInfo(fakeDayData);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `${t('common.phrases.generalError')}`;

        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });
      });
  }

  function renderDayInfoCard(dayInfoData: DateSelectedInfoProps): ReactNode {
    if (dayInfoData.dataIndisponivel) {
      return (
        <ListReservationsDayItem>
          <ListReservationDayItemRow>
            <FontAwesomeIcon icon={['fal', 'square-info']} />
            <span>{dayInfoData.motivo}</span>
          </ListReservationDayItemRow>
        </ListReservationsDayItem>
      );
    }
    return (
      <ListReservationsDayItem>
        <ListReservationDayItemRow>
          <FontAwesomeIcon icon={['fal', 'calendar-day']} />
          <p>{dayjs(dayInfoData.dataReserva).format('DD/MM/YYYY')}</p>
          <ListReservationStatusContainer status={dayInfoData.status.nome}>
            <ListReservationDayStatus>
              {dayInfoData.status.nome}
            </ListReservationDayStatus>
          </ListReservationStatusContainer>
        </ListReservationDayItemRow>
        <ListReservationDayItemRow>
          <FontAwesomeIcon icon={['fal', 'user']} />
          <span>{dayInfoData.unidade}</span>
        </ListReservationDayItemRow>
        <ListReservationDayItemRow>
          <FontAwesomeIcon icon={['fal', 'clock']} />
          <span>
            {dayInfoData.horarioInicio} - {dayInfoData.horarioFim}
          </span>
        </ListReservationDayItemRow>
        <ListReservationDayItemRow>
          <FontAwesomeIcon icon={['fal', 'square-info']} />
          <span>{dayInfoData.motivo}</span>
        </ListReservationDayItemRow>
        <ListReservationDayItemRow>
          <FontAwesomeIcon icon={['fal', 'users']} />
          <span>{dayInfoData.numeroConvidados} convidado / usuário</span>
        </ListReservationDayItemRow>
      </ListReservationsDayItem>
    );
  }

  const validateDateChoice = () => {
    const dateNow = dayjs.utc().startOf('date').format();
    const dateSelected = dayjs(datePicked)
      .utc()
      .local()
      .startOf('day')
      .format();

    if (dayjs(dateNow).isSameOrBefore(dayjs(dateSelected))) {
      history.push({
        pathname: '/srrReservationRequest',
        state: { item, dateSelected },
      });
    } else {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `A data selecionada tem de ser igual ou posterior ao dia de hoje`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    }
  };

  // Checks if day can show Schedule button
  function validateScheduledDay() {
    const dayValidated = dateInfoList.find(
      el => el.data.toString() === dayjs(datePicked).format('YYYYMMDD'),
    );

    // Check if day is blocked to schedule
    if (dayValidated && dayValidated?.id === 0) {
      setShowReservationButton(false);
      return;
    }

    // Check if day is filled
    if (dayValidated && dayValidated?.id === 1) {
      setShowReservationButton(false);
      return;
    }

    setShowReservationButton(true);
  }

  useEffect(() => {
    setActiveCalendarMonth({
      mes: dayjs(new Date()).format('MM'),
      ano: dayjs(new Date()).format('YYYY'),
    });
  }, []);

  useEffect(() => {
    if (activeCalendarMonth.mes !== '') {
      getData(Number(activeCalendarMonth.mes), Number(activeCalendarMonth.ano));
    }
  }, [activeCalendarMonth]);

  useEffect(() => {
    if (datePicked) {
      getDayData(dayjs(datePicked).format('YYYYMMDD'));
      validateScheduledDay();
    }
  }, [datePicked]);

  useEffect(() => {
    setLoading(false);
  }, [dateSelectedInfo]);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{`${t(
              'spaceReservation.reservationAvailability.title',
            )}`}</Title>
          </TitleContainer>
          <Content>
            <Calendar
              onChange={setCalendarDate}
              value={calendarDate}
              onClickDay={(value, event) => {
                setDatePicked(value);
                setClicked(true);
                return null;
              }}
              onActiveStartDateChange={value => {
                setActiveCalendarMonth({
                  mes: dayjs(value.activeStartDate).format('MM'),
                  ano: dayjs(value.activeStartDate).format('YYYY'),
                });
              }}
              locale="pt-BR"
              showNeighboringMonth={false}
              tileClassName={({ date, view }) => {
                const dateChecked = dateInfoList.find(elDate => {
                  return elDate.data === Number(dayjs(date).format('YYYYMMDD'));
                });
                /* Blocked day / Unavailable => id=0 */
                if (dateChecked?.id === 0) {
                  return 'calendarDayBlocked';
                }

                /* Filled day => id=1 */
                if (dateChecked?.id === 1) {
                  return 'calendarDayHasReservations';
                }

                /* User reservations => id=2 */
                if (dateChecked?.id === 2) {
                  return 'calendarDayFilled';
                }

                /* Has at least one reservation => id=3 */
                if (dateChecked?.id === 3) {
                  return 'calendarDayUserReservation';
                }

                return null;
              }}
            />
            <CalendarSubtitleContainer>
              <CalendarSubtitleItemContainer>
                <CalendarSubtitleItemColor color="primary" borderColor="" />
                <CalendarSubtitleItemInfo>
                  Minhas reservas
                </CalendarSubtitleItemInfo>
              </CalendarSubtitleItemContainer>
              <CalendarSubtitleItemContainer>
                <CalendarSubtitleItemColor color="primary25" borderColor="" />
                <CalendarSubtitleItemInfo>
                  Existem reservas
                </CalendarSubtitleItemInfo>
              </CalendarSubtitleItemContainer>
              <CalendarSubtitleItemContainer>
                <CalendarSubtitleItemColor color="danger25" borderColor="" />
                <CalendarSubtitleItemInfo>
                  Dia ocupado ou cheio
                </CalendarSubtitleItemInfo>
              </CalendarSubtitleItemContainer>
              <CalendarSubtitleItemContainer>
                <CalendarSubtitleItemColor color="explosive" borderColor="" />
                <CalendarSubtitleItemInfo>
                  Dia bloqueado
                </CalendarSubtitleItemInfo>
              </CalendarSubtitleItemContainer>
            </CalendarSubtitleContainer>
            {clicked ? (
              <>
                <CalendarDaySelected>
                  <strong>
                    {dayjs(datePicked).utc().local().format('DD/MM/YYYY')}
                  </strong>
                  {showReservationButton && (
                    <Button
                      title={`${t(
                        'spaceReservation.reservationAvailability.requestReservationButtonDescription',
                      )}`}
                      model="default"
                      width={100}
                      icon="calendar-check"
                      onClick={() => {
                        validateDateChoice();
                      }}
                    />
                  )}
                </CalendarDaySelected>
                <ListReservationsDayContainer>
                  {dateSelectedInfo.map(el => {
                    return renderDayInfoCard(el);
                  })}
                  <InvisibleItem width={20} />
                </ListReservationsDayContainer>
              </>
            ) : null}
          </Content>
        </Container>
      )}
    </>
  );
};

export default RequestReservation;
