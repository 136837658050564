import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Menu } from '../../components/Menu';
import LoadingCondomob from '../../components/LoadingCondomob';
import Tabs from '../../components/Tabs';

import NewMessage from './NewMessage';
import ListMessages from './ListMessages';

import { Container, TitleContainer, TitleBackButton, Title } from './styles';

const TalkCondo: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(false);

  const { state } = location;
  let activeTab = 0;

  if (state) {
    activeTab = state?.activeTab;
  }

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.replace({
                  pathname: '/dashboard',
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('talkCondo.title')}</Title>
          </TitleContainer>

          <Tabs
            tabElement={[
              {
                tabTitle: `${t('talkCondo.newMessage.sendMessage')}`,
                tabIcon: 'comment-dots',
              },
              {
                tabTitle: `${t('talkCondo.readMessageTitle')}`,
                tabIcon: 'comments',
              },
            ]}
            tabContentItem={[NewMessage, ListMessages]}
            tabDataItem={state}
            tabActive={activeTab}
          />
        </Container>
      )}
    </>
  );
};

export default TalkCondo;
