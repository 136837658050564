import styled from 'styled-components';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const ContentContainer = styled.div`
  padding: 1rem;

  overflow-x: hidden;
  overflow-y: auto;
`;

export const ContentData = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1rem;

  border-radius: 0.3rem;
  background-color: var(--white);
`;

export const AdminLogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const AdminLogoContent = styled.img`
  border-radius: 0.5rem;
  height: 10rem;
`;

export const AdminDataContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminName = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--text-body);
`;

export const AdminAddress = styled.text`
  font-weight: 700;
  font-size: 1rem;
  color: var(--text-body);
  margin: 5px 0;
`;

export const AdminContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdminContactItem = styled.div`
  display: flex;
  width: 100%;
  height: 1.5rem;
  align-items: center;
  margin: 2px 5px 0 0;
  color: var(--secundary);
  font-weight: 700;

  svg {
    font-size: 1rem;
  }

  :hover {
    cursor: pointer;
    background-color: var(--itemBackground);
  }
`;

export const AdminContactData = styled.text`
  margin-left: 5px;
`;

export const AdminCondoInfoUnitsContent = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 1rem;
  width: -webkit-fill-available;
  margin-top: 1rem;
  flex-direction: column;
  overflow-y: auto;

  img {
    max-width: -webkit-fill-available;
    height: auto;
  }

  iframe {
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;
  }
`;
