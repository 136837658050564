import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  height: calc(100vh - 3.8rem);

  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-color: transparent !important;

  @media (min-width: 1100px) {
  }

  @media (min-width: 980px) {
  }

  @media (min-width: 740px) {
  }

  @media (max-width: 500px) {
    height: calc(100vh - 3.1rem);
  }
`;

export const Form = styled.form``;

export const Card = styled.div`
  background-color: rgba(255, 255, 255, 0.85);
  width: 22rem;
  padding: 1rem;
  border-radius: 0.25rem;

  @media (max-width: 500px) {
    width: 18rem;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  gap: 0.5rem;
`;

export const ButtonContainer = styled.div`
  margin-top: 0.7rem;
`;

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-around;
  align-items: center;

  height: 3rem;
  width: auto;

  margin-top: 1rem;

  p,
  a {
    color: var(--secundary);
    font-size: 0.875rem;
    text-decoration: none;
  }
`;
