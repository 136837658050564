import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import {
  Container,
  MessagesPool,
  CardMessageContainer,
  CardMessageHeader,
  CardMessageHeaderText,
  CardMessageContent,
  CardMessageContentHeader,
  CardMessageContentHeaderTitle,
  CardMessageContentHeaderInfo,
  CardMessageContentInfo,
  CardMessageContentInfoHeader,
  CardMessageContentText,
  CarMessageContentButtons,
  CardMessageFooter,
  CardMessageFooterInfoContainer,
} from './styles';

interface ReservationListProps {
  convidados: Array<string>;
  dataReserva: string;
  dataResposta: number;
  dataSolicitacao: number;
  espaco: {
    descricao: string;
    imgLink: string;
    nome: string;
  };
  horarioFim: string;
  horarioInicio: string;
  id: number;
  motivo: string;
  numeroConvidados: number;
  observacao: string;
  por: string;
  resposta: string;
  solicitante: string;
  status: {
    id: number;
    nome: string;
  };
}

const MyReservations: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [pastReservationList, setPastReservationList] = useState<
    ReservationListProps[]
  >([]);

  const { t } = useTranslation();

  const getPastReservations = async (): Promise<void> => {
    return api
      .get(`/reservaEspaco/listAnteriores`)
      .then(res => {
        if (res.status === 200) {
          setPastReservationList(res.data.list);
          setLoading(false);
        }
      })
      .catch(err => {
        console.error('@getPastReservations', err.response.data.error);
      });
  };

  useEffect(() => {
    getPastReservations();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <MessagesPool>
            {pastReservationList.map(item => {
              return (
                <CardMessageContainer className="cardDefault" key={item.id}>
                  <CardMessageHeader status={item.status.nome}>
                    <FontAwesomeIcon
                      icon={[
                        'fas',
                        `${
                          // eslint-disable-next-line no-nested-ternary
                          item.status.nome === 'Confirmada'
                            ? 'check'
                            : item.status.nome === 'Aguardando'
                            ? 'clock'
                            : 'times'
                        }`,
                      ]}
                    />
                    <CardMessageHeaderText>
                      {item.status.nome}
                    </CardMessageHeaderText>
                  </CardMessageHeader>
                  <CardMessageContent>
                    <CardMessageContentHeader>
                      <img src={item.espaco.imgLink} alt={item.espaco.nome} />
                      <CardMessageContentHeaderTitle>
                        <strong>{item.espaco.nome}</strong>
                        {item.motivo}
                      </CardMessageContentHeaderTitle>
                    </CardMessageContentHeader>
                    <CardMessageContentHeaderInfo>
                      <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                      {dayjs(item.dataSolicitacao).format('DD/MM/YYYY')}{' '}
                      <strong>{item.horarioInicio}</strong> às{' '}
                      <strong>{item.horarioFim}</strong>
                    </CardMessageContentHeaderInfo>
                    <CardMessageContentInfo>
                      <CardMessageContentInfoHeader tipo="convidados">
                        {`${t('spaceReservation.pastReservations.guestList')}`}
                      </CardMessageContentInfoHeader>
                      {item.convidados.length === 0 ? (
                        <CardMessageContentText>
                          {`${t(
                            'spaceReservation.pastReservations.noDefinedRules',
                          )}`}
                        </CardMessageContentText>
                      ) : (
                        item.convidados.map((conv, index) => {
                          return (
                            <CardMessageContentText key={index.toString()}>
                              {conv}
                            </CardMessageContentText>
                          );
                        })
                      )}
                    </CardMessageContentInfo>

                    <CardMessageContentInfo>
                      <CardMessageContentText>
                        <p>
                          <FontAwesomeIcon icon={['fas', 'user-circle']} />
                          {item.solicitante}
                        </p>
                        <p>{item.resposta}</p>
                        <p>
                          <FontAwesomeIcon icon={['fas', 'reply']} />
                          {dayjs(item.dataResposta).format(
                            'DD/MM/YYYY HH:mm:ss',
                          )}
                        </p>
                      </CardMessageContentText>
                    </CardMessageContentInfo>
                  </CardMessageContent>
                  <CardMessageFooter>
                    <CardMessageFooterInfoContainer>
                      <FontAwesomeIcon icon={['fas', 'calendar']} />
                      {dayjs(item.dataSolicitacao).format(
                        'DD/MM/YYYY HH:mm:ss',
                      )}
                    </CardMessageFooterInfoContainer>
                    <CardMessageFooterInfoContainer>
                      <FontAwesomeIcon icon={['fas', 'user-edit']} />
                      {item.por}
                    </CardMessageFooterInfoContainer>
                  </CardMessageFooter>
                </CardMessageContainer>
              );
            })}
          </MessagesPool>
        </Container>
      )}
    </>
  );
};

export default MyReservations;
