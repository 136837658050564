import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import InputMask from 'react-input-mask';
import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal, ConfirmationModal } from '../../../components/Modal';
import { useModal } from '../../../hooks/useModal';

import LoadingCondomob from '../../../components/LoadingCondomob';
import { Input } from '../../../components/Input';

import { useAuth } from '../../../contexts/AuthContext';

import api from '../../../services/api';

import {
  Container,
  FilterContainer,
  ListContainer,
  ListItem,
  NoItemMessage,
} from '../styles';

import {
  ListMsg,
  FormContainer,
  CondoContainer,
  MsgBox,
  MsgBoxLabelContainer,
  MsgBoxLabel,
  MsgBoxText,
  MsgBoxTextBold,
  MsgBoxButton,
  FormUserData,
  FormUserRow,
  CondoInfoContainer,
  CondoInfoName,
  CondoInfoContact,
  SelectFormat,
  ContactsGroup,
  ContactsGroupLabel,
} from './styles';

interface RouteProps {
  state: any;
}

interface UserUnitProps {
  unidade: {
    identificador: string;
    id: string;
  };
  condominio: {
    id: string;
  };
}

interface CondInfoProps {
  condominio: {
    nome: string;
    whatsapps: Array<string>;
    fones: Array<string>;
    emails: Array<string>;
    site: string;
  };
  administradora: {
    nome: string;
    whatsapps: Array<string>;
    fones: Array<string>;
    emails: Array<string>;
    site: string;
  };
}

interface CondoUnitOptions {
  label: string;
  value: string;
}

const LoginUnitInfo: React.FC = () => {
  const [userUnits, setUserUnits] = useState<UserUnitProps[]>([]);
  const [condoUnits, setCondoUnits] = useState<CondoUnitOptions[]>([]);
  const [filteredUserUnit, setFilteredUserUnit] = useState<UserUnitProps[]>([]);
  const [searchUserUnit, setSearchUserUnit] = useState('');
  const [condInfo, setCondInfo] = useState<CondInfoProps>({} as CondInfoProps);
  const [condoUnitSelected, setCondoUnitSelected] = useState('');
  const [userProfile, setUserProfile] = useState('');
  const [userPrivate, setUserPrivate] = useState('');
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();
  const { userCredentials, setUserCondomob, logout, adminLinkIntegrationId } =
    useAuth();

  const { isShown, toggle } = useModal();

  const location: RouteProps = useLocation();
  const { condo } = location.state;

  const profileOptions = [
    {
      label: `${t('loginUnitInfo.profileSelectOptions.owner')}`,
      value: 'proprietario',
    },
    {
      label: `${t('loginUnitInfo.profileSelectOptions.tenant')}`,
      value: 'inquilino',
    },
  ];

  const condoUnitsOptions: CondoUnitOptions[] = [];

  const privateOptions = [
    {
      label: `${t('loginUnitInfo.privateSelectOptions.onlyAdministration')}`,
      value: true,
    },
    {
      label: `${t(
        'loginUnitInfo.privateSelectOptions.admnistrationAndNeighbors',
      )}`,
      value: false,
    },
  ];

  const getCondoUnits = async () => {
    return api
      .get(`/condominio/unidades`, {
        params: { id: condo.id },
      })
      .then(res => {
        res.data.map(unit =>
          condoUnitsOptions.push({ label: unit, value: unit }),
        );
        setCondoUnits(condoUnitsOptions);
      })
      .catch(err => {
        console.error('@getCondoUnits', err);
      });
  };

  const getCondoInfo = async () => {
    return api
      .get(`/condominio/getInfo`, { params: { id: condo.id } })
      .then(res => {
        setCondInfo(res.data);
      })
      .catch(err => {
        console.error('@getCondoInfo', err);
      });
  };

  const getUserUnits = async () => {
    return api
      .get(`/acesso/social/list`, {
        headers: { Authorization: userCredentials?.accessToken },
        params: {
          condominio: condo.id,
          administradora: adminLinkIntegrationId,
        },
      })
      .then(res => {
        setUserUnits(res.data);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error('@getUserUnits', err);
      });
  };

  const loginCondomob = async ({
    condoId,
    unitId,
  }: {
    condoId: string;
    unitId: string;
  }) => {
    return api
      .post(`/acesso/social/login`, null, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: userCredentials?.accessToken,
        },
        params: { condominio: condoId, unidade: unitId },
      })
      .then(res => {
        setUserCondomob(res.data);
        localStorage.setItem(
          '@CondomobWeb:userCondomob',
          JSON.stringify(res.data),
        );
      })
      .catch(err => {
        console.error('@loginCondomob', err.response.data.error);
        console.error('@loginCondomobError', err.response);
        if (err.response.data.error === 'Firebase token inválido!') {
          localStorage.removeItem('@CondomobWeb:tokenFireBase');
          localStorage.removeItem('@CondomobWeb');
        }
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: t('loginUnit.expiredCredentials'),
          icon: `error`,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(res => {
          if (res.isConfirmed) {
            logout();
          }
        });
      });
  };

  const handleFilter = event => {
    const searchWord = event.target.value;
    setSearchUserUnit(searchWord);
    let newFilter = [] as any;
    newFilter = userUnits.filter(value => {
      return value.unidade.identificador
        .toLowerCase()
        .includes(searchWord.toLowerCase());
    });

    if (searchWord === '') {
      setFilteredUserUnit(userUnits);
    } else {
      setFilteredUserUnit(newFilter);
    }
  };

  const registerUserUpdateData = async ({
    condominio,
    fone1,
    fone2,
    inscricao,
    nome,
    perfil,
    publico,
    unidade,
    observacao,
  }) => {
    return api
      .post(`/acesso/social/unidade/atualizacao`, null, {
        headers: {
          Authorization: userCredentials?.accessToken,
        },
        params: {
          condominio,
          fone1,
          fone2,
          inscricao,
          nome,
          perfil,
          publico,
          unidade,
          observacao,
        },
      })
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            title: t('common.words.success'),
            text: t('loginUnitInfo.messages.msgSent'),
            icon: `success`,
            backdrop: `rgba(0,0,0,0.9)`,
          }).then(result => {
            if (result.isConfirmed) {
              toggle();
            }
          });
        }
      })
      .catch(err => {
        console.error('@registerUserUpdateData', err);
      });
  };

  const handleForm = e => {
    e.preventDefault();
    const { userName, cpfCnpj, fone1, fone2, textAreaObs } = e.target.elements;

    if (condoUnitSelected === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.unitRequired')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (userProfile === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.profileRequired')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (userName.value.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.fullnameRequired')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (cpfCnpj.value.replace(/[^0-9]/g, '') === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.cpfcnpjRequired')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (!cpf.isValid(cpfCnpj.value.replace(/[^0-9]/g, ''))) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.cpfcnpjInvalid')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (fone1.value.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.phone1Required')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (fone1.value.replace(/[^0-9]/g, '').length < 11) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.phone1Invalid')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (
      fone2.value.replace(/[^0-9]/g, '').length !== 0 &&
      fone2.value.replace(/[^0-9]/g, '').length < 11
    ) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.phone2Invalid')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (userPrivate === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('loginUnitInfo.messages.privateRequired')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    registerUserUpdateData({
      condominio: condo.id,
      unidade: condoUnitSelected,
      perfil: userProfile,
      nome: userName.value,
      inscricao: cpfCnpj.value.replace(/[^0-9]/g, ''),
      fone1: fone1.value.replace(/[^0-9]/g, ''),
      fone2: fone2.value.replace(/[^0-9]/g, ''),
      publico: userPrivate,
      observacao: textAreaObs.value,
    });
  };

  const handleChangeProfile = item => {
    const { value } = item;
    setUserProfile(value);
  };

  const handleChangeUnit = item => {
    const { value } = item;
    setCondoUnitSelected(value);
  };

  const handleChangePrivate = item => {
    const { value } = item;
    setUserPrivate(value);
  };

  const content = (
    <FormContainer>
      <FormUserData onSubmit={handleForm}>
        <FormUserRow>
          <label htmlFor="selectUserUnit">{`${t(
            'common.words.unit',
          )}: `}</label>
          <SelectFormat
            id="selectUserUnit"
            options={condoUnits}
            placeholder={`${t('common.phrases.selectOption')}...`}
            onChange={item => {
              handleChangeUnit(item);
            }}
          />
        </FormUserRow>
        <FormUserRow>
          <label htmlFor="selectProfile">{`${t(
            'common.words.profile',
          )}: `}</label>
          <SelectFormat
            id="selectProfile"
            options={profileOptions}
            placeholder={`${t('common.phrases.selectOption')}...`}
            onChange={item => {
              handleChangeProfile(item);
            }}
          />
        </FormUserRow>
        <FormUserRow>
          <label htmlFor="userName">{`${t('common.words.fullname')}: `}</label>
          <input
            id="userName"
            name="userName"
            placeholder={t('common.words.fullname')}
          />
        </FormUserRow>
        <FormUserRow>
          <label htmlFor="cpfCnpj">{`CPF/CNPJ: `}</label>
          <InputMask
            id="cpfCnpj"
            name="cpfCnpj"
            mask="999.999.999-99"
            placeholder="CPF/CNPJ"
            // alwaysShowMask
          />
        </FormUserRow>
        <ContactsGroup>
          <ContactsGroupLabel>
            {t('loginUnitInfo.ContactsGroupLabel')}
          </ContactsGroupLabel>
          <FormUserRow style={{ width: '100%', marginLeft: '-3px' }}>
            <label htmlFor="email">{`${t('common.words.email')}: `}</label>
            <input
              id="email"
              name="email"
              value={userCredentials?.providerData[0].email}
              readOnly
            />
          </FormUserRow>
          <FormUserRow style={{ width: '100%', marginLeft: '-3px' }}>
            <label htmlFor="fone1">{`${t('common.words.phone')} 01: `}</label>
            <InputMask
              id="fone1"
              name="fone1"
              mask="(99) 99999-9999"
              placeholder={`${t('common.words.phone')} 01`}
            />
          </FormUserRow>
          <FormUserRow style={{ width: '100%', marginLeft: '-3px' }}>
            <label htmlFor="fone2">{`${t('common.words.phone')} 02: `}</label>
            <InputMask
              id="fone2"
              name="fone2"
              mask="(99) 99999-9999"
              placeholder={`${t('common.words.phone')} 02`}
            />
          </FormUserRow>
          <FormUserRow style={{ width: '100%', marginLeft: '-3px' }}>
            <label htmlFor="selectPrivateData">
              {t('loginUnitInfo.privateSelectOptions.privacyChoice')}
            </label>
            <SelectFormat
              id="selectPrivateData"
              options={privateOptions}
              placeholder={`${t('common.phrases.selectOption')}...`}
              onChange={item => {
                handleChangePrivate(item);
              }}
            />
          </FormUserRow>
        </ContactsGroup>
        <FormUserRow>
          <label htmlFor="textAreaObs">{`${t(
            'common.words.observation',
          )}: `}</label>
          <textarea
            id="textAreaObs"
            placeholder={t('common.words.observation')}
          />
        </FormUserRow>

        <button type="submit">{t('loginUnitInfo.modal.buttonSend')}</button>
      </FormUserData>
    </FormContainer>
  );

  const msgAskHelp = `${t('loginUnitInfo.messages.askHelp01')}\n${t(
    'loginUnitInfo.messages.askHelp02',
  )}${t('loginUnitInfo.messages.askHelp03')}${condInfo?.condominio?.nome}${t(
    'loginUnitInfo.messages.askHelp04',
  )}${userCredentials?.email}`;

  useEffect(() => {
    if (Object.keys(condo).length > 0) {
      getCondoUnits();
      getUserUnits();
      getCondoInfo();
    }
  }, [condo]);

  useEffect(() => {
    setFilteredUserUnit(userUnits);
  }, [userUnits]);

  useEffect(() => {
    if (!isShown) {
      setCondoUnitSelected('');
      setUserProfile('');
      setUserPrivate('');
    }
  }, [isShown]);

  return (
    <Container className="containerDefault">
      <Modal
        headerText={t('loginUnitInfo.modal.headerText')}
        isShown={isShown}
        hide={toggle}
        modalContent={content}
      />
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            <Input
              name="termSearch"
              type="text"
              placeholder={t('loginUnitInfo.FindItemInput.placeholder')}
              value={searchUserUnit}
              onChange={handleFilter}
              icon="search"
            />
          </FilterContainer>
          <CondoContainer>
            <>
              <ListMsg>{t('common.phrases.selectAnUnit')}</ListMsg>
              <ListContainer style={{ minHeight: '13vh' }}>
                {Object.keys(filteredUserUnit).length === 0 ? (
                  <NoItemMessage>
                    {t('common.phrases.noItemFoundNewTermSearch')}
                  </NoItemMessage>
                ) : (
                  filteredUserUnit.map((userUnit, index) => {
                    return (
                      <ListItem key={index.toString()}>
                        <button
                          type="button"
                          onClick={() =>
                            loginCondomob({
                              condoId: userUnit.condominio.id,
                              unitId: userUnit.unidade.id,
                            })
                          }
                        >
                          <div>
                            <strong>{userUnit.unidade.identificador}</strong>
                          </div>
                          <FontAwesomeIcon
                            icon={['far', 'chevron-right']}
                            size="1x"
                            color="#999"
                            style={{ marginLeft: 'auto', marginRight: '15px' }}
                          />
                        </button>
                        <hr />
                      </ListItem>
                    );
                  })
                )}
              </ListContainer>
              <MsgBox>
                <MsgBoxLabelContainer>
                  <MsgBoxLabel>{t('loginUnitInfo.msgBox.label')}</MsgBoxLabel>
                </MsgBoxLabelContainer>
                <MsgBoxText>
                  {t('loginUnitInfo.msgBox.text1')}
                  <MsgBoxTextBold>
                    {userCredentials.providerData[0].email}?
                  </MsgBoxTextBold>
                  {t('loginUnitInfo.msgBox.text2')}
                </MsgBoxText>
                <MsgBoxButton onClick={toggle}>
                  {t('loginUnitInfo.msgBox.buttonText')}
                </MsgBoxButton>
                <MsgBoxText>{t('loginUnitInfo.msgBox.text3')}</MsgBoxText>
                <CondoInfoContainer>
                  {/* Condominum Info */}
                  {Object.keys(condInfo.condominio).length > 0 && (
                    <>
                      <CondoInfoName>{condInfo.condominio.nome}</CondoInfoName>
                      {/* WhatsApps */}
                      {condInfo.condominio.whatsapps.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon
                            icon={['fab', 'whatsapp']}
                            size="1x"
                            style={{ color: '#28a745' }}
                          />
                          <a
                            // href={`https://api.whatsapp.com/send?phone=${item}&text=${msgAskHelp}`}
                            href={`https://api.whatsapp.com/send?phone=${`+55${item.replace(
                              /[^0-9]/g,
                              '',
                            )}`}&text=${msgAskHelp}`}
                            target="_blank"
                            className="whatsApp"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Phones */}
                      {condInfo.condominio.fones.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon icon={['fal', 'phone']} size="1x" />
                          <p>{item}</p>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Emails */}
                      {condInfo.condominio.emails.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon
                            icon={['fal', 'envelope']}
                            size="1x"
                          />
                          <a
                            href={`mailto:${item}`}
                            className="email"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Site */}
                      {condInfo.condominio.site !== undefined && (
                        <CondoInfoContact>
                          <FontAwesomeIcon icon={['fal', 'globe']} size="1x" />
                          <a href="teste">{condInfo.condominio.site}</a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      )}
                    </>
                  )}
                  {/* Administrator Info */}
                  {Object.keys(condInfo.administradora).length > 0 && (
                    <>
                      <CondoInfoName>
                        {condInfo.administradora.nome}
                      </CondoInfoName>
                      {/* WhatsApps */}
                      {condInfo.administradora.whatsapps.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon
                            icon={['fab', 'whatsapp']}
                            size="1x"
                            style={{ color: '#28a745' }}
                          />
                          <a
                            // href="teste" className="whatsApp"
                            href={`https://api.whatsapp.com/send?phone=${`+55${item.replace(
                              /[^0-9]/g,
                              '',
                            )}`}&text=${msgAskHelp}`}
                            className="whatsApp"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Phones */}
                      {condInfo.administradora.fones.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon icon={['fal', 'phone']} size="1x" />
                          <p>{item}</p>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Emails */}
                      {condInfo.administradora.emails.map(item => (
                        <CondoInfoContact>
                          <FontAwesomeIcon
                            icon={['fal', 'envelope']}
                            size="1x"
                          />
                          <a
                            href={`mailto:${item}`}
                            className="email"
                            target="_blank"
                            rel="noreferrer"
                          >
                            {item}
                          </a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      ))}
                      {/* Site */}
                      {condInfo.administradora.site !== undefined && (
                        <CondoInfoContact>
                          <FontAwesomeIcon icon={['fal', 'globe']} size="1x" />
                          <a
                            href={`${condInfo.administradora.site}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {condInfo.administradora.site}
                          </a>
                          <FontAwesomeIcon
                            icon={['fal', 'chevron-right']}
                            size="1x"
                            color="#333"
                            style={{ marginLeft: 'auto' }}
                          />
                        </CondoInfoContact>
                      )}
                    </>
                  )}
                </CondoInfoContainer>
              </MsgBox>
            </>
          </CondoContainer>
        </>
      )}
    </Container>
  );
};

export default LoginUnitInfo;
