import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import api from '../../services/api';

import { useAuth } from '../../contexts/AuthContext';

import {
  Container,
  MsgBoxContainer,
  IconContent,
  AcceptanceBoxContainer,
} from './styles';

const AcceptanceTerm: React.FC = ({ location }: any) => {
  const [checkedBox, setCheckedBox] = useState(false);

  const history = useHistory();
  const { setUserCondomob } = useAuth();
  const { t } = useTranslation();

  const { data } = location.state;

  const acceptTermsOfUse = () => {
    api
      .get(`/termoUso/aceitar`, {
        headers: { Authorization: data.token },
      })
      .then(res => {
        if (res.status === 200) {
          setUserCondomob(data);
          localStorage.setItem(
            '@CondomobWeb:userCondomob',
            JSON.stringify(data),
          );
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });

        console.error('@acceptTermsOfUse', err.response.data.error);
      });
  };

  return (
    <Container className="containerDefault">
      <MsgBoxContainer>
        <IconContent>
          <FontAwesomeIcon icon={['fas', 'handshake-alt']} />
        </IconContent>
        <strong>Termo de consentimento</strong>
        <p>
          Ao acessar o Condomob aceito de forma livre e inequívoca o tratamento
          de meus dados pessoais conforme definido no inteiro conteúdo da{' '}
          <a
            id="link"
            href={data.termoUsoLink}
            target="_blank"
            rel="noreferrer"
          >
            Política de Privacidade e Proteção de Dados
          </a>{' '}
          desta plataforma.
        </p>
        <AcceptanceBoxContainer>
          <div>
            <input
              type="checkbox"
              defaultChecked={checkedBox}
              onChange={() => {
                setCheckedBox(!checkedBox);
              }}
            />
            <span>li e aceito</span>
          </div>
          <button
            aria-label="TermoAceiteBotao"
            type="button"
            disabled={!checkedBox}
            onClick={acceptTermsOfUse}
          >
            AVANÇAR
          </button>
        </AcceptanceBoxContainer>
      </MsgBoxContainer>
    </Container>
  );
};

export default AcceptanceTerm;
