import { useEffect, useState } from 'react';
import { Switch, Route, useRouteMatch, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import ThirdPartyTicket from '../pages/Tickets/ThirdPartyTicket';

import NormalRequest from '../pages/Tickets/NormalRequest';

import TicketUpload from '../pages/Tickets/TicketUpload';
import TicketUploadDetail from '../pages/Tickets/TicketUpload/TicketUploadDetail';

import CopyTicket from '../pages/Tickets/CopyTicket';
import CTOpenReadMessage from '../pages/Tickets/CopyTicket/Open/ReadMessage';
import CTPaidReadMessage from '../pages/Tickets/CopyTicket/Paid/ReadMessage';
import CTConfig from '../pages/Tickets/CopyTicket/Config';

import api from '../services/api';
import LoadingCondomob from '../components/LoadingCondomob';

interface TicketModeProps {
  doGet: boolean;
  link: string;
  modo: {
    descricao: string;
    id: number;
    nome: string;
  };
  usuario: string;
  senha: string;
}

const TicketRoutes = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [ticketMode, setTicketMode] = useState<TicketModeProps>(
    {} as TicketModeProps,
  );
  // The `path` lets us build <Route> paths that are
  // relative to the parent route, while the `url` lets
  // us build relative links.
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();

  const renderTicket = () => {
    switch (ticketMode.modo.nome) {
      case 'Condomob - Financeiro':
        return (
          <Switch>
            <Route exact path={path}>
              <CopyTicket />
            </Route>
            <Route path={`${path}/ctOpenReadMessage`}>
              <CTOpenReadMessage state={location.state} />
            </Route>
            <Route path={`${path}/ctPaidReadMessage`}>
              <CTPaidReadMessage state={location.state} />
            </Route>
            <Route path="/ctConfig" component={CTConfig} />
          </Switch>
        );
      case 'Normal - Solicitações':
        return (
          <Switch>
            <Route exact path={path}>
              <NormalRequest />
            </Route>
          </Switch>
        );
      case 'Sistema de Terceiro':
        return (
          <Switch>
            <Route exact path={path}>
              <ThirdPartyTicket ticket={ticketMode} />
            </Route>
          </Switch>
        );
      default:
        return (
          <Switch>
            <Route exact path={path}>
              <TicketUpload />
            </Route>
            <Route path={`${path}/ticketUploadDetails`}>
              <TicketUploadDetail state={location.state} />
            </Route>
          </Switch>
        );
    }
  };

  function getData() {
    api
      .get('/financeiro3o/parametro')
      .then(res => {
        setTicketMode(res.data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="containerDefault">
      {loading ? (
        <>
          <LoadingCondomob />
        </>
      ) : (
        renderTicket()
      )}
    </div>
  );
};

export default TicketRoutes;
