import React, { useEffect, useState } from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';
import LoadingCondomob from '../../../../components/LoadingCondomob';

import {
  Container,
  ConfigContainer,
  LoadingContainer,
  Message,
  InputSelect,
} from './styles';

import api from '../../../../services/api';

interface DigitalTicketProps {
  label: string;
  value: boolean;
}

const CTConfig: React.FC = () => {
  const { t } = useTranslation();
  const options = [
    {
      label: `${t('tickets.copyTickets.configNoOption')}`,
      value: true,
    },
    { label: `${t('common.words.yes')}`, value: false },
  ];
  const [loading, setLoading] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [digitalTicketSelected, setDigitalTicketSelected] =
    useState<DigitalTicketProps>({} as DigitalTicketProps);

  const saveConfig = () => {
    if (!digitalTicketSelected) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('common.phrases.selectOption')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    } else {
      setLoadingSave(true);
      api
        .post(`/financeiro/configuracoes/save`, null, {
          params: { boletoDigital: digitalTicketSelected.value },
        })
        .then(res => {
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            text: `Feito`,
          });
          setLoadingSave(false);
        })
        .catch(error => {
          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: `${error.response.data.error}`,
            icon: `error`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
          setLoadingSave(false);
        });
    }
  };

  function getData() {
    const url = '/financeiro/configuracoes/get';
    api
      .get(url)
      .then(res => {
        const type = options.find(item => {
          return item.value === res.data.boletoDigital;
        }) as DigitalTicketProps;
        setDigitalTicketSelected(type);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <ConfigContainer>
          <Message>{`${t(
            'tickets.copyTickets.configQuestionDescription',
          )}`}</Message>
          <InputSelect
            id="inputSelectDestination"
            options={options}
            placeholder={`${t('common.phrases.selectOption')}...`}
            onChange={item => {
              setDigitalTicketSelected(item as DigitalTicketProps);
            }}
            value={digitalTicketSelected}
            isSearchable={false}
          />
          <Button
            disabled={loadingSave}
            title={`${t('common.words.save')}`}
            width={15}
            icon="check"
            onClick={saveConfig}
          />
        </ConfigContainer>
      )}
    </Container>
  );
};

export default CTConfig;
