import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { Button } from '../../../../components/Button';
import LoadingCondomob from '../../../../components/LoadingCondomob';

import NoImage from '../../../../assets/noImage.svg';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  RDImage,
  RDImageTitle,
  RDImageFooterInfo,
  RDImageFooterValue,
  RDImageFooterTotalReserved,
  RDInfo,
  RDInfoText,
  RDImageContainer,
} from './styles';

interface SpaceDetailsProps {
  imgLink: string;
  capacidade: number;
  descricao: string;
  nome: string;
  taxa: number;
}

const RequestReservationDetail: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(false);

  const { item }: { item: SpaceDetailsProps } = location.state;
  const { imgLink, capacidade, descricao, nome, taxa } = item;

  const history = useHistory();
  const { t } = useTranslation();

  const confirmUsageRules = () => {
    Swal.fire({
      text: `${t(
        'spaceReservation.requestReservationDetail.confirmUsageRulesText',
      )}`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        history.push({
          pathname: '/srrReservationAvailability',
          state: { item },
        });
      } else {
        return null;
      }
      return null;
    });
  };

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>
              {`${t('spaceReservation.requestReservationDetail.title')}`}
            </Title>
          </TitleContainer>
          <Content>
            <RDImageContainer>
              <RDImage bkgImg={imgLink === '' ? NoImage : imgLink}>
                <RDImageTitle>{nome}</RDImageTitle>
                <RDImageFooterInfo>
                  <RDImageFooterValue>
                    <div>
                      <FontAwesomeIcon icon={['far', 'dollar-sign']} />
                      <text>{`R$ ${taxa
                        .toFixed(2) // always two decimal digits
                        .replace('.', ',') // replace decimal point character with ,
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} `}</text>
                    </div>
                  </RDImageFooterValue>
                  <RDImageFooterTotalReserved>
                    <div>
                      {`${t(
                        'spaceReservation.requestReservationDetail.capacity',
                      )}`}
                      <text>
                        {capacidade}{' '}
                        {`${t(
                          'spaceReservation.requestReservationDetail.people',
                        )}`}
                      </text>
                    </div>
                  </RDImageFooterTotalReserved>
                </RDImageFooterInfo>
              </RDImage>
            </RDImageContainer>
            <RDInfo>
              <RDInfoText>
                <h1>{`${t('spaceReservation.observations')}`}</h1>
                <div dangerouslySetInnerHTML={{ __html: descricao }} />
              </RDInfoText>
              <Button
                title={`${t(
                  'spaceReservation.requestReservationDetail.checkAvailability',
                )}`}
                model="default"
                width={25}
                icon="check"
                onClick={confirmUsageRules}
              />
            </RDInfo>
          </Content>
        </Container>
      )}
    </>
  );
};

export default RequestReservationDetail;
