import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
      // Main colors
      --danger: #E44258;
      --danger25: #F9DADE;
      --dangerHover: #B63546;
      --info: #0085FF;
      --info25: #DFF0FF;
      --infoHover: #0071D9;
      --mute: #E0E0E0;
      --mute25: #EBEBEB;
      --muteHover: #C2C2C2;
      --primary: #295886;
      --primary25: #C9D5E1;
      --primary50: #93ABC2;
      --secondary: #7795BA;
      --secondary25: #DBE3ED;
      --secondary50: #B7C8DB;
      --success: #00CA72;
      --success25: #CCF4E3;
      --successHover: #00A25B;
      --warning: #FDB85D;
      --warning25: #FED39A;
      --warningHover: #FDAB3D;

      // GrayScalle palette
      --dark: #323338;
      --dark50: rgba(0, 0, 0, 0.5);
      --riverstone: #F5F6F8;
      --uIGray: #E6E9EF;
      --white: #FFFFFF;
      --wolfgray: #C5C7D0;

      // Auxiliary colors
      --americanGray: #808080;
      --aquamarine: #4ECCC6;
      --berry: #7E3B8A;
      --blackish: #333333;
      --brightGreen: #9CD326;
      --bubble: #FAA1F1;
      --chillblue: #66CCFF;
      --darkblue: #0086C0;
      --darkindigo: #401694;
      --darkOrange: #FF642E;
      --darkpurple: #784BD1;
      --darkRed: #BA3254;
      --eggYolk: #FFCC00;
      --explosive: #C4C4C4;
      --grassGreen: #037F4C;
      --green: #00C875;
      --indigo: #5559DF;
      --lightblue: #579BFC;
      --lipstick: #FF5AC4;
      --navy: #225091;
      --brown: #7f5347;
      --orangePeel: #FD9D21;
      --peach: #FFADAD;
      --purple: #A25DDC;
      --red: #E2445C;
      --river: #68A1BD;
      --saladish: #CAB641;
      --sofiaPink: #FF158A;
      --sunset: #FF7575;
      --winter: #9AADBD;

      /* ############################### */
      /*    colors to remove    */
      /* ############################### */

      /* Condomob colors */
      --primary75: rgba(77, 110, 152, .75);
      --secundary: #3c5f8b;

      /* Black & White colors */
      --black: #000000;
      --cultured: rgba(245, 245, 245, 1);
      --gray: rgba(108, 117, 125, 1);
      --gray25: rgba(108, 117, 125, 0.25);
      --gray50: rgba(108, 117, 125, 0.5);
      --gray75: rgba(108, 117, 125, 0.75);
      --platinum: rgba(235, 235, 235, 1);
      --white: rgba(255, 255, 255, 1);
      --white75: rgba(255, 255, 255, 0.75);
      --white50: rgba(255, 255, 255, 0.5);
      --white25: rgba(255, 255, 255, 0.25);

      --text: #444;
      --text-title: #084298;
      --text-body: #41464b;

      --titleColor: #3c5f8b;
      --titleBkgColor: #F5F5F5;
      --textLight: #ffffff;
      --textDark: #858585;
      --textDarkLight: #CCCCCC;
      --textAlert: #ff0000;

      --background: #eee;
      --bkgDefault: #EBEBEB;
      --backgroundHeaderLight: #EBEBEB;
      --backgroundHeader: #E0E0E0;
      --backgroundHeaderDark: #6C757D;
      --backgroundFooterLight: #EBEBEB;
      --backgroundFooterDark: #6C757D;
      --itemBackground: #F5F5F5;
      --primaryBackground: rgba(77, 110, 152, 0.1);

      --buttonText: #666666;
      --buttonBorder: #ccc;
      --buttonMenuClose: #EB4335;
      --buttonHover: #f9f9f9;
      --buttonBackground: #eee;

      --borderDark: #333;
      --borderMedium: #666;
      --borderLight: #999;
      --borderExtraLight: #ddd;
      --inputBorder: #ccc;

      --error: #f8d7da;
      --warningBorder: #FFE285;
      --warningText: #8F6D00;
      --info: #cff4fc;

      --noticeAlertBkg: #F7DEE0;
      --noticeAlertText: #94242D;
      --noticeAlertBorder: #EFBDC1;
      --noticeAlertBtnBkg: #E38C95;
      --noticeAlertBtnText: #B52C39;
      --noticeAlertBtnBorder: #DF7C86;
      --noticeOkBkg: #D1ECF1;
      --noticeOkText: #2B7F8C;
      --noticeOkBorder: #BEE5EB;
      --noticeOkBtnBkg: #A1DAE2;
      --noticeOkBtnText: #2B818C;
      --noticeOkBtnBorder: #82CFD9;
      --noticeWarningBkg: #FFF3CD;
      --noticeWarningText: #B88A00;
      --noticeWarningBorder: #FFEEBA;
      --noticeWarningBtnBkg: #FFE085;
      --noticeWarningBtnText: #CC9900;
      --noticeWarningBtnBorder: #FFCC33;

      --confirmed-background: #20AC6B;
      --confirmed-text: #198A55;
      --notConfirmed-background: #842029;
      --notConfirmed-text: #63181E;
      --waiting-background: #3c5f8b;

      --success-color: #0f5132;
      --success-background-color: #d1e7dd;
      --success-border-olor: #badbcc;

      --danger-color: #842029;
      --danger-background: #f8d7da;
      --danger-border-color: #f5c2c7;

      --elementOn: #198A55;
      --elementOff: #63181E;
      --elementGray: #666666;

      --pollOpen: #1C9B60;
      --pollClose: #A42832;
      --pollVoted: #006300;
      --poolChosenOptionBkg: #D1ECF1;
      --poolChosenOptionBorder: #BFE5EB;

      --calendarDateReserved: #C6D5E7;
      --adminPlateBackground: #C6D5E7;

      --messageColor01: rgba(51, 163, 123, 1);
      --messageColor02: rgba(41, 88, 135, 1);

      --riverstone: rgba(245, 246, 248, 1);

      // Status
      --Complaint: rgba(228,66,88,1); // Reclamação
      --Suggestion: rgba(0,133,255,1); // Sugestão
      --Doubt: rgba(255,203,0,1); // Dúvida
      --Warning: rgba(34,80,145,1); // Aviso
      --Warning75: rgba(34,80,145,0.75);
      --Warning50: rgba(34,80,145,0.5);
      --Warning25: rgba(34,80,145,0.25);
      --Delation: rgba(85,89,223,1); // Denúncia
      --Financial: rgba(0,200,117,1); // Financeiro
      --Praise: rgba(87,155,252,1); // Elogio
      --Other: rgba(128,128,128,1); // Outro
      --Construction: rgba(127,83,71,1); // Obras
      --Defense: rgba(51,51,51,1); // Defesa


    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    // Padrão font-size: 16px (Desktop)
    html {
    }

    body {
        background: var(--background);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-weight: 700;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed;
    }

    .bodyApp {
      background-color: #eee;
      /* background-image: url(${'https://app2.condomob.net/dist/img/bg-desk-login.jpg'}); */
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .bodyContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0 auto;
      /* padding: 0 10px; */
      @media (max-width: 500px) {
        max-height: ${window.innerHeight - 50}px;
      }

    }

    .dashboardDefault,
    .loginNoMenuDefault,
    .containerDefault {
      display: flex;
      flex-direction: column;
      /* max-width: 1280px; */
      width: -webkit-fill-available;
      align-self: center;
      background-color: var(--bkgDefault);
      overflow-y: hidden;
    }

    .containerDefault,
    .loginNoMenuDefault {
      height: calc(100vh - 3.9rem);
      font-size: 0.9rem;
      overflow-y: auto;
    }

    .loginNoMenuDefault {
      position: relative;
      background-size: cover;
    }

    .format-alert pre {
      font-family: 'Roboto', sans-serif;
    }

    .cardDefault {
      border-radius: 0.5rem;
      border: 1px solid #f5f5f6;

      :hover {
        -webkit-box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
        box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.12);
      }
    }

`;
