import { AxiosRequestConfig } from 'axios';
import {
  browserName,
  browserVersion,
  osName,
  osVersion,
  isMobile,
  isBrowser,
  isDesktop,
} from 'react-device-detect';

import { version } from '../../../package.json';

export default async (config: AxiosRequestConfig) => {
  config.headers.AppInfo = JSON.stringify({
    appVersion: version,
    platform: 'WEB',
    platformVersion: `${browserName} ${browserVersion}`,
    extra: {
      os: `${osName} ${osVersion}`,
      isMobile,
      isBrowser,
      isDesktop,
    },
    // pointControl: 'WebTeste',
  });

  return config;
};
