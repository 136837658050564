import { FormEvent, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import firebase from 'firebase/compat/app';

import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

import { Input } from '../../../components/Input';
import { Button } from '../../../components/Button';

import {
  Form,
  Card,
  Container,
  InputContainer,
  ButtonContainer,
  RegisterContainer,
} from './styles';

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [userEmail, setUserEmail] = useState('');

  const history = useHistory();
  const { t } = useTranslation();

  const forgotPassword = async (email: string) => {
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        toast.success(`${t('forgotPassword.forgotPassResetEmail')}`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => history.push('/'),
        });
        setLoading(false);
      })
      .catch(error => {
        const errorCode = error.code;
        console.error('errorCode', errorCode);
        const errorMessage = error.message;
        console.error('errorMessage', errorMessage);
        setLoading(false);
        toast.error(
          `
        ${
          errorCode === 'auth/user-not-found'
            ? `${t('forgotPassword.forgotPassEmailNotRegistered')}`
            : `${t('forgotPassword.forgotPassEmailInvalid')}`
        }
      `,
          {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          },
        );
      });
  };

  const recoverPassword = (e: FormEvent): void => {
    e.preventDefault();
    setLoading(true);
    forgotPassword(userEmail);
  };

  const handleOnChange = e => {
    setUserEmail(e.target.value);
  };

  return (
    <Container className="loginNoMenuDefault">
      <Card>
        <Form onSubmit={recoverPassword}>
          <InputContainer>
            <Input
              type="text"
              placeholder={`${t('common.words.email')}`}
              value={userEmail}
              name="email"
              onChange={handleOnChange}
            />
          </InputContainer>
          <ButtonContainer>
            <Button
              type="submit"
              title={`${t('forgotPassword.forgotPassResetPassword')}`}
              disabled={loading}
              model="default"
            />
          </ButtonContainer>
        </Form>
        <RegisterContainer>
          <Link to="/">{`${t('common.words.back')}`}</Link>
        </RegisterContainer>
      </Card>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default ForgotPassword;
