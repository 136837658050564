import styled from 'styled-components';

export const Container = styled.button<{
  btnColor: string;
  btnTextColor: string;
  marginVertical: number;
  model: string;
  width: number;
}>`
  display: flex;

  height: 2.1rem;
  min-height: 2.1rem;
  width: ${props => props.width}%;
  margin-top: ${props => props.marginVertical}px;
  margin-bottom: ${props => props.marginVertical}px;

  align-items: center;
  justify-content: center;
  align-self: center;

  background-color: ${props =>
    props.model === 'new'
      ? `var(--buttonBackground)`
      : props.model === 'default'
      ? `var(--primary)`
      : props.btnColor};

  border: ${props =>
    props.model === 'default' ? 'none' : `solid 1px var(--borderExtraLight)`};
  border-radius: 2rem;

  svg {
    font-size: 1rem;
    margin-right: 0.5rem;
    color: ${props =>
      props.model === 'default'
        ? `var(--white)`
        : props.model === 'new'
        ? `var(--primary)`
        : props.btnTextColor};
  }

  p {
    color: ${props =>
      props.model === 'default'
        ? `var(--white)`
        : props.model === 'new'
        ? `var(--primary)`
        : props.btnTextColor};
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-size: 0.8rem;

    line-height: 0.875rem;
  }

  transition: filter 0.2s;

  &:hover {
    filter: brightness(0.7);

    svg {
      color: ${props => props.model === 'new' && `var(--white)`};
    }
    p {
      color: ${props => props.model === 'new' && `var(--white)`};
    }
  }
`;

export const ButtonTitle = styled.text<{ titleColor?: string }>`
  font-size: 1rem;
  font-weight: bold;
  color: ${props => `var(--${props.titleColor})`};
  text-transform: uppercase;
`;

export const Spinner = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid;
  border-color: #fff #0000;
  animation: s1 1s infinite;

  @keyframes s1 {
    to {
      transform: rotate(0.5turn);
    }
  }
`;

Container.defaultProps = {
  width: 100,
};

ButtonTitle.defaultProps = {
  titleColor: 'white',
};
