import styled from 'styled-components';

export const Container = styled.div`
  color: var(--text-body);
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden auto;
  padding: 0.5rem 1rem;
`;

// *** Specific of the page ***

export const MessageHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
  }
  .reference {
    font-size: 1rem;
  }
  .statusTitle {
    font-size: 1.2rem;
    margin-top: 0.2rem;
  }
  .statusInfo {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--textAlert);
  }
`;

export const MessageHeaderDescription = styled.p<{
  color: string;
}>`
  color: ${props => (props.color ? props.color : '#000')};
  font-size: 1.5rem;
  font-weight: 700;
`;

export const MessageData = styled.div`
  display: flex;

  div {
    width: 100%;
  }

  .label,
  .labelRight {
    font-weight: 700;
    color: var(--primary);
  }
  .data,
  .dataRight {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .labelRight,
  .dataRight {
    text-align: right;
  }
`;

export const MessageComposition = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .label {
    font-weight: 700;
    color: var(--primary);
    margin: 1rem 0 0.5rem;
  }
  .data {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .item {
    margin-left: 2rem;
  }
`;

export const MessageInstructions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .label {
    font-weight: 700;
    color: var(--primary);
    margin: 1rem 0 0.5rem;
  }
  .data {
    font-weight: 700;
    font-size: 1.2rem;
  }

  .item {
    margin-left: 2rem;
  }
`;

export const QRCodeContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const MessageCode = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;

  .label {
    font-weight: 700;
    color: var(--primary);
    margin: 1rem 0 0.5rem;
  }
`;

export const MessageCodeElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-self: center;
  /* width: -webkit-fill-available; */
  justify-content: center;
`;

export const MessageCodeNumberText = styled.text`
  font-size: 1.2rem;
  text-align: center;
`;

export const MessageShare = styled.div`
  display: flex;
  width: -webkit-fill-available;
  justify-content: center;
  margin: 2rem 0;
`;
