import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)   // Loads translations from server
  .use(LanguageDetector)  // Detect user language (https://github.com/i18next/i18next-browser-languageDetector)
  .use(initReactI18next)  // passa the i18n instance to react-i18next
  .init({   //init i18next
    debug: false,
    fallbackLng: 'pt',
    interpolation: {
      escapeValue: false,   // react escape by default
    }
  })

  export default i18n;
