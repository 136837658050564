import React from 'react';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Barcode from 'react-barcode';

import { Button } from '../../../../components/Button';
import api from '../../../../services/api';

import {
  CardContainer,
  CardContent,
  Container,
  CardContentText,
  SharePostContainer,
  TitleContainer,
  TitleBackButton,
  Title,
} from './styles';
import {
  MessageCode,
  MessageCodeElement,
  MessageCodeNumberText,
  MessageComposition,
  MessageData,
  MessageHeader,
  MessageHeaderDescription,
  MessageInstructions,
  MessageShare,
} from '../../CopyTicket/Open/ReadMessage/styles';

const TicketUploadDetail = (location: any) => {
  const { state } = location;
  const { item } = state;

  const renderComposition = () => {
    return item.itens.map((elem, index) => (
      <li className="item" key={index.toString()}>
        {elem.descricao}
      </li>
    ));
  };

  const renderInstructions = () => {
    return item.instrucoes.map((elem, index) => (
      <li className="item" key={index.toString()}>
        {elem}
      </li>
    ));
  };

  const decodeHTML = (function () {
    // this prevents any overhead from creating the object each time
    const element = document.createElement('div');

    function decodeHTMLEntities(str) {
      if (str && typeof str === 'string') {
        // strip script/html tags
        // eslint-disable-next-line no-param-reassign
        str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, '');
        // eslint-disable-next-line no-param-reassign
        str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, '');
        element.innerHTML = str;
        // eslint-disable-next-line no-param-reassign
        str = element.textContent;
        element.textContent = '';
      }

      return str;
    }

    return decodeHTMLEntities;
  })();

  const history = useHistory();
  const { t } = useTranslation();

  const downloadFile = (fileURL: string): void => {
    if (fileURL.length > 0) {
      window.open(fileURL);
    } else {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('common.phrases.fileNotFound')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    }
  };

  const shareWhatsApp = () => {
    const textMessage = `Boleto disponível para download:\n${
      item?.descricao
    }\n${item?.valor ? `Valor: ${item?.valor}` : ''}\nAcesse: ${item?.link}`;
    window.open(`https://api.whatsapp.com/send?text=${textMessage}`, '_blank');
  };

  const sendByEmail = async (): Promise<void> => {
    const body = {
      id: item?.id ? item?.id : null,
      descricao: item?.descricao ? item?.descricao : null,
      vencimento: item?.vencimento ? item?.vencimento : null,
      valor: item?.valor ? item?.valor : null,
      link: item?.link,
    };
    return api
      .post(`/financeiro3o/receberEmail`, null, { params: body })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('tickets.copyTickets.ticketTitleModal')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        const str = `${t('tickets.copyTickets.ticketNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  return (
    <Container className="containerDefault">
      <TitleContainer>
        <TitleBackButton
          onClick={() => {
            history.goBack();
          }}
        >
          <FontAwesomeIcon icon={['far', 'angle-left']} />
          {`${t('common.words.back')}`}
        </TitleBackButton>
        <Title>{`${t('tickets.ticket')}`}</Title>
      </TitleContainer>
      <CardContainer>
        <CardContent>
          <MessageHeader>
            <p className="title">{item?.descricao}</p>
            <MessageHeaderDescription color={item.status?.cor}>
              {item.status?.descricao}
            </MessageHeaderDescription>
          </MessageHeader>
          {/* <MessageData>
            <div>
              <p className="label">{`${t('tickets.maturity')}`}</p>
              <p className="data">{item?.vencimento}</p>
            </div>
            <div>
              <p className="labelRight">{`${t('tickets.value')}`}</p>
              <p className="dataRight">R$ {item?.valor}</p>
            </div>
          </MessageData>

          {item.itens?.length > 0 && (
            <MessageComposition>
              <p className="label">{`${t(
                'tickets.copyTickets.composition',
              )}`}</p>
              <ul>{renderComposition()}</ul>
            </MessageComposition>
          )}

          {item.instrucoes?.length > 0 && (
            <MessageInstructions>
              <p className="label">{`${t(
                'tickets.copyTickets.instructions',
              )}`}</p>
              <ul>{renderInstructions()}</ul>
            </MessageInstructions>
          )}

          <MessageCode>
            <p className="label">{`${t('tickets.copyTickets.barCode')}`}</p>
            {item?.linhaDigitavel?.length > 0 ? (
              <>
                <MessageCodeElement>
                  <MessageCodeNumberText>
                    {!Number.isNaN(
                      Number(
                        item.linhaDigitavel.replace(/(\d)[\s.]+(?=\d)/g, '$1'),
                      ),
                    ) ? (
                      <Barcode
                        value={item.linhaDigitavel.replace(
                          /(\d)[\s.]+(?=\d)/g,
                          '$1',
                        )}
                        height={75}
                        background="transparent"
                      />
                    ) : (
                      <p
                        dangerouslySetInnerHTML={{
                          __html: decodeHTML(item.linhaDigitavel),
                        }}
                      />
                    )}
                  </MessageCodeNumberText>
                  <Button
                    title={`${t('tickets.copyCode')}`}
                    model="new"
                    width={30}
                    icon="copy"
                    onClick={() => {
                      navigator.clipboard.writeText(item.linhaDigitavel);
                    }}
                  />
                </MessageCodeElement>
              </>
            ) : (
              <p style={{ color: 'red' }}>{`${t('tickets.noTicket')}`}</p>
            )}
          </MessageCode> */}

          {item?.link && (
            <MessageShare>
              <Button
                title={`${t('common.words.downloadFile')}`}
                model="new"
                width={25}
                icon="file-download"
                onClick={() => downloadFile(item.link)}
              />
              <Button
                title={`${t('common.words.share')}`}
                model="new"
                width={25}
                icon="share-alt"
                onClick={shareWhatsApp}
              />
              <Button
                title={`${t('common.words.receiveByEmail')}`}
                model="new"
                width={25}
                icon="envelope-open-dollar"
                onClick={() => sendByEmail()}
              />
            </MessageShare>
          )}
        </CardContent>
      </CardContainer>
    </Container>
  );
};

export default TicketUploadDetail;
