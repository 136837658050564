import styled from 'styled-components';

export const Container = styled.div`
  overflow-y: auto;
`;

export const CardPool = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-flow: row wrap;
  gap: 2rem;
  padding: 2rem;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 22rem;
  background-color: var(--itemBackground);
  overflow: hidden;

  :hover {
    cursor: pointer;
  }
`;

export const CardContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  background-color: cadetblue;

  img {
    object-fit: fill;
    height: 17.3rem;
  }
`;

export const CardFooter = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  padding: 1rem;
  /* background-color: var(--backgroundHeaderLight); */
  background-color: var(--white);
  border-top: solid 1px var(--inputBorder);
  bottom: 0;
`;

export const CardFooterSpaceTitle = styled.text`
  font-weight: 700;
  margin-bottom: 0.5rem;
  color: var(--text);
`;

export const CardFooterRow = styled.div`
  display: flex;
`;

export const CardFooterValue = styled.div`
  display: flex;
  width: 50%;
  align-items: center;
  svg {
    margin-right: 0.5rem;
  }
`;

export const CardFooterTotalReserved = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  align-items: center;

  svg {
    margin-right: 0.5rem;
  }
`;
