import { useState } from 'react';
import {
  Content,
  Modal,
  Title,
  Button,
  Actions,
  BtnLink,
  LogoImg,
  Badge,
  BadgeList,
} from './styles';
import logo from '../../../assets/condomob-logo-rounded.png';
import appleStoreBadge from '../../../assets/apple-store-badge.svg';
import googlePlayBadge from '../../../assets/google-play-badge.svg';

const InstallAppModal: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [showStoreModal, setShowStoreModal] = useState(false);

  console.log({ showStoreModal });

  return (
    <>
      <Modal
        isOpen={isVisible}
        onRequestClose={() => {
          setIsVisible(false);
        }}
        shouldCloseOnOverlayClick={false}
        shouldCloseOnEsc={false}
        style={{
          overlay: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            alignContent: 'center',
          },
        }}
      >
        {!showStoreModal ? (
          <Content>
            <LogoImg src={logo} alt="Condomob Logo" />
            <Title>
              Essa é uma versão reduzida, para uma experiência completa instale
              o app.
            </Title>
            <Actions>
              <Button
                onClick={e => {
                  setShowStoreModal(true);
                }}
              >
                Instalar App Condomob
              </Button>
              <BtnLink onClick={() => setIsVisible(false)}>Agora não</BtnLink>
            </Actions>
          </Content>
        ) : (
          <Content>
            <LogoImg src={logo} alt="Condomob Logo" />
            <Title>
              Escolha sua loja de aplicativos para baixar o Condomob
            </Title>

            <BadgeList>
              <a href="https://apps.apple.com/us/app/condomob-condom%C3%ADnios/id1136370866?itsct=apps_box_link&itscg=30200">
                <Badge src={appleStoreBadge} alt="Apple Store" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=agile.ti.mobile.condomob&hl=pt_BR">
                <Badge src={googlePlayBadge} alt="Google Play" />
              </a>
            </BadgeList>

            <Actions>
              <BtnLink onClick={() => setIsVisible(false)}>Agora não</BtnLink>
            </Actions>
          </Content>
        )}
      </Modal>
    </>
  );
};

export default InstallAppModal;
