import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../../components/Button';

import api from '../../../../services/api';

import { Container, NewMessageContainer, FormRow } from './styles';

const Form: React.FC = () => {
  const [sendLoading, setSendLoading] = useState(false);
  const [requestText, setRequestText] = useState('');

  const { t } = useTranslation();

  const clearInfo = () => {
    setRequestText('');
  };

  const sendMessageForm = async (): Promise<void> => {
    if (requestText.length < 3) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('tickets.normalRequest.messageRequest')}`,
        icon: `warning`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
    } else {
      setSendLoading(true);
      const body = {
        mensagem: requestText,
      };
      await api
        .post(`/financeiro3o/segundaVia/save`, null, { params: body })
        .then(res => {
          if (res.status === 200) {
            Swal.fire({
              title: `${t('common.words.success')}`,
              text: `${t('tickets.normalRequest.messageSend')}`,
              icon: `success`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            clearInfo();
            setSendLoading(false);
          }
        })
        .catch(err => {
          if (
            err.response.status !== undefined &&
            err.response.status === 400
          ) {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: err.response.data.error,
              icon: `warning`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            clearInfo();
            setSendLoading(false);
            return;
          }

          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: err,
            icon: `error`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
          clearInfo();
          setSendLoading(false);
        });
    }
  };

  return (
    <Container className="containerDefault">
      <NewMessageContainer>
        <FormRow>
          <label htmlFor="textAreaMessage">{`${t(
            'tickets.normalRequest.requestTicket',
          )}`}</label>
          <textarea
            id="textAreaMessage"
            placeholder={`${t('tickets.normalRequest.placeholderMessage')}`}
            value={requestText}
            onChange={event => {
              setRequestText(event.target.value);
            }}
          />
          <label htmlFor="textAreaMessage">
            {`${t('tickets.normalRequest.requestDescription')}`}
          </label>
        </FormRow>

        <Button
          disabled={sendLoading}
          title={`${t('tickets.normalRequest.sendButton')}`}
          model="default"
          width={100}
          icon="check"
          onClick={sendMessageForm}
        />
      </NewMessageContainer>
    </Container>
  );
};

export default Form;
