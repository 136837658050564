/* eslint-disable no-nested-ternary */
import styled from 'styled-components';
import { GlobalStyle } from '../../../styles/global';

export const Container = styled.div``;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  max-height: 94%;

  padding: 10px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  padding: 10px 20px;

  border-radius: 10px;

  background-color: var(--white);
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  padding: 5px 0px;

  align-items: center;

  svg {
    color: var(--primary);
    font-size: 1.2rem;
  }
`;

export const HeaderRowTextStrong = styled.text`
  margin-left: 10px;
  font-size: 1.1rem;
  font-weight: bold;

  color: var(--primary);
`;

export const HeaderRowText = styled.text`
  margin-left: 10px;
  font-size: 1rem;

  color: var(--text);
`;

export const HeaderLine = styled.hr`
  margin-top: 20px;
`;

export const MessagesListContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: flex-start;
  flex-direction: column;

  max-height: 70%;

  overflow-x: hidden;
`;

export const MessageContentItemContainer = styled.div<{ isApp: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 60%;

  align-self: ${props => (props.isApp ? 'flex-end' : 'flex-start')};

  padding: 10px 20px 5px;

  border-radius: 5px;
  border: solid 1px
    ${props => (props.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)')};

  margin: 10px 0px;

  background-color: var(--riverstone);
`;

export const MessageContentItemSideBar = styled.div<{ isApp: boolean }>`
  position: absolute;
  display: flex;
  width: 5px;
  height: 100%;

  left: ${props => (props.isApp ? 'null' : 0)};
  right: ${props => (props.isApp ? 0 : 'null')};
  top: 0;

  background-color: ${props =>
    props.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)'};
`;

export const MessageContentItemUser = styled.text<{ isApp: boolean }>`
  font-weight: bold;
  font-size: 0.9rem;
  color: ${props =>
    props.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)'};

  margin-bottom: 5px;
  text-align: ${props => (props.isApp ? 'right' : 'left')};
`;

export const MessageContentItemText = styled.text<{ isApp: boolean }>`
  font-size: 1rem;
  color: var(--elementGray);

  margin: 5px 0px 20px;

  text-align: ${props => (props.isApp ? 'right' : 'left')};
`;

export const MessageContentItemDate = styled.text<{ isApp: boolean }>`
  position: absolute;
  bottom: 5px;
  font-size: 0.7rem;
  color: var(--elementGray);
  text-align: ${props => (props.isApp ? 'left' : 'right')};
`;

export const MessageContentFileList = styled.div<{ isApp: boolean }>`
  align-items: ${props => (props.isApp ? 'flex-end' : 'flex-start')};

  margin-bottom: 20px;
`;

export const MessageContentFileButton = styled.button<{
  isApp?: boolean;
}>`
  flex-direction: row;
  max-width: 30%;

  padding: 3px 10px;
  margin: 3px 0px;

  align-items: center;
  justify-content: center;

  border-radius: 20px;
  border: solid 1px
    ${props => (props.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)')};

  background-color: var(--white);

  white-space: nowrap;
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
`;

export const MessageContentFileName = styled.text<{ isApp?: boolean }>`
  font-size: 12px;
  font-weight: bold;
  color: ${props =>
    props.isApp ? 'rgba(51, 163, 123, 1)' : 'rgba(41, 88, 135, 1)'};

  margin-left: 5px;
`;

export const MessageNewMessageContainer = styled.div``;

export const MessageNewMessageInputContainer = styled.div<{
  filesAttached: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 10px;
`;

export const MessageNewMessageInput = styled.input`
  min-height: 30px;
  max-height: 60px;

  flex-grow: 1;

  border-radius: 15px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--gray50);

  padding: 10px 15px;
  margin: 0px 5px;

  color: var(--gray);
  background-color: var(--riverstone);

  outline-width: 0px;
`;

/* Attachment file */
export const MessageNewMessageAttachFileContainer = styled.div`
  margin-bottom: 10px;
`;

export const MessageNewMessageAttachFileTitle = styled.div`
  font-size: 14px;
  color: var(--gray);

  margin-bottom: 5px;
`;

export const MessageNewMessageRemoveFileButton = styled.button`
  width: 24px;
  height: 24px;
  border-radius: 12px;
  border-width: 0px;

  background-color: var(--noticeAlertText);

  justify-content: center;
  align-items: center;

  margin-left: 5px;
`;

export const MessageNewMessageAttachFileListContainer = styled.div``;

export const MesageNewMessageAttachFileListItem = styled.div`
  flex-direction: row;

  justify-content: space-between;
`;

export const FileAttachmentButton = styled.button`
  width: 34px;
  height: 34px;

  border-radius: 17px;

  justify-content: center;
  align-items: center;

  background-color: var(--white);

  border-width: 0px;

  svg {
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const FileAttachmentInput = styled.input`
  display: none;
`;

export const SendMessageButton = styled.button`
  width: 34px;
  height: 34px;

  border-radius: 17px;
  margin-left: 5px;

  justify-content: center;
  align-items: center;

  background-color: var(--primary);

  outline-width: 0px;
  border-width: 0px;

  svg {
    size: 1rem;
    color: var(--white);

    margin-left: 3px;
  }
`;

export const MessageDetailsOptionsButton = styled.button`
  position: absolute;

  top: 15px;
  right: 15px;

  width: 40px;
  height: 40px;
  border-radius: 20px;

  justify-content: center;
  align-items: center;

  outline-width: 0px;
  border-width: 0px;

  background-color: var(--white);

  svg {
    color: var(--gray50);
    font-size: 1.5rem;
  }
`;

// Criar um modal simples
export const ModalOptionsContainer = styled.div<{ visible: boolean }>`
  display: ${props => (props.visible ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  height: 100%;

  align-items: center;

  background-color: var(--gray50);

  z-index: 10;
`;

export const ModalOptionsContent = styled.div`
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 80%;
  height: 20%;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid var(--gray25);

  background-color: var(--white);

  z-index: 20;
`;

export const ModalOptionsCloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;

  width: 30px;
  height: 30px;

  border: none;
  border-radius: 15px;
  outline: none;

  justify-content: center;
  align-items: center;

  background-color: transparent;

  svg {
    color: var(--primary50);
    font-size: 1.5rem;
  }
`;

export const ModalOptionsTitle = styled.text`
  font-size: 1rem;
  font-weight: bold;
  text-align: center;

  color: var(--gray);

  margin: 10px 0px 30px;
  text-transform: uppercase;
`;
