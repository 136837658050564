import styled from 'styled-components';

// const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const Container = styled.div`
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ListItem = styled.button`
  display: flex;
  width: -webkit-fill-available;
  border: none;
  background-color: #fff;
  border-bottom: solid 0.5px #ccc;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;

  :hover {
    background-color: var(--buttonHover);
  }
`;

export const ListItemTitle = styled.div`
  display: flex;
  width: 33%;
  height: 4rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.3rem;

  strong {
    font-size: 1.2rem;
    text-align: left;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const ListItemValue = styled.div`
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
`;

export const ListItemIcon = styled.div`
  display: flex;
  width: 33%;

  svg {
    margin-left: auto;
  }
`;
