import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../components/LoadingCondomob';
import { Input } from '../../components/Input';
import Tabs from '../../components/Tabs';

import AccountabilityPage from './Accountability';
import DocsContracts from './DocumentsContracts';
import MinutesMeeting from './MinutesMeeting';

import { Container, TitleContainer, TitleBackButton, Title } from './styles';

const Documents: React.FC = () => {
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();

  const state = history.location.state || {};
  const activeTab = state.activeTab !== undefined ? state.activeTab : 0;

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.replace({
                  pathname: '/dashboard',
                });
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('documents.title')}`}</Title>
          </TitleContainer>
          <Tabs
            tabElement={[
              {
                tabTitle: `${t('documents.minutesMeeting.title')}`,
                tabIcon: 'file-alt',
              },
              {
                tabTitle: `${t('documents.accountabilityPage.title')}`,
                tabIcon: 'file-invoice-dollar',
              },
              {
                tabTitle: `${t('documents.docsContracts.title')}`,
                tabIcon: 'file',
              },
            ]}
            tabContentItem={[MinutesMeeting, AccountabilityPage, DocsContracts]}
            tabActive={activeTab}
          />
        </Container>
      )}
    </>
  );
};

export default Documents;
