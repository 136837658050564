import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Tabs from '../../../components/Tabs';

import VAInfo from './VAInfo';
import VAMessagesList from './VAMessagesList';
import VAVoteInfo from './VAVoteList';

import { Container, TitleContainer, TitleBackButton, Title } from './styles';

const VATabs: React.FC = ({ location }: any) => {
  const { activeTab } = location.state;

  const history = useHistory();
  const { t } = useTranslation();

  return (
    <Container className="containerDefault">
      <TitleContainer>
        <TitleBackButton
          onClick={() => {
            history.push({
              pathname: '/virtualAssemblies',
            });
          }}
        >
          <FontAwesomeIcon
            icon={['far', 'angle-left']}
            size="lg"
            color="#3c5f8b"
          />
          {t('common.words.back')}
        </TitleBackButton>
        <Title>{t('virtualAssemblies.title')}</Title>
      </TitleContainer>
      <Tabs
        tabElement={[
          {
            tabTitle: `${t('virtualAssemblies.tabs.tab01')}`,
            tabIcon: 'comments',
          },
          {
            tabTitle: `${t('virtualAssemblies.tabs.tab02')}`,
            tabIcon: 'comment-alt-lines',
          },
          {
            tabTitle: `${t('virtualAssemblies.tabs.tab03')}`,
            tabIcon: 'question',
          },
        ]}
        tabContentItem={[VAInfo, VAMessagesList, VAVoteInfo]}
        tabDataItem={location.state}
        tabActive={activeTab}
      />
    </Container>
  );
};

export default VATabs;
