import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import qs from 'qs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

import { Button } from '../../../components/Button';
import ImageDefault from '../../../assets/talkTo_ImgDefault.svg';
import LoadingCondomob from '../../../components/LoadingCondomob';

import api from '../../../services/api';

import {
  Container,
  ImageContainer,
  NewMessageContainer,
  ImageItem,
  ButtonImgSelection,
  FormRow,
  InputSelect,
  CheckBoxRow,
} from './styles';

interface ListOptionsProps {
  label?: string;
  value: number;
}

const NewMessage: React.FC = ({ dataItem }: any) => {
  const [loading, setLoading] = useState(true);
  const [listMessageTo, setListMessageTo] = useState<ListOptionsProps[]>([]);
  const [listMessageType, setListMessageType] = useState<ListOptionsProps[]>(
    [],
  );
  const [deadlineMessage, setDeadlinemessage] = useState('');
  const [messageImageBase64, setMessageImageBase64] = useState<
    string | ArrayBuffer | null
  >('');
  const [imgReduced, setImgReduced] = useState<any>(null);
  const [messageToOpt, setMessageToOpt] = useState<ListOptionsProps>(
    {} as ListOptionsProps,
  );
  const [messageTypeOpt, setMessageTypeOpt] = useState<ListOptionsProps>(
    {} as ListOptionsProps,
  );
  const [messageSubject, setMessageSubject] = useState('');
  const [messageInitialText, setInitialMessageText] = useState('');
  const [messageOcurrenceBook, setMessageOcurrenceBook] = useState(false);

  const { t } = useTranslation();
  const history = useHistory();

  const getMessageOptions = async (): Promise<void> => {
    return api
      .get(`/mensagem/opcoes`)
      .then(res => {
        if (res.status === 200) {
          setLoading(false);
          // populate MessageTo
          const toList: ListOptionsProps[] = [];
          res.data.paraList.map(item =>
            toList.push({ label: `${item.nome}`, value: item.id }),
          );
          setListMessageTo(toList);
          // Populate MessageType
          const typeList: ListOptionsProps[] = [];
          res.data.tipoList.map(item =>
            typeList.push({ label: `${item.nome}`, value: item.id }),
          );
          setListMessageType(typeList);
          setDeadlinemessage(res.data.prazo);
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        }).then(result => {
          if (result.isConfirmed) {
            history.push('/');
          }
        });

        console.error('@getMessageOptions', err.response.data.error);
      });
  };

  // Reduce Image file
  const resizeFile = (file: File) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        'JPEG',
        100,
        0,
        uri => {
          resolve(uri);
        },
        'file',
      );
    });

  // Load image file
  const onChange = async (file: File) => {
    try {
      const image = await resizeFile(file);
      setImgReduced(image);
    } catch (err) {
      console.error(err);
    }
  };

  const getMessageImage = async e => {
    // Get all image info
    const imgPath = URL.createObjectURL(e.target.files[0]);

    const img = new Image();
    img.onload = () => {
      onChange(e.target.files[0]);
    };
    img.src = imgPath;
  };

  const clearInfo = () => {
    setMessageToOpt({} as ListOptionsProps);
    setMessageTypeOpt({} as ListOptionsProps);
    setInitialMessageText('');
    setMessageOcurrenceBook(false);
    setMessageImageBase64('');
  };

  const createMessage = async (): Promise<void> => {
    const body = {
      assunto: messageSubject,
      livroOcorrencia: messageOcurrenceBook,
      para: messageToOpt.value,
      texto: messageInitialText,
      tipo: messageTypeOpt.value,
    };
    setLoading(true);

    return api
      .post(`/mensagem/create`, qs.stringify(body))
      .then(res => {
        if (res.status === 200) {
          const { id } = res.data;
          history.push({
            pathname: '/tcMessageDetails',
            state: {
              id,
            },
          });

          clearInfo();
          setLoading(false);
        }
      })
      .catch(err => {
        if (err.response.status !== undefined && err.response.status === 400) {
          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: err.response.data.error,
            icon: `warning`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
          clearInfo();
          setLoading(false);
          return;
        }

        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
        clearInfo();
        setLoading(false);
      });
  };

  const validateMsg = async () => {
    if (Object.keys(messageToOpt).length === 0) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('talkCondo.newMessage.messageRecipient')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (Object.keys(messageTypeOpt).length === 0) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('talkCondo.newMessage.messageType')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (messageSubject.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('talkCondo.newMessage.messageSubject')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (messageInitialText.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('talkCondo.newMessage.messageText')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (messageInitialText.trim() === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('talkCondo.newMessage.messageText')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (deadlineMessage !== '') {
      const deadlineConfirm = await Swal.fire({
        text: `${deadlineMessage}`,
        icon: `warning`,
        backdrop: `rgba(0,0,0,0.9)`,
        showCancelButton: true,
        cancelButtonText: `${t('common.words.cancel')}`,
      });

      if (!deadlineConfirm.isConfirmed) {
        return;
      }
    }

    createMessage();
  };

  useEffect(() => {
    clearInfo();
    getMessageOptions();
    if (dataItem?.acordoBoleto) {
      setInitialMessageText(`${t('talkCondo.newMessage.agreement')}`);
    }
  }, []);

  useEffect(() => {
    if (dataItem?.acordoBoleto) {
      setMessageToOpt({ value: 1 });
    }
  }, [listMessageTo]);

  useEffect(() => {
    if (dataItem?.acordoBoleto) {
      setMessageTypeOpt({ value: 5 });
    }
  }, [listMessageType]);

  useEffect(() => {
    // store image file and convert do base64
    if (imgReduced !== null) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setMessageImageBase64(reader.result);
      };
      reader.readAsDataURL(imgReduced);
    }
  }, [imgReduced]);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <NewMessageContainer>
            <ImageContainer>
              <ImageItem
                src={
                  messageImageBase64 === ''
                    ? ImageDefault
                    : (messageImageBase64 as string)
                }
              />
            </ImageContainer>
            {/* <ButtonImgSelection htmlFor="msgImg">
              <FontAwesomeIcon icon={['fas', 'file-upload']} />
              <input
                id="msgImg"
                type="file"
                onChange={getMessageImage}
                accept="image/*"
              />
              {t('common.phrases.selectImage')}
            </ButtonImgSelection> */}
            {listMessageTo.length > 0 && (
              <FormRow>
                <label htmlFor="inputSelectDestination">Para</label>
                <InputSelect
                  id="inputSelectDestination"
                  options={listMessageTo}
                  placeholder={t('common.phrases.selectOption')}
                  defaultValue={dataItem?.acordoBoleto && listMessageTo[1]}
                  onChange={(item: ListOptionsProps | any) => {
                    setMessageToOpt({ value: item.value });
                  }}
                  isSearchable={false}
                />
              </FormRow>
            )}
            {listMessageType.length > 0 && (
              <FormRow>
                <label htmlFor="inputSelectType">Tipo</label>
                <InputSelect
                  id="inputSelectType"
                  options={listMessageType}
                  placeholder={t('common.phrases.selectOption')}
                  defaultValue={dataItem?.acordoBoleto && listMessageType[5]}
                  onChange={(item: ListOptionsProps | any) => {
                    setMessageTypeOpt({ value: item.value });
                  }}
                  isSearchable={false}
                />
              </FormRow>
            )}
            <FormRow>
              <label htmlFor="inputSubject">Assunto</label>
              <input
                id="inputSubject"
                placeholder={`${t('common.words.subject')}`}
                onChange={event => {
                  setMessageSubject(event.target.value);
                }}
              />
              {/* <InputSelect
                  id="inputInitialMessage"
                  options={listMessageType}
                  placeholder={t('common.phrases.selectOption')}
                  defaultValue={dataItem?.acordoBoleto && listMessageType[5]}
                  onChange={(item: ListOptionsProps | any) => {
                    setMessageTypeOpt({ value: item.value });
                  }}
                  isSearchable={false}
                /> */}
            </FormRow>
            <FormRow>
              <label htmlFor="inputInitialMessage">Mensagem inicial</label>
              <textarea
                id="inputInitialMessage"
                placeholder={`${t('talkCondo.newMessage.leaveMessage')}`}
                value={messageInitialText}
                onChange={event => {
                  setInitialMessageText(event.target.value);
                }}
                rows={6}
              />
            </FormRow>
            <CheckBoxRow>
              <input
                type="checkbox"
                id="checkboxInclude"
                name="checkboxInclude"
                checked={messageOcurrenceBook}
                onChange={() => setMessageOcurrenceBook(!messageOcurrenceBook)}
              />
              <text>{`${t('talkCondo.newMessage.occurrenceBook')}`}</text>
            </CheckBoxRow>

            <Button
              title={`${t('talkCondo.newMessage.sendMessage')}`}
              model="default"
              width={100}
              icon="check"
              onClick={validateMsg}
            />
          </NewMessageContainer>
        </Container>
      )}
    </>
  );
};

export default NewMessage;
