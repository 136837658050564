import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { format } from 'date-fns';

import { Button } from '../../../../components/Button';
import LoadingCondomob from '../../../../components/LoadingCondomob';
import EmptyList from '../../../../components/EmptyList';

import api from '../../../../services/api';

import {
  Container,
  LoadingContainer,
  ListContainer,
  CardContainer,
  CardContent,
  CardContentText,
  CardContentTextPostDate,
  SharePostContainer,
  Content,
  CardPool,
  ReplyContainer,
} from './styles';

interface TicketProps {
  dataResposta: number;
  dataSolicitacao: number;
  fileGcs: boolean;
  id: number;
  link: string;
  mensagem: string;
  resposta: string;
  unidade: {
    bloqueado: boolean;
    dataInquilino: number;
    dataProprietario: number;
    id: number;
    publico: boolean;
    sindico: boolean;
    vazia: boolean;
    virtual: boolean;
  };
  usuario: {
    ativo: boolean;
    email: string;
    ultimoAcesso: number;
    usuario: string;
  };
}

const List: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState<TicketProps[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const downloadFile = fileURL => {
    if (fileURL.length > 0) {
      window.open(fileURL);
    } else {
      const str = `${t('common.phrases.fileNotFound')}`;
      Swal.fire({
        icon: 'error',
        title: 'Ops...',
        html: `<pre>${str}</pre>`,
        customClass: {
          popup: 'format-alert',
        },
      });
    }
  };

  const sendByEmail = itemId => {
    const body = {
      id: itemId,
    };
    return api
      .get(`/financeiro3o/segundaVia/receberEmail`, { params: body })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('tickets.copyTickets.ticketTitleModal')}`;
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        // console.error('@sendNormalRequestByEmail', err.response.data.error);
        const str = `${t('tickets.copyTickets.ticketNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const renderItem = () => {
    return (
      <Content>
        <CardPool>
          {tickets.map(item => (
            <CardContainer
              key={item.id}
              onClick={() => {
                return null;
              }}
            >
              <CardContent>
                <>
                  <CardContentText>
                    <p>{`${t('tickets.normalRequest.request')}${format(
                      new Date(item.dataSolicitacao),
                      'dd/MM/yyyy HH:mm:ss',
                    )}`}</p>
                    <strong>{item.mensagem}</strong>
                    {item.dataResposta > 0 && (
                      <>
                        <ReplyContainer>
                          <CardContentTextPostDate>
                            <FontAwesomeIcon icon={['fal', 'calendar']} />{' '}
                            {format(
                              new Date(item.dataResposta),
                              'dd/MM/yyyy HH:mm:ss',
                            )}
                          </CardContentTextPostDate>
                          <p>{item.resposta}</p>

                          <SharePostContainer>
                            <Button
                              title={`${t('common.words.downloadFile')}`}
                              model="new"
                              width={50}
                              icon="file-download"
                              onClick={() => downloadFile(item.link)}
                            />
                            <Button
                              title={`${t('common.words.receiveByEmail')}`}
                              model="new"
                              width={50}
                              icon="envelope"
                              onClick={() => {
                                sendByEmail(item.id);
                              }}
                            />
                          </SharePostContainer>
                        </ReplyContainer>
                      </>
                    )}
                  </CardContentText>
                </>
              </CardContent>
            </CardContainer>
          ))}
        </CardPool>
      </Content>
    );
  };

  function getData() {
    const url = '/financeiro3o/segundaVia/list';
    api
      .get(url)
      .then(res => {
        setLoading(false);
        setTickets(res.data);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <>
          {tickets.length === 0 ? (
            <EmptyList />
          ) : (
            <ListContainer>{renderItem()}</ListContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default List;
