import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 2.5rem;

  p {
    margin-left: 0.5rem;
    font-weight: 700;
    color: var(--primary);
  }
`;

export const Spinner = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 4px solid;
  border-color: var(--primary) #0000;
  animation: s1 1s infinite;

  @keyframes s1 {
    to {
      transform: rotate(0.5turn);
    }
  }
`;
