import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';

import { Button } from '../../../components/Button';
import LoadingCondomob from '../../../components/LoadingCondomob';

import imgEmptyList from '../../../assets/emptyData.svg';

import api from '../../../services/api';

import {
  Container,
  MessagesPool,
  EmptyList,
  CardMessageContainer,
  CardMessageHeader,
  CardMessageHeaderText,
  CardMessageContent,
  CardMessageContentHeader,
  CardMessageContentHeaderTitle,
  CardMessageContentHeaderInfo,
  CardMessageContentInfo,
  CardMessageContentInfoHeader,
  CardMessageInnerContent,
  CardMessageContentText,
  CarMessageContentButtons,
  CardMessageFooter,
  CardMessageFooterInfoContainer,
} from './styles';

interface ReservationListProps {
  convidados: Array<string>;
  dataReserva: string;
  dataResposta: number;
  dataSolicitacao: number;
  espaco: {
    descricao: string;
    imgLink: string;
    nome: string;
    capacidade: number;
  };
  horarioFim: string;
  horarioInicio: string;
  id: number;
  mensagemId: number;
  motivo: string;
  numeroConvidados: number;
  observacao: string;
  por: string;
  solicitante: string;
  status: {
    id: number;
    nome: string;
  };
}

const MyReservations: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(true);
  const [reservationList, setReservationList] = useState<
    ReservationListProps[]
  >([]);

  const history = useHistory();
  const { t } = useTranslation();

  const getMyReservations = async (): Promise<void> => {
    return api
      .get(`/reservaEspaco/list`)
      .then(res => {
        setReservationList(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.error('@getMyReservations', err);
        setLoading(false);
      });
  };

  useEffect(() => {
    getMyReservations();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          {reservationList.length === 0 ? (
            <EmptyList>
              <img src={imgEmptyList} alt="Lista vazia" />
              <p>{t('spaceReservation.myReservation.emptyListResult')}</p>
            </EmptyList>
          ) : (
            <MessagesPool>
              {reservationList.map(item => {
                return (
                  <CardMessageContainer className="cardDefault">
                    <CardMessageHeader status={item.status.nome}>
                      <FontAwesomeIcon
                        icon={[
                          'fas',
                          `${
                            // eslint-disable-next-line no-nested-ternary
                            item.status.nome === 'Confirmada'
                              ? 'check'
                              : item.status.nome === 'Aguardando'
                              ? 'clock'
                              : 'times'
                          }`,
                        ]}
                      />
                      <CardMessageHeaderText>
                        {item.status.nome}
                      </CardMessageHeaderText>
                    </CardMessageHeader>
                    <CardMessageContent>
                      <CardMessageContentHeader>
                        <img src={item.espaco.imgLink} alt={item.espaco.nome} />{' '}
                        <CardMessageContentHeaderTitle>
                          <strong>{item.espaco.nome}</strong>
                          {item.motivo}
                        </CardMessageContentHeaderTitle>
                      </CardMessageContentHeader>
                      <CardMessageContentHeaderInfo>
                        <FontAwesomeIcon icon={['fas', 'calendar-day']} />
                        {dayjs(item.dataReserva).format('DD/MM/YYYY')}{' '}
                        <strong>{item.horarioInicio}</strong> às{' '}
                        <strong>{item.horarioFim}</strong>
                      </CardMessageContentHeaderInfo>
                      <CardMessageContentInfo>
                        <CardMessageContentInfoHeader>
                          {`${t(
                            'spaceReservation.myReservation.myReservation',
                          )}`}
                        </CardMessageContentInfoHeader>
                        {item.espaco.descricao === '' ? (
                          <CardMessageContentText>
                            {`${t(
                              'spaceReservation.myReservation.noDefinedRules',
                            )}`}
                          </CardMessageContentText>
                        ) : (
                          <CardMessageInnerContent>
                            <CardMessageContentText
                              dangerouslySetInnerHTML={{
                                __html: item.espaco.descricao,
                              }}
                            />
                          </CardMessageInnerContent>
                        )}
                      </CardMessageContentInfo>
                      {item.status.nome !== 'Cancelada' ? (
                        <CarMessageContentButtons>
                          <Button
                            icon="times"
                            model="default"
                            onClick={() => {
                              if (item.mensagemId > 0) {
                                history.push({
                                  pathname: 'tcMessageDetails',
                                  state: {
                                    id: item.mensagemId,
                                  },
                                });

                                return;
                              }

                              history.push({
                                pathname: '/srrReservationCancel',
                                state: {
                                  id: item.id,
                                  nome: item.espaco.nome,
                                  dataSolicitacao: item.dataSolicitacao,
                                },
                              });
                            }}
                            title={`${t('common.words.cancel')}`}
                            width={50}
                          />
                          <Button
                            title={`${t(
                              'spaceReservation.myReservation.guests',
                            )}`}
                            model="default"
                            width={50}
                            icon="clipboard-list-check"
                            onClick={() => {
                              history.push({
                                pathname: '/srrGuestList',
                                state: {
                                  id: item.id,
                                  nome: item.espaco.nome,
                                  dataSolicitacao: item.dataSolicitacao,
                                  convidados: item.convidados,
                                  capacidade: item.espaco.capacidade,
                                },
                              });
                            }}
                          />
                        </CarMessageContentButtons>
                      ) : null}
                      {item.dataResposta !== 0 && (
                        <CardMessageContentInfo>
                          <CardMessageContentText>
                            <p>
                              <FontAwesomeIcon icon={['fas', 'user-circle']} />
                              {item.solicitante}
                            </p>
                            <p>
                              <FontAwesomeIcon icon={['fas', 'reply']} />
                              {dayjs(item.dataResposta).format(
                                'DD/MM/YYYY HH:mm:ss',
                              )}
                            </p>
                          </CardMessageContentText>
                        </CardMessageContentInfo>
                      )}
                    </CardMessageContent>
                    <CardMessageFooter>
                      <CardMessageFooterInfoContainer>
                        <FontAwesomeIcon icon={['fas', 'calendar']} />
                        {dayjs(item.dataSolicitacao).format(
                          'DD/MM/YYYY HH:mm:ss',
                        )}
                      </CardMessageFooterInfoContainer>
                      <CardMessageFooterInfoContainer>
                        <FontAwesomeIcon icon={['fas', 'user-edit']} />
                        {item.por}
                      </CardMessageFooterInfoContainer>
                    </CardMessageFooter>
                  </CardMessageContainer>
                );
              })}
            </MessagesPool>
          )}
        </Container>
      )}
    </>
  );
};

export default MyReservations;
