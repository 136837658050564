import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: -webkit-fill-available;
  height: calc(100vh - 12rem);
  padding: 1rem;

  border-radius: 0.3rem;

  background-color: var(--white);

  img {
    height: 20vh;
    margin-bottom: 1rem;
  }

  p {
    font-weight: 700;
    text-align: center;
    color: var(--primary);
  }
`;
