import { useEffect, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';

import Login from '../pages/Login';
import SignUp from '../pages/Login/SignUp';
import ForgotPassword from '../pages/Login/ForgotPassword';
import ListUnits from '../pages/LoginUnit';
import ListUnitCity from '../pages/LoginUnit/LoginUnitCity';
import ListUnitCondo from '../pages/LoginUnit/LoginUnitCondo';
import ListUnitInfo from '../pages/LoginUnit/LoginUnitInfo';
import AcceptanceTerm from '../pages/AcceptanceTerm';

import { useAuth } from '../contexts/AuthContext';
import { getAdm, storeAdm } from '../services/adm';

const AuthRoutes: React.FC = () => {
  const { userUnits } = useAuth();
  const history = useHistory();
  const [admParam, setAdmParam] = useState('');

  useEffect(() => {
    const admSessionId = getAdm();
    const queryParams = new URLSearchParams(history.location.search);
    const admQueryParam = queryParams.get('administradora');

    if (admQueryParam !== null) {
      storeAdm(admQueryParam);
      setAdmParam(`?administradora=${admQueryParam}`);
    }

    if (admSessionId !== null) {
      setAdmParam(`?administradora=${admSessionId}`);
    }
  }, []);

  return (
    <Switch>
      {/* Login Stack  */}
      <Route exact path="/" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgotpassword" component={ForgotPassword} />
      <Route path="/listunits" component={ListUnits} />
      <Route path="/listunitcity" component={ListUnitCity} />
      <Route path="/listunitcondo" component={ListUnitCondo} />
      <Route path="/listunitinfo" component={ListUnitInfo} />
      <Route path="/acceptanceTerm" component={AcceptanceTerm} />
      {userUnits === null && history.push(`/${admParam}`)}
    </Switch>
  );
};

export default AuthRoutes;
