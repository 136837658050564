import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
`;

export const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 0.4rem;
  background-color: var(--white);

  padding: 1rem;
`;

export const Message = styled.text`
  display: flex;
  margin: 1rem 0;
`;

export const InputSelect = styled(Select).attrs({
  styles: {
    option: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    input: base => ({
      ...base,
      fontSize: '0.8rem',
      margin: 0,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '0.9rem',
      color: 'var(--text)',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      border: `solid 1px var(--primary)`,
      display: 'flex',
      height: 30,
      borderRadius: 5,
      fontSize: '0.9rem',
      marginBottom: '1rem',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: () => ({
      color: 'var(--primary)',
    }),
  },
})`
  width: 100%;
  margin-left: 3px;
`;
