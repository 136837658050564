import styled from 'styled-components';
import Select from 'react-select';

export const Container = styled.div``;

export const NewMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  padding: 1rem;
  margin: 1rem;

  border-radius: 0.4rem;
`;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  align-self: center;
`;

export const ImageItem = styled.img`
  width: 100%;
  max-height: 22vh;
  object-fit: contain;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  padding: 3px 0px;
  margin: 2px 0px;

  label {
    color: var(--primary);
    font-weight: 700;
    font-size: 0.7rem;
    margin-left: 3px;
  }

  input,
  select,
  textarea {
    width: 100%;
    margin-left: 3px;
    border: 1px solid #336699;
    border-radius: 4px;
    padding: 5px;
  }

  textarea {
    resize: none;
  }
`;

export const ButtonImgSelection = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16rem;
  min-height: 2.2rem;
  align-self: center;
  border: 1px solid var(--borderExtraLight);
  border-radius: 0.3rem;
  background-color: var(--buttonBackground);

  font-weight: 700;
  font-size: 0.8rem;
  color: var(--primary);

  margin: 1rem 0rem;

  cursor: pointer;

  :hover {
    background-color: var(--primary);
    color: var(--white);
  }

  svg {
    margin-right: 0.5rem;
    font-size: 1rem;
  }

  input[type='file'] {
    display: none;
  }
`;

export const InputSelect = styled(Select).attrs({
  styles: {
    option: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    input: base => ({
      ...base,
      fontSize: '0.8rem',
      margin: 0,
    }),
    placeholder: base => ({
      ...base,
      fontSize: '0.9rem',
    }),
    control: () => ({
      // none of react-select's styles are passed to <Control />
      width: '100%',
      border: `solid 1px var(--primary)`,
      display: 'flex',
      height: 30,
      borderRadius: 5,
      fontSize: '0.9rem',
    }),
    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = 'opacity 300ms';

      return { ...provided, opacity, transition };
    },
    dropdownIndicator: () => ({
      color: 'var(--primary)',
    }),
  },
})`
  width: 100%;
  margin-left: 3px;
`;

export const CheckBoxRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-bottom: 1rem;

  input {
    margin: 0 0.3rem;
  }
`;
