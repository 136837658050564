import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  padding: 0.5rem;

  overflow: auto;
`;

export const VAInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 12rem;

  padding: 1rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;

  background-color: var(--white);
`;

export const VASchedulesContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding: 1rem 0rem;

  border-radius: 0.5rem;

  background-color: var(--white);

  span {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  }

  overflow: auto;
`;

export const CardContentLabel = styled.text`
  font-size: 1rem;
  font-weight: bold;

  padding: 0rem 0.5rem;

  color: var(--dark);
`;

export const CardContentPollInfoRow = styled.div`
  display: flex;
  margin: 0.2rem 0rem 0.4rem;

  svg {
    margin-right: 0.4rem;
    color: var(--primary);
  }

  text {
    margin-right: 0.5rem;
  }
`;

export const AssemblyAuthor = styled.text`
  font-size: 1.3rem;
  font-weight: bold;

  color: var(--dark);
`;

export const CardContentText = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text);
  overflow-y: auto;
  padding: 0.5rem;

  strong {
    font-size: 1.3rem;
    margin: 0.5rem 0;
  }
`;

export const AssemblyScheduleItemButton = styled.button`
  position: relative;
  display: flex;

  width: 95%;
  min-height: 2.4rem;
  max-height: 2.4rem;

  align-items: center;
  justify-content: center;
  align-self: center;

  border: 0;
  border-radius: 1.2rem;

  margin: 0.5rem 0rem;

  background-color: var(--secondary);

  p {
    color: var(--white);
  }

  div {
    position: absolute;
    left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;

    align-items: center;
    justify-content: center;

    border-radius: 0.8rem;
    background-color: var(--primary50);

    align-self: left;

    p {
      margin-top: 0.1rem;
      font-weight: bold;
      font-size: 1rem;
    }
  }

  :hover {
    background-color: var(--primary);
  }
`;

export const AssemblyModalItemBlockLabel = styled.div`
  display: flex;
  width: 100%;
  height: 1.4rem;

  align-items: center;
  margin: 0.5rem 0rem;

  svg {
    color: var(--primary);
  }

  p {
    font-size: 1rem;
    font-weight: bold;

    margin-left: 0.5rem;
  }
`;

export const AssemblyModalItemBlockButton = styled.button`
  position: relative;
  display: flex;

  width: 95%;
  min-height: 2.4rem;
  max-height: 2.4rem;

  align-items: center;
  align-self: center;

  border: 0;
  border-radius: 1.2rem;

  margin: 0.5rem 0rem;
  padding: 1rem;

  background-color: var(--primary);

  svg {
    color: var(--white);
  }

  p {
    color: var(--white);
    margin-left: 0.5rem;
    font-weight: bold;
  }
`;
