import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import LoadingCondomob from '../../../components/LoadingCondomob';
import { Input } from '../../../components/Input';

import { useAuth } from '../../../contexts/AuthContext';
import api from '../../../services/api';

import { Container, FilterContainer, ListContainer, ListItem } from '../styles';

interface CityProps {
  nome: string | never;
  uf: {
    nome: string | never;
    sigla: string | never;
  };
}

const LoginUnitCity: React.FC = () => {
  const [cities, setCities] = useState<CityProps[]>([]);
  const [filteredCities, setFilteredCities] = useState<CityProps[]>([]);
  const [searchCity, setSearchCity] = useState('');
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { t } = useTranslation();
  const { adminLinkIntegrationId } = useAuth();

  const searchInputRef = useRef<HTMLInputElement>(null);

  const handleFilter = event => {
    const searchWord = event.target.value;
    setSearchCity(searchWord);
    let newFilter = [] as any;
    newFilter = cities.filter(value => {
      return value.nome.toLowerCase().includes(searchWord.toLowerCase());
    });

    if (searchWord === '') {
      setFilteredCities(cities);
    } else {
      setFilteredCities(newFilter);
    }
  };

  const getCities = async () => {
    return api
      .get(`/cidade/list`, {
        params: {
          administradora: adminLinkIntegrationId,
        },
      })
      .then(res => {
        setCities(res.data);
        setLoading(false);
        searchInputRef.current?.focus();
      })
      .catch(err => {
        setLoading(false);
        console.error('@GetCities', err);
      });
  };

  const handleNavigation = item => {
    history.push({
      pathname: '/listunitcondo',
      state: { item },
    });
  };

  useEffect(() => {
    getCities();
  }, []);

  useEffect(() => {
    setFilteredCities(cities);
  }, [cities]);

  return (
    <Container className="containerDefault">
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            <Input
              refInput={searchInputRef}
              name="termSearch"
              type="text"
              placeholder={t('loginUnitCity.FindItemInput.placeholder')}
              value={searchCity}
              onChange={handleFilter}
              style={{ borderColor: 'var(--primary)' }}
              icon="search"
            />
          </FilterContainer>

          <ListContainer style={{ maxHeight: '100vh' }}>
            {filteredCities.map((city, index) => {
              return (
                <ListItem key={index.toString()}>
                  <button type="button" onClick={() => handleNavigation(city)}>
                    <div>
                      <strong>{city.nome}</strong>
                      <p>
                        {city.uf.nome} - {city.uf.sigla}
                      </p>
                    </div>
                    <FontAwesomeIcon
                      icon={['far', 'chevron-right']}
                      size="sm"
                      color="#999"
                      style={{ marginLeft: 'auto' }}
                    />
                  </button>
                </ListItem>
              );
            })}
          </ListContainer>
        </>
      )}
    </Container>
  );
};

export default LoginUnitCity;
