import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import flagBrazil from '../../assets/flagBrazil.svg';
import flagFrance from '../../assets/flagFrance.svg';
import flagItaly from '../../assets/flagItaly.svg';
import flagNorway from '../../assets/flagNorway.svg';
import flagSpain from '../../assets/flagSpain.svg';
import flagUSA from '../../assets/flagUSA.svg';

import {
  Container,
  FlagImg,
  DialogBalloon,
  FlagButton,
  FlagButtonImg,
} from './styles';

const Language: React.FC = () => {
  const [flagsVisible, setFlagsVisible] = useState(false);

  const useOutsideAlerter = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (flagsVisible) {
            setFlagsVisible(!flagsVisible);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, flagsVisible]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  const lngs = {
    en: { nativeName: 'English', flag: flagUSA },
    es: { nativeName: 'Español', flag: flagSpain },
    fr: { nativeName: 'Français', flag: flagFrance },
    it: { nativeName: 'Italiano', flag: flagItaly },
    no: { nativeName: 'Norsk', flag: flagNorway },
    pt: { nativeName: 'Português (Brasil)', flag: flagBrazil },
  };
  const { i18n } = useTranslation();
  return (
    <Container ref={wrapperRef}>
      <FlagImg
        src={lngs[i18n.language.substring(0, 2)].flag}
        alt="CountrFlag"
        onClick={() => setFlagsVisible(!flagsVisible)}
      />
      <DialogBalloon hidden={!flagsVisible}>
        <div className="fadeView">
          {Object.keys(lngs).map(lng => (
            <FlagButton
              key={lng}
              style={{ fontWeight: i18n.language === lng ? 700 : 400 }}
              type="submit"
              onClick={() => i18n.changeLanguage(lng)}
            >
              <FlagButtonImg src={lngs[lng].flag} />
              {lngs[lng].nativeName}
            </FlagButton>
          ))}
        </div>
      </DialogBalloon>
    </Container>
  );
};

export default Language;
