import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { toast, ToastContainer } from 'react-toastify';
import qs from 'qs';

import Swal from 'sweetalert2';
import LoadingCondomob from '../../../../components/LoadingCondomob';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal';
import { useModal } from '../../../../hooks/useModal';

import { AppCommonContext } from '../../../../contexts/AppCommonContext';

import api from '../../../../services/api';

import {
  Container,
  CardContentLabel,
  CardContentPollInfoRow,
  CardContentText,
  AssemblyAuthor,
  AssemblyScheduleItemButton,
  AssemblyModalItemBlockButton,
  AssemblyModalItemBlockLabel,
  VAInfoContainer,
  VASchedulesContainer,
} from './styles';

type AssemblyBlockProps = {
  descricao: string;
  fileGcs: boolean;
  fileName?: string;
  html: string;
  id: number;
  idAviso: number;
  link: string;
  ordem: number;
  tipo: {
    codigo: string;
    id: number;
    nome: string;
  };
};

type AssemblyScheduleProps = {
  id: number;
  assembleia: string;
  titulo: string;
  ordem: number;
  blocos: Array<AssemblyBlockProps>;
};

type AssemblyDataProps = {
  categoria: string;
  dataAtualizacao: number;
  dataCriacao: number;
  dataFim: string;
  dataInicio: string;
  formato: string;
  grupos: Array<string>;
  id: number;
  intervalo: {
    descricao: string;
    intervalo: number;
  };
  pautas: Array<AssemblyScheduleProps>;
  reuniaoLink: string;
  status: {
    aberta: boolean;
    cor: string;
    descricao: string;
  };
  titulo: string;
  usuario: string;
};

const VAInfo: React.FC = ({ dataItem }: any) => {
  const [loading, setLoading] = useState(true);
  const [assemblyData, setAssemblyData] = useState<AssemblyDataProps>(
    {} as AssemblyDataProps,
  );
  const [modalContent, setModalContent] = useState<any>();
  const [modalItemTitle, setModalItemTitle] = useState('');
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const customParseFormat = require('dayjs/plugin/customParseFormat');

  const { assemblyUserPresence, setAssemblyUserPresence } =
    useContext(AppCommonContext);

  const {
    assemblyId,
    mensagemPresente,
    registraPresencaApp,
    unidadePresente,
    votantePresente,
  } = dataItem;
  const { t } = useTranslation();
  const { isShown, toggle } = useModal();
  dayjs.extend(customParseFormat);

  async function getAssemblyData() {
    const url = `assembleia/get`;
    const body = {
      params: {
        id: assemblyId,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setAssemblyData(res.data);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  async function handleUnitAssemblyPresence() {
    const url = `assembleia/presencaUnidade/save`;
    const body = {
      assembleia: assemblyId,
    };

    await api
      .post(url, qs.stringify(body))
      .then(() => {
        setAssemblyUserPresence(true);
        toast.success(`Presença registrada com sucesso!!`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function releaseAssemblyUnityPresence() {
    if (
      registraPresencaApp &&
      dayjs(assemblyData?.dataInicio, 'DD-MM-YYYY HH:mm').diff(Date.now()) <
        0 &&
      dayjs(assemblyData?.dataFim, 'DD-MM-YYYY HH:mm').diff(Date.now()) > 0 &&
      !assemblyUserPresence
    ) {
      Swal.fire({
        title: `Atenção!`,
        html: `<p>Você deseja registrar a sua presença na Assembleia?</p></br><p ${
          !mensagemPresente && `hidden`
        }>• Obrigatório para enviar mensagens.</p><p ${
          !votantePresente && `hidden`
        }>• Obrigatório para votar.</p>`,
        icon: `question`,
        showCancelButton: true,
        confirmButtonText: `${t('common.words.yes')}`,
        confirmButtonColor: `#3c5f8b`,
        cancelButtonText: `${t('common.words.no')}`,
        cancelButtonColor: '#6c757d',
        reverseButtons: true,
        backdrop: `rgba(0,0,0,0.9)`,
      }).then(result => {
        if (result.isConfirmed) {
          handleUnitAssemblyPresence();
        } else {
          return null;
        }

        return true;
      });
    }
  }

  function scheduleOrderSort(data: AssemblyDataProps) {
    data?.pautas.sort((a, b) => a.ordem - b.ordem);
  }

  function getModalContent(data: AssemblyScheduleProps): void {
    setModalContent([]);
    setModalItemTitle(data?.titulo);

    if (data?.blocos) {
      data?.blocos.map(bloco => {
        // Text Block
        if (bloco.tipo.id === 1) {
          setModalContent(prevState => [
            prevState,
            <div dangerouslySetInnerHTML={{ __html: bloco.html }} />,
          ]);
        }

        // Image Block
        if (bloco.tipo.id === 2) {
          setModalContent(prevState => [
            prevState,
            <img
              src={bloco.link}
              alt="imagem do item de pauta"
              style={{ width: '100%' }}
            />,
          ]);
        }

        // Attachment Block
        if (bloco.tipo.id === 3) {
          setModalContent(prevState => [
            prevState,
            <>
              <AssemblyModalItemBlockLabel>
                <FontAwesomeIcon icon={['fas', 'chain']} />
                <p>{bloco?.descricao}</p>
              </AssemblyModalItemBlockLabel>
              <AssemblyModalItemBlockButton
                onClick={() => {
                  window.open(bloco?.link, '_blank');
                }}
              >
                <FontAwesomeIcon icon={['fas', 'file']} />
                <p>{bloco?.fileName}</p>
              </AssemblyModalItemBlockButton>
            </>,
          ]);
        }

        // Youtube Video Block
        if (bloco.tipo.id === 4) {
          const regexTest = /([^/]+$)/;

          setModalContent(prevState => [
            prevState,
            <iframe
              width="690"
              height="388"
              src={`https://www.youtube.com/embed/${
                regexTest.exec(bloco.link)?.[0]
              }`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Embedded youtube"
            />,
          ]);
        }

        // External link Block
        if (bloco.tipo.id === 5) {
          setModalContent(prevState => [
            prevState,
            <>
              <AssemblyModalItemBlockLabel>
                <FontAwesomeIcon icon={['fas', 'chain']} />
                <p>{bloco?.descricao}</p>
              </AssemblyModalItemBlockLabel>
              <AssemblyModalItemBlockButton
                onClick={() => {
                  window.open(bloco?.link, '_blank');
                }}
              >
                <FontAwesomeIcon icon={['fas', 'arrow-up-right-from-square']} />
                <p>Abrir Link</p>
              </AssemblyModalItemBlockButton>
            </>,
          ]);
        }

        return true;
      });
    }
  }

  useEffect(() => {
    getAssemblyData();
  }, []);

  useEffect(() => {
    if (Object.keys(assemblyData).length > 0) {
      scheduleOrderSort(assemblyData);
      releaseAssemblyUnityPresence();

      setLoading(false);
    }
  }, [assemblyData]);

  return (
    <Container>
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <VAInfoContainer>
            <Modal
              headerText={modalItemTitle}
              isShown={isShown}
              hide={toggle}
              modalContent={modalContent}
            />

            <CardContentPollInfoRow style={{ flexDirection: 'column' }}>
              <AssemblyAuthor>{assemblyData.usuario}</AssemblyAuthor>
              <CardContentText style={{ padding: 0 }}>
                {dayjs(assemblyData.dataAtualizacao).format(
                  'DD/MM/YYYY HH:mm:ss',
                )}
              </CardContentText>
            </CardContentPollInfoRow>
            <CardContentPollInfoRow>
              <FontAwesomeIcon icon={['fas', 'comments']} />
              {assemblyData.titulo}
            </CardContentPollInfoRow>
            <CardContentPollInfoRow>
              <FontAwesomeIcon icon={['fas', 'exclamation-circle']} />
              <text>{assemblyData.categoria}</text>
              <FontAwesomeIcon
                icon={[
                  'fas',
                  assemblyData?.formato === 'Presencial'
                    ? 'person'
                    : assemblyData?.formato === 'Híbrida'
                    ? 'screen-users'
                    : 'video-arrow-up-right',
                ]}
              />

              <text>{assemblyData?.formato}</text>
            </CardContentPollInfoRow>
            <CardContentPollInfoRow>
              <FontAwesomeIcon icon={['fas', 'calendar-days']} />
              <strong>{assemblyData.dataInicio}</strong>&nbsp;a&nbsp;
              <strong>{assemblyData.dataFim}</strong>
            </CardContentPollInfoRow>
            {assemblyData.reuniaoLink && (
              <Button
                title="ENTRAR NA SALA VIRTUAL"
                icon="video-arrow-up-right"
                iconFamily="fas"
                onClick={() => {
                  window.open(assemblyData.reuniaoLink, '_blank');
                }}
              />
            )}
            {registraPresencaApp &&
              dayjs(assemblyData?.dataInicio, 'DD-MM-YYYY HH:mm').diff(
                Date.now(),
              ) < 0 &&
              dayjs(assemblyData?.dataFim, 'DD-MM-YYYY HH:mm').diff(
                Date.now(),
              ) > 0 &&
              !assemblyUserPresence && (
                <Button
                  icon="hand-point-up"
                  iconFamily="fas"
                  onClick={() => {
                    handleUnitAssemblyPresence();
                  }}
                  title="REGISTRAR PRESENÇA"
                />
              )}
          </VAInfoContainer>
          <VASchedulesContainer>
            <CardContentLabel>Pauta:</CardContentLabel>
            <span>
              {assemblyData?.pautas.map(pauta => {
                return (
                  <AssemblyScheduleItemButton
                    onClick={() => {
                      toggle();
                      getModalContent(pauta);
                    }}
                  >
                    <div>
                      <p>{pauta?.ordem}</p>
                    </div>
                    <p>{pauta?.titulo}</p>
                  </AssemblyScheduleItemButton>
                );
              })}
            </span>
          </VASchedulesContainer>
        </>
      )}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Container>
  );
};

export default VAInfo;
