import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import qs from 'qs';
import Swal from 'sweetalert2';

import { Button } from '../../../../components/Button';
import { Menu } from '../../../../components/Menu';
import LoadingCondomob from '../../../../components/LoadingCondomob';

import api from '../../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  ReservationInfo,
  GLRow,
  GLRowElement,
  GLLabel,
  GLInput,
  GLInputInfo,
} from './styles';

const CancelReservation: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(false);
  const [guestList, setGuestList] = useState('');

  const { id, nome, dataSolicitacao, convidados, capacidade } = location.state;

  const history = useHistory();
  const { t } = useTranslation();

  const updateGuestList = async (): Promise<void> => {
    const body = {
      id,
      convidados: guestList,
    };
    return api
      .post(`/reservaEspaco/saveConvidados`, qs.stringify(body))
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            icon: 'success',
            title: `${t('common.words.success')}`,
            text: `${t(
              'spaceReservation.myReservation.guestList.updatedList',
            )}`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
        }
      })
      .catch(err => {
        console.error('@updateGuestList', err.response.data.error);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: err.response.data.error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  useEffect(() => {
    if (convidados) {
      const guestListTemp = convidados.map(item => {
        return `${item}\n`;
      });
      setGuestList(guestListTemp.toString().trim());
    }
  }, []);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                /* history.goBack(); */
                history.replace({
                  pathname: 'SpaceReservation',
                  state: { ...location.state, activeTab: 1 },
                });
              }}
            >
              <FontAwesomeIcon
                icon={['far', 'angle-left']}
                size="lg"
                color="#3c5f8b"
              />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t(
              'spaceReservation.myReservation.guestList.title',
            )}`}</Title>
          </TitleContainer>
          <Content>
            <ReservationInfo>
              <p>
                <FontAwesomeIcon icon={['fas', 'calendar-day']} />{' '}
                {dayjs(dataSolicitacao).format('DD/MM/YYYY')}
              </p>
              <strong>{nome}</strong>
            </ReservationInfo>
            <GLRow>
              <GLRowElement>
                <GLLabel>{`${t(
                  'spaceReservation.myReservation.guestList.title',
                )}`}</GLLabel>
                <GLInput>
                  <textarea
                    rows={5}
                    placeholder={`${t(
                      'spaceReservation.myReservation.guestList.guestListPlaceholder',
                    )}`}
                    value={guestList}
                    onChange={e => {
                      setGuestList(e.target.value);
                    }}
                  />
                </GLInput>
                <GLInputInfo>
                  <ul>
                    <li>
                      <strong>
                        {`${t(
                          'spaceReservation.info.capacity',
                        )}: ${capacidade} ${t('spaceReservation.info.people')}`}
                      </strong>
                    </li>
                    <li>{`${t('spaceReservation.info.info1')}`}</li>
                    <li>{`${t('spaceReservation.info.info2')}`}</li>
                    <li>{`${t('spaceReservation.info.info3')}`}</li>
                    <li>{`${t('spaceReservation.info.info4')}`}</li>
                  </ul>
                </GLInputInfo>
                <Button
                  title={`${t('common.words.save')}`}
                  model="default"
                  width={15}
                  icon="check"
                  onClick={updateGuestList}
                />
              </GLRowElement>
            </GLRow>
          </Content>
        </Container>
      )}
    </>
  );
};

export default CancelReservation;
