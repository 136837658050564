import { CSSProperties, forwardRef, InputHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Container, InputText, IconRight } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  type: string;
  name: string;
  placeholder: string;
  value: string;
  style?: CSSProperties;
  icon?: string;
  model?: 'input' | 'search';
  handleSearchFunction?: () => void;
  refInput?: any;
}

export const Input: React.FC<InputProps> = ({
  type,
  name,
  placeholder,
  value,
  onChange,
  style,
  icon,
  model = 'input',
  handleSearchFunction,
  refInput,
}: InputProps) => {
  return model === 'input' ? (
    <Container>
      {icon !== undefined && (
        <FontAwesomeIcon icon={['fas', icon as IconName]} />
      )}
      <InputText
        ref={refInput}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
      />
    </Container>
  ) : (
    <Container>
      <InputText
        ref={refInput}
        type={type}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={style}
      />
      <IconRight onClick={handleSearchFunction}>
        <FontAwesomeIcon icon={['fas', icon as IconName]} />
      </IconRight>
    </Container>
  );
};
