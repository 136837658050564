import { ButtonHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { Container, ButtonTitle, Spinner } from './styles';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  btnColor?: string;
  btnTextColor?: string;
  icon?: string;
  iconFamily?: string;
  marginVertical?: number;
  model?: 'default' | 'new' | 'other';
  title: string;
  titleColor?: string;
  width?: number | any;
}

export function Button(props: ButtonProps): JSX.Element {
  const {
    btnColor,
    btnTextColor,
    disabled,
    icon,
    iconFamily,
    marginVertical = 10,
    model = 'default',
    onClick,
    title,
    titleColor,
    type,
    width,
  } = props;

  return (
    <Container
      btnColor={`${btnColor}`}
      btnTextColor={`${btnTextColor}`}
      disabled={disabled}
      marginVertical={marginVertical}
      model={model}
      onClick={onClick}
      type={type}
      width={width}
    >
      {!disabled ? (
        <>
          {icon !== undefined && (
            <FontAwesomeIcon
              icon={[(iconFamily as IconPrefix) || 'fas', icon as IconName]}
            />
          )}

          <ButtonTitle titleColor={titleColor}>{title}</ButtonTitle>
        </>
      ) : (
        <Spinner />
      )}
    </Container>
  );
}
