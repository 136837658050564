import {
  osVersion,
  osName,
  browserName,
  fullBrowserVersion,
} from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import animationData from '../../../assets/lottie/AboutAppAnim.json';

import packageJSON from '../../../../package.json';

import { Container } from './styles';

const AboutApp = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Lottie
        options={{
          loop: false,
          autoplay: true,
          animationData,
          rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
        }}
        style={{ width: '20rem' }}
      />

      <p>
        <strong>{t('aboutApp.version')}: </strong>
        {packageJSON.version}
      </p>
      <p>
        <strong>{t('aboutApp.operationalSystem')}: </strong>
        {`${osName} - ${osVersion}`}
      </p>
      <p>
        <strong>{t('aboutApp.browser')}: </strong>
        {`${browserName} - ${fullBrowserVersion}`}
      </p>
    </Container>
  );
};

export default <AboutApp />;
