import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  overflow-y: auto;
`;

export const EmptyList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: -webkit-fill-available;
  height: calc(100vh - 12.5rem);

  margin: 1rem;

  background-color: var(--white);

  border-radius: 0.4rem;

  img {
    height: 20vh;
    margin-bottom: 1rem;
  }

  p {
    font-weight: 700;
    text-align: center;
    color: var(--primary);
  }
`;

export const MessagesPool = styled.div`
  display: flex;
  width: 95%;
  flex-flow: row wrap;
  gap: 4rem;
  margin: 1rem 0;
`;

export const CardMessageContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 22rem;
  height: 75vh;
  overflow: hidden;
  background-color: var(--itemBackground);
`;

export const CardMessageHeader = styled.div<{ status: string }>`
  display: flex;
  width: -webkit-fill-available;
  align-items: center;
  min-height: 3rem;
  background-color: ${props =>
    // eslint-disable-next-line no-nested-ternary
    props.status === 'Confirmada'
      ? `var(--confirmed-background)`
      : props.status === 'Aguardando'
      ? `var(--waiting-background)`
      : `var(--notConfirmed-background)`};
  color: var(--white);
  font-weight: 700;

  svg {
    color: var(--white);
    margin: 0 1rem;
  }
`;

export const CardMessageHeaderText = styled.div`
  word-break: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-height: 16px; /* fallback */
  max-height: 32px; /* fallback */
  -webkit-line-clamp: 2; /* number of lines to show */
  -webkit-box-orient: vertical;
`;

export const CardMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  width: -webkit-fill-available;
  overflow-y: auto;
`;

export const CardMessageContentHeader = styled.div`
  display: flex;
  img {
    width: 20%;
    object-fit: cover;
    min-height: 2rem;
  }
  strong {
    font-size: 1rem;
  }
`;

export const CardMessageContentHeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
`;

export const CardMessageContentHeaderInfo = styled.div`
  margin-top: 0.5rem;
  svg {
    margin-right: 0.5rem;
  }
  strong {
  }
`;

export const CardMessageContentText = styled.div`
  display: flex;
  flex-direction: column;
  color: var(--text);
  padding: 0.2rem;
  p {
    & + p {
      margin-top: 0.5rem;
    }
  }

  svg {
    margin-right: 0.3rem;
  }
`;

export const CardMessageContentInfo = styled.div`
  width: -webkit-fill-available;
  border: solid 1px var(--borderMedium);
  font-size: 0.8rem;

  border-radius: 0.5rem;
  margin: 0.5rem 0 0 0;

  background-color: var(--white);
`;

export const CardMessageContentInfoHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.3rem;
  background-color: #17a2b8;
  color: var(--white);
  font-weight: 700;
  border-top-left-radius: 0.45rem;
  border-top-right-radius: 0.45rem;
`;

export const CardMessageInnerContent = styled.div`
  padding: 0rem 1rem;
`;

export const CardMessageFooter = styled.div`
  display: flex;
  flex-direction: column;
  height: 3rem;
  justify-content: center;
  width: -webkit-fill-available;
  background-color: var(--backgroundHeaderLight);
  border-top: solid 1px var(--inputBorder);
`;

export const CardMessageFooterInfoContainer = styled.div`
  display: flex;
  font-size: 0.8rem;
  margin: 0.3rem 0;
  svg {
    margin: 0 0.5rem;
  }
`;

export const CarMessageContentButtons = styled.div`
  display: flex;
  width: -webkit-fill-available;
  margin: 0.5rem 0;

  button {
    :first-child {
      margin-right: 0.5rem;
    }
  }
`;
