import styled from 'styled-components';

export const Container = styled.div`
  height: calc(100vh - 3.8rem);

  @media (max-width: 500px) {
    height: calc(100vh - 3.1rem);
  }
`;

export const CondoBkgImg = styled.div`
  position: relative;
  width: 100%;
  height: 10rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const CondoInfoContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  left: 1rem;
`;

export const CondoLogo = styled.img`
  width: 5rem;
  height: 5rem;
  object-fit: contain;
  background-color: var(--white);
  border-radius: 0.3rem;

  margin: 0 0.5rem 0.2rem 0;

  @media (max-width: 500px) {
    width: 4rem;
    height: 4rem;
  }
`;

export const CondoUserInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CondoUserInfo = styled.text`
  font-size: 1.3rem;
  font-weight: 700;
  color: var(--textLight);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

  @media (max-width: 500px) {
    font-size: 1rem;
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
  background-color: var(--titleBkgColor);
`;

export const BodyContainer = styled.div`
  overflow-y: auto;
`;
