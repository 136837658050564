import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  padding: 0.5rem;

  overflow: auto;
`;

export const LineRow = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
`;

export const VAScheduleItemListContainer = styled.div`
  width: 25%;
  height: 100%;

  padding: 0.5rem;

  border-radius: 0.5rem;

  margin-right: 0.5rem;

  overflow: auto;
  background-color: var(--white);
`;

export const VASCheduleItemListTitle = styled.text`
  font-size: 1rem;
  font-weight: bold;

  color: var(--dark);
`;

export const VAScheduleItemList = styled.div`
  margin-top: 1rem;
`;

export const VAScheduleItemListButton = styled.button<{
  itemSelected: boolean;
}>`
  display: flex;
  width: 100%;

  border: none;
  border-radius: 2rem;

  align-items: center;
  margin-bottom: 0.5rem;

  padding: 0.5rem;

  div {
    display: flex;
    width: 1.6rem;
    height: 1.6rem;

    align-items: center;
    justify-content: center;

    border-radius: 1rem;
    background-color: var(--secondary50);
    margin-right: 1rem;

    font-weight: bold;
    color: var(--primary);
  }

  p {
    font-size: 1rem;
    font-weight: bold;

    color: var(--white);
  }

  :hover {
    background-color: var(--primary);
  }

  background-color: ${props =>
    props.itemSelected ? 'var(--primary)' : 'var(--secondary)'};
`;

export const VAScheduleItemMessageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 0.5rem;

  overflow: auto;
`;

export const VASCheduleItemMessageList = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 0.5rem;
  margin-bottom: 0.5rem;

  overflow: auto;
`;

export const VAScheduleItemMessageElement = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  padding: 1rem;
  margin-bottom: 0.5rem;

  border-radius: 0.5rem;

  svg {
    margin: 0rem 0.5rem;
  }

  p {
    font-size: 0.8rem;
  }

  span {
    padding: 1rem;

    border: solid 1px var(--explosive);
    border-radius: 0.5rem;

    margin: 0.5rem 0rem;

    background-color: var(--uIGray);
  }

  h5 {
    color: var(--americanGray);
    font-weight: 400;
    font-size: 0.7rem;
  }

  background-color: var(--white);
`;

export const VASCheduleItemMessageSenderContainer = styled.div`
  display: flex;
  width: 100%;
  height: 4rem;

  padding: 0.5rem;

  align-items: center;

  border-radius: 0.5rem;

  background-color: var(--white);
`;

export const VAScheduleItemMessageSenderTextInput = styled.input`
  display: flex;
  flex: 1;
  height: 2rem;

  border: solid 1px;
  border-color: var(--americanGray);
  border-radius: 1rem;

  padding: 1rem;

  :focus {
    outline: none !important;
    border: 2px solid var(--primary);
  }
`;

export const VAScheduleItemMessageSenderButton = styled.button`
  display: flex;
  width: 2.1rem;
  height: 2.1rem;

  align-items: center;

  margin: 0rem 0.5rem;

  border: none;
  border-radius: 2rem;

  background-color: var(--primary);

  svg {
    color: var(--white);
    margin-left: 0.4rem;
  }
`;

export const VAMessagesNotificationContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 0.5rem;
  padding: 1rem;

  justify-content: center;
  align-items: center;

  span {
    font-size: 1.5rem;
    font-weight: bold;
    text-align: center;

    margin-bottom: 1rem;

    color: var(--americanGray);
  }

  background-color: var(--white);
`;
