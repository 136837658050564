/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../../../../../components/LoadingCondomob';
import { Button } from '../../../../../../components/Button';

import api from '../../../../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  FormRow,
  CardContainer,
  CardHeader,
  CardContent,
  SurveyVoteSecretContainer,
  CardContentText,
  CardFooter,
  LineRow,
} from './styles';

interface OpcoesProps {
  id: number;
  opcao: string;
  percentual: string;
  votos: number;
}

interface PoolInfoProps {
  id: number;
  opcoes: Array<OpcoesProps>;
  pergunta: string;
  minimoEscolhas: number;
  maximoEscolhas: number;
}

type AssemblySurveyDataChoiceProps = {
  id: number;
  opcao: string;
};

type AssemblySurveyDataProps = {
  id: number;
  maximoEscolhas: number;
  minimoEscolhas: number;
  opcoes: Array<AssemblySurveyDataChoiceProps>;
  pergunta: string;
  titulo: string;
  unidadevotou: boolean;
  votoAberto: boolean;
};

const VAVote: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(false);
  const [assemblySurveyData, setAssemblySurveyData] =
    useState<AssemblySurveyDataProps>({} as AssemblySurveyDataProps);
  const [surveyType, setSurveyType] = useState<'single' | 'multiple' | ''>('');
  const [surveyChoices, setSurveyChoices] = useState<any[]>([]);
  const [chosenOption, setChosenOption] = useState('');
  const [chosenOptionsBoxes, setChosenOptionsBoxes] = useState<any>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const { pollId } = location.state;

  async function getAssemblySurveyData(): Promise<void> {
    const url = '/enquete/get';
    const body = {
      params: {
        id: pollId,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setAssemblySurveyData(res.data);
        setSurveyType(
          res.data.maximoEscolhas === 1 && res.data.minimoEscolhas === 1
            ? 'single'
            : 'multiple',
        );
        setLoading(false);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function manageRadioButton(optionId: string): void {
    setSurveyChoices([optionId]);
  }

  function manageCheckBoxButton(optionId: string): void {
    if (surveyChoices.filter(choice => choice === optionId).length > 0) {
      setSurveyChoices(surveyChoices.filter(choice => choice !== optionId));
      return;
    }
    setSurveyChoices(prevState => [...prevState, optionId]);
  }

  async function enviarVoto() {
    const url = `/enquete/votar`;
    const params = qs.stringify(
      {
        id: pollId,
        opcoes: surveyChoices,
      },
      {
        indices: false, // qs option to omit array indices
      },
    );

    await api
      .post(url, params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(res => {
        if (res.status === 200) {
          toast.success(`Voto registrado com sucesso!!`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => history.goBack(),
          });
          setLoading(false);
        }
      })
      .catch(err => {
        setLoading(false);
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  function checkVoteOptions() {
    if (surveyChoices.length === 0) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: 'Você precisa selecionar pelo menos uma opção para poder efetuar o voto.',
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }

    if (surveyType === 'multiple') {
      if (surveyChoices.length < assemblySurveyData?.minimoEscolhas) {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `É necessário escolher pelo menos ${assemblySurveyData?.minimoEscolhas} opções para efetuar o voto.`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });

        return;
      }

      if (surveyChoices.length > assemblySurveyData?.maximoEscolhas) {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `Você só pode escolher até ${assemblySurveyData?.maximoEscolhas} opções para efetuar o voto.`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
        return;
      }
    }
    enviarVoto();
  }

  useEffect(() => {
    getAssemblySurveyData();
  }, []);

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('virtualAssemblies.voting.title')}</Title>
          </TitleContainer>
          <Content>
            {Object.keys(assemblySurveyData).length > 0 && (
              <CardContainer>
                <CardHeader>
                  <LineRow>
                    <FontAwesomeIcon icon={['fas', 'check-to-slot']} />
                    <p>
                      <strong>{assemblySurveyData?.titulo}</strong>
                    </p>
                  </LineRow>
                  {!assemblySurveyData?.votoAberto && (
                    <LineRow>
                      <FontAwesomeIcon icon={['fas', 'user-secret']} />
                      <p>Voto secreto</p>
                    </LineRow>
                  )}
                </CardHeader>
                <CardContent>
                  {!assemblySurveyData?.votoAberto && (
                    <SurveyVoteSecretContainer>
                      <strong>Voto Secreto</strong>
                      <text>Apenas você saberá em qual opção votou.</text>
                    </SurveyVoteSecretContainer>
                  )}
                  <CardContentText>
                    {surveyType === 'single'
                      ? `Escolha uma das opções abaixo`
                      : `Escolha entre ${assemblySurveyData.minimoEscolhas} a ${assemblySurveyData.maximoEscolhas} opções para votar.`}
                  </CardContentText>
                  {assemblySurveyData?.maximoEscolhas > 1
                    ? assemblySurveyData?.opcoes.map(option => {
                        return (
                          <FormRow key={option.id.toString()}>
                            <input
                              type="checkbox"
                              id={option.id.toString()}
                              name="poolOptions"
                              value={option.id}
                              onChange={e =>
                                manageCheckBoxButton(e.target.value)
                              }
                            />
                            <label htmlFor="opcao01">{option.opcao}</label>
                          </FormRow>
                        );
                      })
                    : assemblySurveyData?.opcoes.map(option => {
                        return (
                          <FormRow key={option.id.toString()}>
                            <input
                              type="radio"
                              id={option.id.toString()}
                              name="poolOptions"
                              value={option.id}
                              // onChange={e => setChosenOption(e.target.value)}
                              onChange={e => manageRadioButton(e.target.value)}
                            />
                            <label htmlFor="opcao01">{option.opcao}</label>
                          </FormRow>
                        );
                      })}
                  <Button
                    title={t('polls.voteInPoll.vote')}
                    model="default"
                    icon="check"
                    iconFamily="fas"
                    onClick={() => checkVoteOptions()}
                  />
                </CardContent>
                <CardFooter />
              </CardContainer>
            )}
          </Content>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </Container>
      )}
    </>
  );
};

export default VAVote;
