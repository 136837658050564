import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Container, TabItem, TabItemTitle } from './styles';

interface TabElementProps {
  tabTitle: string;
  tabIcon: IconName;
}

interface TabContentProps {
  dataItem: any;
}

interface TabDataItemProps {
  id: number;
}

interface TabsProps {
  tabElement: Array<TabElementProps>;
  // tabContentItem: Array<React.FC>;
  tabContentItem: Array<React.FC<TabContentProps>>;
  tabDataItem?: TabDataItemProps;
  tabActive?: number;
}

const Tabs = ({
  tabElement,
  tabContentItem,
  tabDataItem,
  tabActive,
}: TabsProps) => {
  const [activeTab, setActiveTab] = useState<number | undefined>(0);

  useEffect(() => {
    if (tabActive !== 0) {
      setActiveTab(tabActive);
    }
  }, []);

  return (
    <>
      <Container>
        {tabElement.map((item, index) => {
          return (
            <TabItem
              key={index.toString()}
              onClick={() => setActiveTab(index)}
              activated={activeTab === index}
            >
              <FontAwesomeIcon
                icon={['fas', item.tabIcon as IconName]}
                color={`${
                  activeTab === index ? 'var(--primary)' : 'var(--textDark)'
                }`}
              />
              <TabItemTitle>{item.tabTitle}</TabItemTitle>
            </TabItem>
          );
        })}
      </Container>
      {tabContentItem.map((Item, index) => {
        if (activeTab === index) return <Item dataItem={tabDataItem} />;
        return null;
      })}
    </>
  );
};

export default Tabs;
