import { AxiosRequestConfig } from 'axios';

export default async (config: AxiosRequestConfig) => {
  let token;

  const localToken = await localStorage.getItem('@CondomobWeb:tokenCondomob');

  if (localToken) {
    const localObject = JSON.parse(localToken);
    token = localObject.token;
  }

  if (
    token &&
    config.url !== '/acesso/social/list' &&
    config.url !== '/acesso/social/login'
  ) {
    config.headers.Authorization = `${token}`;
    if (config.method === 'post') {
      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=UTF-8';
    }
  }
  return config;
};
