import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../../contexts/AuthContext';

import { Modal } from '../Modal';
import AboutApp from './AboutApp';

import packageJson from '../../../package.json';

import { Container, ButtonMenu, DialogBalloon, VersionApp } from './styles';
import StorageService from '../../services/storage';

const AppMenu: React.FC = (): any => {
  const history = useHistory();

  const [dialogVisible, setDialogVisible] = useState(false);

  const { t } = useTranslation();

  useState(false);
  const [modalAboutAppVisible, setModalAboutAppVisible] = useState(false);

  const { logout, userCondomob } = useAuth();

  const handleLogout = async () => {
    await logout().then(() => {
      history.push('/');
    });
  };

  const handleLogoutUnit = async () => {
    return Swal.fire({
      text: `Deseja realmente sair da unidade?`,
      icon: `question`,
      showCancelButton: true,
      confirmButtonText: `${t('common.words.yes')}`,
      confirmButtonColor: `#3c5f8b`,
      cancelButtonText: `${t('common.words.no')}`,
      cancelButtonColor: '#6c757d',
      reverseButtons: true,
      backdrop: `rgba(0,0,0,0.9)`,
    }).then(result => {
      if (result.isConfirmed) {
        StorageService.remove('@CondomobWeb:userCondomob');
        history.push('/listunits');
      }
    });
  };

  const useOutsideAlerter = ref => {
    useEffect(() => {
      const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
          if (dialogVisible) {
            setDialogVisible(!dialogVisible);
          }
        }
      };
      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, dialogVisible]);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <Container ref={wrapperRef}>
      <Modal
        headerText={`${t('components.appmenu.aboutAppTitle')}`}
        isShown={modalAboutAppVisible}
        hide={() => {
          setModalAboutAppVisible(!modalAboutAppVisible);
        }}
        modalContent={AboutApp}
      />
      <ButtonMenu
        onClick={() => {
          setDialogVisible(!dialogVisible);
        }}
      >
        <FontAwesomeIcon icon={['fas', 'bars']} />
      </ButtonMenu>
      <DialogBalloon hidden={!dialogVisible}>
        <div className="fadeView">
          <button
            type="button"
            onClick={() => {
              window.open('https://condomob.net/termos-de-uso/', '_blank');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'file-alt']} />
            <text>{`${t('components.appmenu.itemUseTerms')}`}</text>
          </button>
          <button
            type="button"
            onClick={() => {
              window.open(
                'https://condomob.net/protecao-de-dados-pessoais/',
                '_blank',
              );
            }}
          >
            <FontAwesomeIcon icon={['fal', 'shield-check']} />
            <text>{`${t('components.appmenu.itemPrivacyPolicy')}`}</text>
          </button>
          <button
            type="button"
            onClick={() => {
              (window as any).sendAdoptCommand('open');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'cookie-bite']} />
            <text>{`${t('components.appmenu.itemManageCookies')}`}</text>
          </button>
          <button
            type="button"
            onClick={() => {
              window.open('https://condomob.net/sobre-nos/', '_blank');
            }}
          >
            <FontAwesomeIcon icon={['fal', 'info-circle']} />
            <text>{`${t('components.appmenu.itemAboutCondomob')}`}</text>
          </button>
          <button
            type="button"
            onClick={() => {
              setModalAboutAppVisible(!modalAboutAppVisible);
            }}
          >
            <FontAwesomeIcon icon={['fal', 'star']} />
            <text>{`${t('components.appmenu.itemAboutApp')}`}</text>
          </button>

          <button
            type="button"
            onClick={handleLogoutUnit}
            style={
              Object.keys(userCondomob).length > 0 &&
              history.location.pathname !== '/listunits'
                ? {}
                : { display: 'none' }
            }
          >
            <FontAwesomeIcon icon={['fal', 'sign-out']} />
            <text>Sair da unidade</text>
          </button>
          <button
            type="button"
            onClick={handleLogout}
            style={
              Object.keys(userCondomob).length > 0 ? {} : { display: 'none' }
            }
          >
            <FontAwesomeIcon icon={['fas', 'person-to-door']} />
            <text>{`${t('components.appmenu.itemLogout')}`}</text>
          </button>
          <VersionApp>{`App version: ${packageJson.version}`}</VersionApp>
        </div>
      </DialogBalloon>
    </Container>
  );
};

export default AppMenu;
