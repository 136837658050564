import styled from 'styled-components';

export const Container = styled.div``;

export const LineRow = styled.div`
  display: flex;
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;

  @media (max-width: 500px) {
    font-size: 1.2rem;
  }
`;

// *** Specific of the page ***
export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: auto;

  padding: 0.5rem 1rem;
`;

export const ListItem = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0.6rem 1rem;
  align-items: center;

  margin-bottom: 10px;

  border: none;
  border-radius: 10px;
  background-color: var(--white);

  :hover {
    background-color: var(--buttonHover);
  }

  svg {
    :last-child {
      margin-left: auto;
    }
  }
`;

export const ListItemContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  text-align: left;

  strong {
    font-size: 1.1rem;
    color: var(--dark);
  }

  div {
    display: flex;
    margin: 0.3rem 0;

    svg {
      margin-right: 0.3rem;
      color: var(--primary);
    }
  }
`;

export const ListItemAssemblyProps = styled.div`
  display: flex;
  flex: 1;

  p {
    margin-right: 0.3rem;
  }
`;

export const ListItemStatus = styled.div<{ statusCor: string }>`
  align-self: flex-end;
  padding: 5px 20px;
  align-items: center;
  justify-content: center;

  font-weight: 600;
  color: var(--white);
  border-radius: 20px;

  background-color: ${props => `var(--${props.statusCor})`};
`;
