import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import LoadingCondomob from '../../../../components/LoadingCondomob';

import vaSurveyListEmptyImgDefault from '../../../../assets/vaNoSurveyImgDefault.png';

import api from '../../../../services/api';

import {
  Container,
  VAVoteItemListContainer,
  VAVoteItemListTitle,
  VAVoteItemList,
  VAVoteItemListButton,
  VAVoteItemMessageContainer,
  VAVoteListItem,
  VAVoteListItemContent,
  VAVoteListItemStatus,
} from './styles';

interface PollsListProps {
  dataFim: string;
  dataInicio: string;
  id: number;
  status: {
    descricao: string;
    encerrada: boolean;
  };
  titulo: string;
  votoAberto: boolean;
}

type ScheduleListProps = {
  assembleia: number;
  id: number;
  ordem: number;
  titulo: string;
};

type AssemblySurveyItemProps = {
  assembleia: number;
  assembleiaPauta: number;
  dataFim: string;
  dataInicio: string;
  id: number;
  status: {
    descricao: string;
    cor: string;
    encerrada: boolean;
  };
  titulo: string;
  votoAberto: boolean;
};

const VAVote: React.FC = ({ dataItem }: any) => {
  const [loading, setLoading] = useState(true);
  const [scheduleList, setScheduleList] = useState<ScheduleListProps[]>([]);
  const [scheduleSelected, setScheduleSelected] = useState<number>(0);
  const [surveyList, setSurveyList] = useState<AssemblySurveyItemProps[]>([]);

  const {
    assemblyEndTime,
    assemblyId,
    assemblyStartTime,
    mensagemPresente,
    registraPresencaApp,
    unidadePresente,
    votantePresente,
  } = dataItem;

  const history = useHistory();
  const { t } = useTranslation();

  async function getScheduleList() {
    const url = `assembleia/pautas/list`;
    const body = {
      params: {
        id: assemblyId,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setScheduleList(res.data.sort((a, b) => a.ordem - b.ordem));
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  async function handleSelectSchedule(scheduleId = 0): Promise<void> {
    setLoading(true);
    if (scheduleList.length > 0) {
      if (scheduleSelected === 0) {
        setScheduleSelected(scheduleList[0]?.id);
      } else {
        setScheduleSelected(scheduleId);
      }
    }
  }
  async function getAssemblySurveyList(): Promise<void> {
    const url = `/enquete/list/assembleiaPauta`;
    const body = {
      params: {
        idAssembleia: assemblyId,
        idAssembleiaPauta: scheduleSelected,
      },
    };

    await api
      .get(url, body)
      .then(res => {
        setSurveyList(res.data);
        setLoading(false);
      })
      .catch(err => {
        const error = err.data.error
          ? err.data.error
          : `Ops! Algo não saiu como esperado!`;
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: error,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
    setLoading(false);
  }

  useEffect(() => {
    getScheduleList();
  }, []);

  useEffect(() => {
    if (scheduleList.length > 0) {
      handleSelectSchedule();
    }
  }, [scheduleList]);

  useEffect(() => {
    if (scheduleSelected !== 0) {
      getAssemblySurveyList();
    }
  }, [scheduleSelected, getAssemblySurveyList]);

  return loading ? (
    <LoadingCondomob />
  ) : (
    <Container>
      <VAVoteItemListContainer>
        <VAVoteItemListTitle>Pauta:</VAVoteItemListTitle>
        <VAVoteItemList>
          {scheduleList.map(el => {
            return (
              <VAVoteItemListButton
                onClick={() => {
                  handleSelectSchedule(el?.id);
                }}
                itemSelected={el?.id === scheduleSelected}
              >
                <div>{el?.ordem}</div>
                <p>{el?.titulo}</p>
              </VAVoteItemListButton>
            );
          })}
        </VAVoteItemList>
      </VAVoteItemListContainer>
      <VAVoteItemMessageContainer>
        {surveyList.length === 0 ? (
          <img
            src={vaSurveyListEmptyImgDefault}
            alt="Lista vazia"
            width={200}
            style={{ alignSelf: 'center', marginTop: '5rem' }}
          />
        ) : (
          surveyList.map(pollItem => {
            return (
              <VAVoteListItem
                key={pollItem.id.toString()}
                onClick={() =>
                  history.push({
                    pathname: '/VAVoteInfo',
                    state: {
                      pollId: pollItem.id,
                      assemblyId,
                      assemblyEndTime,
                      assemblyStartTime,
                      mensagemPresente,
                      registraPresencaApp,
                      unidadePresente,
                      votantePresente,
                    },
                  })
                }
              >
                <VAVoteListItemContent>
                  <div>
                    <strong>{pollItem.titulo}</strong>
                  </div>
                  <div>
                    <FontAwesomeIcon icon={['fas', 'calendar-days']} />
                    <p>
                      {pollItem.dataInicio} - {pollItem.dataFim}
                    </p>
                  </div>
                  {!pollItem.votoAberto && (
                    <div>
                      <FontAwesomeIcon icon={['fas', 'user-secret']} />
                      <p>Voto Secreto</p>
                    </div>
                  )}
                  <VAVoteListItemStatus encerrada={pollItem.status.encerrada}>
                    <span>{pollItem.status.descricao}</span>
                  </VAVoteListItemStatus>
                </VAVoteListItemContent>
                <FontAwesomeIcon icon={['fas', 'chevron-right']} />
              </VAVoteListItem>
            );
          })
        )}
      </VAVoteItemMessageContainer>
    </Container>
  );
};

export default VAVote;
