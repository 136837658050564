import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  flex-direction: column;
  width: 4rem;

  justify-content: center;
  align-items: center;

  border-width: 0px;
  background-color: transparent;
`;

interface ContentProps {
  active: boolean;
}

export const Content = styled.div`
  display: flex;

  justify-content: center;
  align-items: center;

  width: 4rem;
  height: 4rem;

  border-radius: 50%;

  background-color: var(--primary);

  transition: filter 0.2s;

  svg {
    color: var(--white);
    font-size: 2rem;
  }

  &:hover {
    filter: brightness(0.9);
  }
`;

export const Title = styled.p`
  display: flex;
  width: 100%;
  height: 2rem;
  margin-top: 0.375rem;
  justify-content: center;

  color: var(--secundary);
  text-align: center;
  line-height: auto;

  font-size: 0.8rem;
  font-weight: ${(props: ContentProps) => {
    if (props.active) {
      return 700;
    }
    return 400;
  }};
`;
