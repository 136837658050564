import React from 'react';

import { useTranslation } from 'react-i18next';

import imgEmptyList from '../../assets/emptyData.svg';

import { Container } from './styles';

const EmptyList: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <img src={imgEmptyList} alt="Lista vazia" />
      <p>{`${t('common.phrases.emptyListResult')}`}</p>
    </Container>
  );
};

export default EmptyList;
