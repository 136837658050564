/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import qs from 'qs';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../../components/LoadingCondomob';
import { Button } from '../../../components/Button';

import api from '../../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  PoolQuestion,
  FormRow,
  CardContainer,
  CardHeader,
  CardContent,
  CardContentText,
  CardFooter,
} from './styles';

interface OpcoesProps {
  id: number;
  opcao: string;
  percentual: string;
}

interface PoolInfoProps {
  id: number;
  opcoes: Array<OpcoesProps>;
  pergunta: string;
  minimo: number;
  maximo: number;
}

const VoteInThePool: React.FC = ({ location }: any) => {
  const [loading, setLoading] = useState(false);
  const [chosenOption, setChosenOption] = useState('');
  const [chosenOptionsBoxes, setChosenOptionsBoxes] = useState<any>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const item = location.state;

  const { id, opcoes, pergunta, minimo, maximo }: PoolInfoProps = item;

  const poolVoting = async () => {
    let url;
    let body;

    if (minimo > 1 || maximo > 1) {
      body = {
        id,
      };
      url = '/enquete/votar?';
      chosenOptionsBoxes.forEach(item => {
        url += `&opcoes=${item}`;
      });
    } else {
      url = '/enquete/votar';
      body = {
        id,
        opcoes: chosenOption,
      };
    }
    // let url = '/enquete/votar?';
    // chosenOptionsBoxes.forEach(item => {
    //   url += `&opcoes=${item}`;
    // });
    return api
      .post(`${url}`, qs.stringify(body), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(res => {
        if (res.status === 200) {
          Swal.fire({
            title: `${t('common.words.success')}`,
            text: `${t('polls.voteInPoll.voteSuccess')}`,
            icon: `success`,
            showConfirmButton: true,
            confirmButtonText: 'Ok',
            confirmButtonColor: `#3c5f8b`,
            backdrop: `rgba(0,0,0,0.9)`,
          }).then(result => {
            if (result.isConfirmed) {
              history.goBack();
            }
            return null;
          });
        }
      })
      .catch(err => {
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${err.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  };

  const getUniqueValues = arr => {
    const unique: any = Array.from(new Set([...arr]));
  };

  return (
    <>
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('polls.voteInPoll.title')}</Title>
          </TitleContainer>
          <Content>
            <CardContainer>
              <CardHeader />
              <CardContent>
                <PoolQuestion dangerouslySetInnerHTML={{ __html: pergunta }} />
                {minimo > 1 || maximo > 1
                  ? opcoes.map(option => {
                      return (
                        <FormRow key={option.id.toString()}>
                          <input
                            type="checkbox"
                            id={option.id.toString()}
                            name="poolOptions"
                            value={option.id}
                            onChange={e => {
                              if (e.target.checked) {
                                setChosenOptionsBoxes(oldState => [
                                  ...oldState,
                                  e.target.value,
                                ]);
                              }

                              if (!e.target.checked) {
                                const chosenBoxesFiltered =
                                  chosenOptionsBoxes.filter(value => {
                                    return value !== e.target.value;
                                  });
                                setChosenOptionsBoxes(chosenBoxesFiltered);
                              }
                            }}
                          />
                          <label htmlFor="opcao01">{option.opcao}</label>
                        </FormRow>
                      );
                    })
                  : opcoes.map(option => {
                      return (
                        <FormRow key={option.id.toString()}>
                          <input
                            type="radio"
                            id={option.id.toString()}
                            name="poolOptions"
                            value={option.id}
                            onChange={e => setChosenOption(e.target.value)}
                          />
                          <label htmlFor="opcao01">{option.opcao}</label>
                        </FormRow>
                      );
                    })}
                <Button
                  title={t('polls.voteInPoll.vote')}
                  width={15}
                  icon="check"
                  onClick={() => {
                    Swal.fire({
                      title: `${t('polls.voteInPoll.voteConfirmQuestion')}`,
                      text: `${t('polls.voteInPoll.voteCantChangeInfo')}`,
                      icon: `question`,
                      showConfirmButton: true,
                      confirmButtonText: `${t('common.words.yes')}`,
                      confirmButtonColor: `#3c5f8b`,
                      showCancelButton: true,
                      cancelButtonText: `${t('common.words.no')}`,
                      reverseButtons: true,
                      backdrop: `rgba(0,0,0,0.9)`,
                    }).then(result => {
                      if (result.isConfirmed) {
                        poolVoting();
                      }
                      return null;
                    });
                  }}
                />
              </CardContent>
              <CardFooter />
            </CardContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default VoteInThePool;
