import styled from 'styled-components';

export const InvisibleItemContainer = styled.div<{ width: number }>`
  display: flex;
  flex: 1;

  height: 1rem;

  min-width: ${props => props.width}%;
`;
