import styled from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  color: var(--text-body);
`;

export const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--titleBkgColor);
`;

export const TitleBackButton = styled.button`
  position: absolute;
  display: flex;
  border: none;
  width: 3rem;
  height: 100%;

  justify-content: center;
  align-items: center;
  padding: 0.2rem;
  left: 1rem;

  font-size: 0.7rem;
  background-color: transparent;
  color: var(--secundary);

  svg {
    margin-right: 0.3rem;
    font-size: 1rem;
    color: var(--primary);
  }
`;

export const Title = styled.text`
  width: 100%;
  text-align: center;
  align-self: center;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--titleColor);
  padding: 5px 0;
`;

// *** Specific of the page ***

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  height: 100%;
  overflow-y: auto;
`;

export const RDImageContainer = styled.div`
  display: flex;
`;

export const RDImage = styled.div<{ bkgImg: string }>`
  position: relative;
  display: flex;
  width: 100%;
  height: 35rem;
  align-self: center;
  background-image: url(${props => props.bkgImg});
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  background-color: bisque;
  background-position: center;
`;

export const RDImageTitle = styled.text`
  width: -webkit-fill-available;
  font-weight: 700;
  font-size: 1.5rem;
  color: var(--textLight);
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const RDImageFooterInfo = styled.div`
  display: flex;
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;

  font-size: 1.2rem;
  font-weight: 700;
  color: var(--textLight);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
`;

export const RDImageFooterValue = styled.div`
  display: flex;
  width: 50%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;

    svg {
      filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));
    }
  }
`;

export const RDImageFooterTotalReserved = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
  }
`;

export const RDInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: -webkit-fill-available;
  padding: 1rem;
`;

export const RDInfoText = styled.text`
  margin-bottom: 1rem;
  .redInfo {
    color: #ff0000;
  }

  h1 {
    font-size: 1.3rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }

  p {
    strong {
      font-size: 1rem;
    }

    & + p {
      margin-top: 1rem;
    }
  }
`;
