import React, { useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LoadingCondomob from '../../components/LoadingCondomob';
import LoadMoreAnimation from '../../components/LoadMore';
import EmptyList from '../../components/EmptyList';

import api from '../../services/api';

import {
  Container,
  TitleContainer,
  TitleBackButton,
  Title,
  Content,
  ListContainer,
  ListItem,
  ListItemContent,
  ListItemVoted,
  BorderBottom,
} from './styles';

interface PoolListProps {
  dataFim: string;
  dataInicio: string;
  id: number;
  status: {
    descricao: string;
    encerrada: boolean;
    cor: string;
  };
  titulo: string;
  unidadeVotou: boolean;
}

const Pools: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [poolList, setPoolList] = useState<PoolListProps[]>([]);
  const [listCursor, setListCursor] = useState('');
  const [hasMoreItems, setHasMoreItems] = useState(false);
  const [refreshing, setRefreshing] = useState(false);

  const history = useHistory();
  const { t } = useTranslation();

  const voted = true;

  const getPoolList = async (): Promise<void> => {
    setRefreshing(true);
    return api
      .get(`/enquete/list`, {
        params: {
          cursor: listCursor,
        },
      })
      .then(res => {
        setListCursor(res.data.cursor);
        setHasMoreItems(res.data.hasMore);
        // eslint-disable-next-line no-shadow
        setPoolList(poolList => [...poolList, ...res.data.list]);
        setLoading(false);
        setRefreshing(false);
      })
      .catch(err => {
        console.error('@getPoolList', err.response.data.error);
        setLoading(false);
        setRefreshing(false);
      });
  };

  const listenToScroll = (e: any) => {
    if (
      e.target.scrollHeight - e.target.scrollTop <=
      e.target.clientHeight * 1.15
    ) {
      if (!refreshing && hasMoreItems) {
        getPoolList();
      }
    }
  };

  useEffect(() => {
    setRefreshing(true);
    getPoolList();
  }, []);

  return (
    <>
      {/* <Menu /> */}
      {loading ? (
        <Container className="containerDefault">
          <LoadingCondomob />
        </Container>
      ) : (
        <Container className="containerDefault">
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {t('common.words.back')}
            </TitleBackButton>
            <Title>{t('polls.title')}</Title>
          </TitleContainer>
          <Content onScroll={listenToScroll}>
            <ListContainer>
              {poolList.length === 0 ? (
                <EmptyList />
              ) : (
                poolList.map(item => {
                  return (
                    <ListItem
                      key={item.id.toString()}
                      onClick={() =>
                        history.push({
                          pathname: '/descriptionPoll',
                          state: { id: item.id },
                        })
                      }
                    >
                      <ListItemContent color={item.status.cor}>
                        <strong>{item.titulo}</strong>
                        <div>
                          <FontAwesomeIcon icon={['fas', 'calendar']} />
                          <p>
                            {item.dataInicio.substring(0, 11)} a{' '}
                            {item.dataFim.substring(0, 11)} -{' '}
                            <span>{item.status.descricao}</span>
                          </p>
                        </div>
                        {voted && (
                          <ListItemVoted>
                            {item.unidadeVotou && (
                              <>
                                <FontAwesomeIcon icon={['fas', 'check']} />
                                <strong>Você já votou!!!</strong>
                              </>
                            )}
                          </ListItemVoted>
                        )}
                      </ListItemContent>
                      <BorderBottom />
                      <FontAwesomeIcon icon={['fas', 'chevron-right']} />
                    </ListItem>
                  );
                })
              )}
              {hasMoreItems && poolList.length > 8 && <LoadMoreAnimation />}
            </ListContainer>
          </Content>
        </Container>
      )}
    </>
  );
};

export default Pools;
