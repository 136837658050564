import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin: 0.5rem;
  padding: 0.5rem;
  /* background-color: var(--white);
  border-radius: 0.3rem;
  border: solid 1px var(--borderExtraLight); */
`;

export const MenuContainer = styled.div`
  width: 100%;
`;

export const ResponsiveMenuContent = styled.div`
  display: flex;
  gap: 4rem;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 1rem;
`;
