// Reference article
// https://nainacodes.com/blog/create-an-accessible-and-reusable-react-modal
import React from 'react';
import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

import {
  Backdrop,
  Container,
  CloseButton,
  StyledModal,
  Header,
  HeaderText,
  Content,
  ShareButtonContainer,
  ConfirmationButtons,
  Message,
  NoButton,
  YesButton,
} from './styles';

interface ModalProps {
  isShown: boolean;
  hide: () => void;
  modalContent: JSX.Element | string | any;
  headerText: string;
  whatsApp?: boolean;
  shareContent?: string;
}

interface ConfirmationModalProps {
  onConfirm: () => void;
  onCancel: () => void;
  message: string;
}

export const Modal: React.FC<ModalProps> = ({
  headerText,
  hide,
  isShown,
  modalContent,
  whatsApp,
  shareContent,
}) => {
  const { t } = useTranslation();

  const shareWhatsApp = (textMessage: any) => {
    window.open(`https://api.whatsapp.com/send?text=${textMessage}`, '_blank');
  };

  const modal = (
    <>
      <Backdrop />
      <Container>
        <StyledModal>
          <Header>
            <HeaderText>{headerText}</HeaderText>
            <CloseButton onClick={hide}>
              <FontAwesomeIcon
                icon={['fas', 'times-circle']}
                size="lg"
                color="#fff"
              />
            </CloseButton>
          </Header>
          {whatsApp ? (
            <Content>
              <textarea disabled value={modalContent} rows={23} />
              <ShareButtonContainer>
                <Button
                  title={`${t('common.words.close')}`}
                  width={20}
                  model="new"
                  icon="times"
                  onClick={hide}
                />
                <Button
                  title={`${t('common.words.share')}`}
                  width={20}
                  model="new"
                  icon="whatsapp"
                  iconFamily="fab"
                  onClick={() => {
                    shareWhatsApp(shareContent);
                  }}
                />
              </ShareButtonContainer>
            </Content>
          ) : (
            <Content>{modalContent}</Content>
          )}
        </StyledModal>
      </Container>
    </>
  );
  return isShown ? ReactDOM.createPortal(modal, document.body) : null;
};

export const ConfirmationModal: React.FC<ConfirmationModalProps> = props => {
  const { message, onCancel, onConfirm } = props;
  return (
    <>
      <Message>{message}</Message>
      <ConfirmationButtons>
        <YesButton onClick={onConfirm}>Yes</YesButton>
        <NoButton onClick={onCancel}>No</NoButton>
      </ConfirmationButtons>
    </>
  );
};
