import React, { FormEvent, useEffect, useState } from 'react';

import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { toast, ToastContainer } from 'react-toastify';
import firebase from 'firebase/compat/app';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';

import { auth } from '../../firebase';

import { useAuth } from '../../contexts/AuthContext';

import api from '../../services/api';

import { Input } from '../../components/Input';
import { Button } from '../../components/Button';
import ButtonLink from '../../components/ButtonLink';
import LoadingCondomob from '../../components/LoadingCondomob';

import bkgLoginImg from '../../assets/bg-desk-login.jpg';

import {
  Container,
  BackGroundImg,
  Card,
  Form,
  InputContainer,
  ButtonContainer,
  RegisterContainer,
  SocialButtonsContainer,
  SocialButton,
  AdminPlateContainer,
  AdminPlateLogo,
  AdminPlateInfoContainer,
  AdminPlateInfoName,
  AdminPlateInfoButton,
  ContentContainer,
  ContentData,
  AdminLogoContainer,
  AdminLogoContent,
  AdminDataContainer,
  AdminName,
  AdminAddress,
  AdminContactContainer,
  AdminContactItem,
  AdminContactData,
  AdminCondoInfoUnitsContent,
} from './styles';
import * as SC from './styles';

import { CloseButton } from '../../components/Modal/styles';
import { getAdm, storeAdm } from '../../services/adm';
import InstallAppModal from './InstallAppModal';
import StorageService from '../../services/storage';

const modalOverlayStyle = {
  content: { width: '50%', margin: '0 auto', padding: 10 },
};

const useQuery = () => new URLSearchParams(useLocation().search);

interface AdminDataProps {
  imgLogin: string;
  imgLink: string;
  nome: string;
  site?: string;
}

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [loadingCondomob, setLoadingCondomob] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [userEmail, setUserEmail] = useState('');
  const [userPassword, setUserPassword] = useState('');
  const [fireBaseAuthJWT, setFireBaseAuthJWT] = useState('');
  const [emailVerified, setEmailVerified] = useState(false);
  const [googleCredentials, setGoogleCredentials] = useState<any>({});
  const [facebookCredentials, setFacebookCredentials] = useState<any>({});
  const [adminData, setAdminData] = useState<AdminDataProps | any>('');

  const history = useHistory();
  const { t } = useTranslation();
  const {
    setUserUnits,
    userUnits,
    userCredentials,
    setAdminLinkIntegrationId,
    adminLinkIntegrationId,
  } = useAuth();
  const query = useQuery();

  const userLogin = async (email: string, password: string) => {
    return auth.signInWithEmailAndPassword(email, password);
  };

  const sendEmailVerification = async user => {
    user
      .sendEmailVerification()
      .then(() => {
        toast.success(`${t('login.loginValidationLink')}`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          onClose: () => history.push('/'),
        });
      })
      .catch(error => {
        if (error.code === 'auth/too-many-requests') {
          toast.error(`${t('login.loginValidationManyRequest')}`, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      });
  };

  const getFormData = (event: FormEvent) => {
    event.preventDefault();
  };

  const handleLogin = async (event: FormEvent): Promise<void> => {
    event.preventDefault();

    if (userEmail === '' || userPassword === '') {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${t('login.loginRequiredFields')}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      return;
    }
    setLoading(true);
    await userLogin(userEmail, userPassword)
      .then((userCredential: any) => {
        const { user } = userCredential;
        setEmailVerified(user.emailVerified);
        if (!user.emailVerified) {
          Swal.fire({
            icon: `error`,
            title: `${t('common.words.oops')}`,
            text: `${t('login.loginEmailNotVerified')}`,
            showConfirmButton: true,
            confirmButtonText: `${t('common.words.yes')}`,
            showDenyButton: true,
            denyButtonText: `${t('common.words.no')}`,
            backdrop: `rgba(0,0,0,0.9)`,
          }).then(result => {
            if (result.isConfirmed) {
              sendEmailVerification(user);
            }
          });
        }
      })
      .catch((err: any) => {
        switch (err.code) {
          case 'auth/user-not-found': {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('login.loginEmailNotRegistered')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
              showConfirmButton: true,
              confirmButtonText: `${t('common.words.yes')}`,
              showDenyButton: true,
              denyButtonText: `${t('common.words.no')}`,
            }).then(result => {
              if (result.isConfirmed) {
                history.push({
                  pathname: `/signup`,
                  state: { userEmail, userPassword },
                });
              }
            });
            break;
          }
          case 'auth/wrong-password': {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('login.loginCredentialsError')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          case 'auth/too-many-requests': {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('login.loginManyRequests')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          case 'auth/invalid-email': {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('login.loginEmailInvalid')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
          default: {
            Swal.fire({
              title: `${t('common.words.oops')}`,
              text: `${t('login.loginFailed')}`,
              icon: `error`,
              backdrop: `rgba(0,0,0,0.9)`,
            });
            break;
          }
        }
      });
    setLoading(false);
  };

  const handleGoogleLogin = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('email');
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        const { credential, additionalUserInfo } = result;
        setGoogleCredentials({
          credential,
          profile: additionalUserInfo?.profile,
        });
      })
      .catch(err => {
        // Handle Errors here.
        // Swal.fire({
        //   title: `${t('common.words.oops')}`,
        //   text: `${t('login.loginFailed')}`,
        //   icon: `error`,
        //   backdrop: `rgba(0,0,0,0.9)`,
        // });
        console.error('@handleGoogleLogin', err.message);

        // const errorCode = error.code;
        // const errorMessage = error.message;
        // console.log('Error Google: ', errorCode, errorMessage);

        // The email of the user's account used.
        // const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        // const { credential } = error;
        // ...
      });
  };

  const handleFacebookLogin = async () => {
    const provider = new firebase.auth.FacebookAuthProvider();
    return firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        const { user } = result;
        setFacebookCredentials({
          credential: user,
        });
      })
      .catch(err => {
        // Handle Errors here.
        // const errorCode = error.code;
        // Swal.fire({
        //   title: `${t('common.words.oops')}`,
        //   text: `${t('login.loginFailed')}`,
        //   icon: `error`,
        //   backdrop: `rgba(0,0,0,0.9)`,
        // });
        console.error('@handleGoogleLogin', err.message);

        // The email of the user's account used.
        // const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        // const { credential } = error;
        // ...
      });
  };

  const getCondomobUserData = async () => {
    return api
      .get(`/acesso/social/list`, {
        headers: { Authorization: fireBaseAuthJWT },
        params: {
          administradora: adminLinkIntegrationId,
        },
      })
      .then(res => {
        if (res.status === 200) {
          setUserUnits(res.data);
          // setLoading(false);
        }
      })
      .catch(err => {
        console.error('@getCondomobUserData', err);
        setLoading(false);
      });
  };

  const getAdminInfo = async (): Promise<void> => {
    console.warn('check');
    const config = {
      params: {
        id: adminLinkIntegrationId,
      },
      headers: { Authorization: fireBaseAuthJWT },
    };

    await api
      .get(`/administradora/get`, config)
      .then(res => {
        if (res.status === 200) {
          setAdminData(res.data);
        }
      })
      .catch(err => {
        console.error('@getAdminInfo', err.response.data);
        setLoadingCondomob(false);
      });
  };

  const sendWhatsApp = (phoneNumber: string) => {
    const numberFormated = phoneNumber.replace(/\D/g, '');
    window.open(`https://wa.me/55${numberFormated}`, '_blank');
  };

  const sendMail = (email: string) => {
    window.open(`mailto:${email}`, '_blank');
  };

  const openWebPage = (webpage: string) => {
    window.open(webpage, '_blank');
  };

  useEffect(() => {
    const admId = getAdm();
    const administradora = query.get('administradora');

    if (administradora !== null) {
      storeAdm(administradora);
      setAdminLinkIntegrationId(administradora);
      return;
    }

    if (admId !== null) {
      storeAdm(admId);
      setAdminLinkIntegrationId(admId);
      return;
    }

    setAdminLinkIntegrationId('');
  }, []);

  useEffect(() => {
    const getToken = async (): Promise<void> => {
      setFireBaseAuthJWT(await userCredentials.getIdToken());
    };

    if (userCredentials) {
      getToken();
    }

    if (Object.keys(googleCredentials).length > 0) {
      setFireBaseAuthJWT(googleCredentials.credential.idToken);
      setEmailVerified(googleCredentials.profile.verified_email);
    }

    if (Object.keys(facebookCredentials).length > 0) {
      setFireBaseAuthJWT(
        facebookCredentials.credential.multiFactor.user.accessToken,
      );
      setEmailVerified(true);
    }
  }, [userCredentials, googleCredentials, facebookCredentials]);

  useEffect(() => {
    if ((fireBaseAuthJWT && emailVerified) || userUnits.length === 0) {
      getCondomobUserData();
    }
  }, [fireBaseAuthJWT, emailVerified]);

  useEffect(() => {
    if (userCredentials) {
      history.push({
        pathname: '/listunits',
        state: { userUnits },
      });
    }
  }, [userUnits]);

  useEffect(() => {
    if (adminLinkIntegrationId !== null && adminLinkIntegrationId !== '') {
      getAdminInfo();
    }

    if (adminLinkIntegrationId === '') {
      setLoadingCondomob(false);
    }
  }, [adminLinkIntegrationId]);

  useEffect(() => {
    if (adminData !== '' && Object.keys(adminData).length > 0) {
      setLoadingCondomob(false);
    }
  }, [adminData]);

  return loadingCondomob ? (
    <LoadingCondomob />
  ) : (
    <>
      {adminData !== '' && (
        <Modal
          isOpen={modalVisible}
          onRequestClose={() => {
            setModalVisible(false);
          }}
          style={modalOverlayStyle}
        >
          <CloseButton
            onClick={() => setModalVisible(false)}
            title={`${t('common.words.close')}`}
          >
            <FontAwesomeIcon
              icon={['fas', 'times-circle']}
              size="2x"
              color="#4d6e98"
            />
          </CloseButton>
          <ContentContainer>
            <ContentData>
              <AdminLogoContainer>
                <AdminLogoContent
                  src={adminData.imgLink}
                  alt={adminData.nome}
                />
              </AdminLogoContainer>
              <AdminDataContainer>
                <AdminName>{adminData.nome}</AdminName>
                <AdminAddress>
                  {adminData?.endereco?.map((info, index) => (
                    <p key={index.toString()}>{info}</p>
                  ))}
                </AdminAddress>
              </AdminDataContainer>
              <AdminContactContainer key="2">
                {adminData.whatsapps.map((item, index) => {
                  return (
                    <AdminContactItem
                      key={index.toString()}
                      onClick={() => {
                        sendWhatsApp(item);
                      }}
                    >
                      <FontAwesomeIcon icon={['fab', 'whatsapp']} />
                      <AdminContactData>{item}</AdminContactData>
                    </AdminContactItem>
                  );
                })}
                {adminData.fones.map((item, index) => {
                  return (
                    <AdminContactItem key={index.toString()}>
                      <FontAwesomeIcon icon={['fas', 'phone']} />
                      <AdminContactData>{item}</AdminContactData>
                    </AdminContactItem>
                  );
                })}
                {adminData.emails.map((item, index) => {
                  return (
                    <AdminContactItem
                      key={index.toString()}
                      onClick={() => {
                        sendMail(item);
                      }}
                    >
                      <FontAwesomeIcon icon={['fas', 'envelope']} />
                      <AdminContactData>{item}</AdminContactData>
                    </AdminContactItem>
                  );
                })}
                {adminData.site !== null && (
                  <AdminContactItem
                    onClick={() => {
                      openWebPage(adminData.site);
                    }}
                  >
                    <FontAwesomeIcon icon={['fal', 'globe']} />
                    <AdminContactData>{adminData.site}</AdminContactData>
                  </AdminContactItem>
                )}
              </AdminContactContainer>
              <div key="3">
                <AdminCondoInfoUnitsContent
                  dangerouslySetInnerHTML={{ __html: adminData.infoHtml }}
                />
              </div>
            </ContentData>
          </ContentContainer>
        </Modal>
      )}

      <InstallAppModal />

      <Container className="loginNoMenuDefault">
        <BackGroundImg
          src={
            adminData?.imgLogin === undefined || adminData?.imgLogin === ''
              ? bkgLoginImg
              : `${adminData?.imgLogin}`
          }
        />
        <Card>
          {adminData !== '' && (
            <AdminPlateContainer>
              <AdminPlateLogo src={`${adminData.imgLink}`} />
              <AdminPlateInfoContainer>
                <AdminPlateInfoName>{adminData.nome}</AdminPlateInfoName>
                <AdminPlateInfoButton
                  type="button"
                  onClick={() => {
                    setModalVisible(true);
                  }}
                >
                  <FontAwesomeIcon icon={['fas', 'plus']} />
                  Mais informações
                </AdminPlateInfoButton>
                {/* <ButtonLink
                link="mailto:sandro.damasceno@condomob.net"
                icon="envelope"
                iconFamily="far"
                label={`${adminData.emails[0]}`}
              /> */}
              </AdminPlateInfoContainer>
            </AdminPlateContainer>
          )}
          <Form onSubmit={getFormData}>
            <InputContainer>
              <Input
                type="text"
                placeholder={`${t('common.words.email')}`}
                name="email"
                value={userEmail}
                onChange={event => setUserEmail(event.target.value)}
                required
              />
              <Input
                type="password"
                placeholder={`${t('common.words.password')}`}
                name="password"
                value={userPassword}
                onChange={event => setUserPassword(event.target.value)}
                required
              />
            </InputContainer>

            <ButtonContainer>
              <Button
                title={loading ? '' : `${t('common.words.access')}`}
                onClick={handleLogin}
                disabled={loading}
                model="default"
              />
            </ButtonContainer>
          </Form>
          <RegisterContainer>
            <Link to="/forgotpassword" className="linkMedia">{`${t(
              'login.loginForgotPassword',
            )}`}</Link>
            <Link to="/signup" className="linkMedia">{`${t(
              'login.loginRegisterEmail',
            )}`}</Link>
          </RegisterContainer>

          <SocialButtonsContainer>
            <SocialButton onClick={handleGoogleLogin} provider="google">
              {`${t('login.loginContinueGoogle')}`}
            </SocialButton>
            <SocialButton onClick={handleFacebookLogin} provider="facebook">
              {`${t('login.loginContinueFacebook')}`}
            </SocialButton>
          </SocialButtonsContainer>
        </Card>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Container>
    </>
  );
};

export default Login;
