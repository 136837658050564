import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dom, IconName } from '@fortawesome/fontawesome-svg-core';
import Swal from 'sweetalert2';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import api from '../../../services/api';

import LoadingCondomob from '../../../components/LoadingCondomob';
import { Input } from '../../../components/Input';
import LoadMoreAnimation from '../../../components/LoadMore';

import {
  Container,
  FilterContainer,
  ListContainer,
  ListItem,
  BorderBottom,
} from './styles';
import { normalizedContains } from '../../../constants/Utils';

interface DadosListProps {
  dataAtualizacao: number;
  fileName: string;
  id: number;
  link: string;
  periodo: string;
  titulo: string;
  usuario: string;
}
interface DadosProps {
  list: Array<DadosListProps>;
}

const Accountability: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [arrayHolder, setArrayHolder] = useState({
    list: [],
  } as DadosProps);

  const [dados, setDados] = useState({
    list: [],
  } as DadosProps);

  const { t } = useTranslation();

  dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver

  async function getData(loadmore = false) {
    if (!loadmore) {
      setLoading(true);
    }

    setSearchTerm('');
    try {
      const url = `/prestacaoConta/list`;
      await api
        .get(url)
        .then(res => {
          setLoading(false);
          setRefreshing(false);

          const novosDados = {
            list: [...dados.list, ...res.data.list],
          };
          setDados(novosDados);
          setArrayHolder(novosDados);
        })
        .catch((error: any) => {
          setLoading(false);
          setRefreshing(false);
          Swal.fire({
            title: `${t('common.words.oops')}`,
            text: `${error.response.data.error}`,
            icon: `error`,
            backdrop: `rgba(0,0,0,0.9)`,
          });
        });
    } catch (err: any) {
      Swal.fire({
        title: `${t('common.words.oops')}`,
        text: `${err.message}`,
        icon: `error`,
        backdrop: `rgba(0,0,0,0.9)`,
      });
      setLoading(false);
    }
  }

  function handleRefresh() {
    setRefreshing(true);
    getData(true);
  }

  function SearchFilterFunction(text) {
    if (dados.list.length > 0) {
      const newData = dados.list.filter(item => {
        const textoFiltro = item.titulo + item.periodo;
        return normalizedContains(textoFiltro, text);
      });
      setArrayHolder({
        list: newData,
      });
      setSearchTerm(text);
    }
  }

  const sendByEmail = async id => {
    return api
      .post(`/prestacaoConta/receberEmail`, null, { params: { id } })
      .then(res => {
        if (res.status === 200) {
          const str = `${t('documents.sendByEmailMessage')}`;
          Swal.fire({
            icon: 'success',
            title: 'Sucesso!',
            html: `<pre>${str}</pre>`,
            customClass: {
              popup: 'format-alert',
            },
            text: str,
          });
        }
      })
      .catch(err => {
        const str = `${t('documents.documentNotSend')}${
          err.response.data.error
        }`;
        Swal.fire({
          icon: 'error',
          title: 'Ops...',
          html: `<pre>${str}</pre>`,
          customClass: {
            popup: 'format-alert',
          },
        });
      });
  };

  const downloadFile = fileURL => {
    window.open(fileURL);
  };

  const renderItem = () =>
    arrayHolder.list.map(item => {
      let iconName: IconName;
      switch (item.link.split('.').pop()?.substring(0, 3)) {
        case 'pdf':
          iconName = 'file-pdf';
          break;

        case 'svg':
          iconName = 'file-image';
          break;

        default:
          iconName = 'file-spreadsheet';
      }
      return (
        <ListItem key={item.id}>
          <button
            type="button"
            onClick={() => {
              Swal.fire({
                text: `${item.fileName}`,
                showConfirmButton: item.link.length > 0,
                confirmButtonText:
                  '<i class="fas fa-cloud-download-alt"></i> Fazer download',
                confirmButtonColor: `#3c5f8b`,
                backdrop: `rgba(0,0,0,0.9)`,
                showDenyButton: true,
                denyButtonText:
                  '<i class="fas fa-envelope"></i> Receber por e-mail',
                denyButtonColor: '#3c5f8b',
              }).then(result => {
                if (result.isConfirmed) {
                  downloadFile(item.link);
                } else if (result.isDenied) {
                  sendByEmail(item.id);
                }
              });
            }}
          >
            <FontAwesomeIcon icon={['fal', iconName]} />
            <div>
              <strong>
                {item.titulo} - {item.periodo}
              </strong>
              <div>
                <p>
                  {format(
                    new Date(item.dataAtualizacao),
                    'dd/MM/yyyy HH:mm:ss',
                  )}
                </p>
              </div>
            </div>
          </button>
          <BorderBottom />
        </ListItem>
      );
    });

  useEffect(() => {
    setRefreshing(true);
    getData();
  }, []);

  return (
    <Container>
      {loading ? (
        <LoadingCondomob />
      ) : (
        <>
          <FilterContainer>
            <Input
              name="termSearch"
              type="text"
              placeholder={`${t('common.words.searchTermDescription')}`}
              value={searchTerm}
              onChange={e => {
                SearchFilterFunction(e.target.value);
              }}
              style={{ borderColor: 'var(--primary)' }}
              icon="search"
            />
          </FilterContainer>

          <ListContainer>{renderItem()}</ListContainer>
        </>
      )}
    </Container>
  );
};

export default Accountability;
