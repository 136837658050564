import { ButtonHTMLAttributes } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

import { Container, Content, Title } from './styles';

interface MenuButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  icon: string;
  active?: boolean;
}

export function MenuButton(props: MenuButtonProps): JSX.Element {
  const { title, icon, active = false, onClick } = props;

  return (
    <Container onClick={onClick}>
      <Content>
        <FontAwesomeIcon icon={['fas', icon as IconName]} />
      </Content>
      <Title active={active}>{title}</Title>
    </Container>
  );
}

// Specifies the default values for props:
MenuButton.defaultProps = {
  active: false,
};
