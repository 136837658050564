import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

import api from '../../../services/api';

import LoadingCondomob from '../../../components/LoadingCondomob';
import EmptyList from '../../../components/EmptyList';

import {
  Container,
  LoadingContainer,
  ListContainer,
  ListItem,
  ListItemTitle,
  ListItemIcon,
  TitleContainer,
  TitleBackButton,
  Title,
} from './styles';

interface TicketProps {
  descricao: string;
  id: string;
  link: string;
}

const TicketUpload: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [tickets, setTickets] = useState<TicketProps[]>([]);

  const history = useHistory();
  const { t } = useTranslation();

  const renderItem = () =>
    tickets.map(item => (
      <ListItem
        key={item.id}
        onClick={() => {
          history.push({
            pathname: '/tickets/ticketUploadDetails',
            state: {
              item,
            },
          });
        }}
      >
        <ListItemTitle>
          <strong>{`${item.descricao}`}</strong>
        </ListItemTitle>
        <ListItemIcon>
          <FontAwesomeIcon icon={['fas', 'chevron-right']} />
        </ListItemIcon>
      </ListItem>
    ));

  function getData() {
    const url = '/financeiro3o/list';
    api
      .get(url)
      .then(res => {
        setLoading(false);
        setTickets(res.data);
        // console.log('Ticket Upload: ', res.data);
      })
      .catch(error => {
        setLoading(false);
        Swal.fire({
          title: `${t('common.words.oops')}`,
          text: `${error.response.data.error}`,
          icon: `error`,
          backdrop: `rgba(0,0,0,0.9)`,
        });
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <Container className="containerDefault">
      {loading ? (
        <LoadingContainer>
          <LoadingCondomob />
        </LoadingContainer>
      ) : (
        <>
          <TitleContainer>
            <TitleBackButton
              onClick={() => {
                history.goBack();
              }}
            >
              <FontAwesomeIcon icon={['far', 'angle-left']} />
              {`${t('common.words.back')}`}
            </TitleBackButton>
            <Title>{`${t('tickets.ticket')}`}</Title>
          </TitleContainer>
          {tickets.length === 0 ? (
            <EmptyList />
          ) : (
            <ListContainer>{renderItem()}</ListContainer>
          )}
        </>
      )}
    </Container>
  );
};

export default TicketUpload;
