import styled from 'styled-components';

const iff = (condition, then, otherwise) => (condition ? then : otherwise);

export const Container = styled.div`
  padding: 1rem;
  overflow-x: hidden;
  overflow-y: auto;
`;

export const LoadingContainer = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const NoticeBoard = styled.div<{ level: 'ok' | 'warning' | 'alert' }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0.3rem;

  background-color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBkg)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBkg)',
          'var(--noticeWarningBkg)',
        )};
  border: solid 0.5px
    ${props =>
      props.level === 'alert'
        ? 'var(--noticeAlertBorder)'
        : iff(
            props.level === 'ok',
            'var(--noticeOkBorder)',
            'var(--noticeWarningBorder)',
          )};
`;

export const NoticeBoardMessage = styled.text<{
  level: 'ok' | 'warning' | 'alert';
}>`
  font-weight: 700;
  margin-right: 0.5rem;
  color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertText)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkText)',
          'var(--noticeWarningText)',
        )};
`;

export const NoticeBoardButton = styled.button<{
  level: 'ok' | 'warning' | 'alert';
}>`
  padding: 0.5rem;
  border-radius: 0.3rem;
  font-weight: 700;
  background-color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBtnBkg)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBtnBkg)',
          'var(--noticeWarningBtnBkg)',
        )};
  color: ${props =>
    props.level === 'alert'
      ? 'var(--noticeAlertBtnText)'
      : iff(
          props.level === 'ok',
          'var(--noticeOkBtnText)',
          'var(--noticeWarningBtnText)',
        )};
  border: solid 0.5px
    ${props =>
      props.level === 'alert'
        ? 'var(--noticeAlertBtnBorder)'
        : iff(
            props.level === 'ok',
            'var(--noticeOkBtnBorder)',
            'var(--noticeWarningBtnBorder)',
          )};
`;

export const ListContainer = styled.div`
  width: 100%;
  overflow-y: auto;
`;

export const ListItem = styled.button`
  position: relative;
  display: flex;
  width: -webkit-fill-available;
  border: none;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;

  :hover {
    background-color: var(--buttonHover);
  }
`;

export const ExpiredText = styled.text<{
  expired: true | false;
}>`
  color: ${props => (props.expired ? '#dc3545' : '#3c608b')};
`;

export const ListItemDescription = styled.text<{
  expired: true | false;
}>`
  color: ${props => (props.expired ? '#dc3545' : '#3c608b')};
`;

export const ListItemTitle = styled.div`
  display: flex;
  width: 33%;
  height: 4rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.3rem;

  strong {
    font-size: 1.2rem;
    text-align: left;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* number of lines to show */
    -webkit-box-orient: vertical;
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const ListItemValue = styled.div`
  display: flex;
  width: 33%;
  justify-content: center;
  align-items: center;
`;

export const ListItemIcon = styled.div`
  display: flex;
  width: 33%;

  svg {
    margin-left: auto;
  }
`;

export const BorderBottom = styled.div`
  position: absolute;
  width: 98%;
  border: none;
  border-bottom: solid 1px var(--borderExtraLight);
  bottom: 0;

  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
`;
