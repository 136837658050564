import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;

  padding: 0.5rem;

  overflow: auto;
`;

export const VAVoteItemListContainer = styled.div`
  width: 25%;
  height: 100%;

  padding: 0.5rem;

  border-radius: 0.5rem;

  margin-right: 0.5rem;

  background-color: var(--white);
  overflow: auto;
`;

export const VAVoteItemListTitle = styled.text`
  font-size: 1rem;
  font-weight: bold;

  color: var(--dark);
`;

export const VAVoteItemList = styled.div`
  margin-top: 1rem;
`;

export const VAVoteItemListButton = styled.button<{ itemSelected: boolean }>`
  display: flex;
  width: 100%;

  border: none;
  border-radius: 2rem;

  align-items: center;
  margin-bottom: 0.5rem;

  padding: 0.5rem;

  div {
    display: flex;
    width: 1.6rem;
    height: 1.6rem;

    align-items: center;
    justify-content: center;

    border-radius: 1rem;
    background-color: var(--secondary50);
    margin-right: 1rem;

    font-weight: bold;
    color: var(--primary);
  }

  p {
    font-size: 1rem;
    font-weight: bold;

    color: var(--white);
  }

  :hover {
    background-color: var(--primary);
  }

  background-color: ${props =>
    props.itemSelected ? 'var(--primary)' : 'var(--secondary)'};
`;

export const VAVoteItemMessageContainer = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  border-radius: 0.5rem;

  overflow: auto;
`;

export const VAVoteListItem = styled.button`
  position: relative;
  display: flex;
  width: 100%;

  padding: 10px 20px;
  align-items: center;
  margin-bottom: 0.5rem;

  border: none;
  border-radius: 0.5rem;
  background-color: var(--white);

  :hover {
    background-color: #f9f9f9;
  }

  svg {
    :last-child {
      margin-left: auto;
    }
  }
`;

export const VAVoteListItemContent = styled.div`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  text-align: left;
  color: var(--primary);

  div {
    display: flex;
    margin: 0.2rem 0;
    align-items: center;

    strong {
      font-size: 1rem;
      color: var(--dark);
    }

    p {
      color: var(--textDark);
    }
    svg {
      margin-right: 0.5rem;
    }
  }
`;

export const VAVoteListItemStatus = styled.div<{ encerrada: boolean }>`
  position: absolute;
  right: 00.5rem;
  bottom: 0rem;

  padding: 0.2rem 0.5rem;
  border-radius: 2rem;

  font-weight: 700;
  color: var(--white);

  background-color: ${props =>
    props.encerrada ? 'var(--primary)' : 'var(--success)'};
`;
