import { Suspense, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';

import { GlobalStyle } from './styles/global';

import Header from './components/Header';
import Routes from './routes';

import { AuthProvider } from './contexts/AuthContext';
import { AppCommonProvider } from './contexts/AppCommonContext';

import googleTagManagerConfig from './config/google-tag-manager';

export function App(): JSX.Element {
  // Datadog configuration
  datadogRum.init({
    applicationId: '69655615-f8c4-45b5-9810-a0c4d227fb75',
    clientToken: 'pubfde8159ee84f6f7398fe23e2abf3e477',
    site: 'datadoghq.com',
    service: 'condomob-app-web',

    // Specify a version number to identify the deployed version of your application in Datadog
    version: '0.22.50',
    sampleRate: 80,
    // premiumSampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    // defaultPrivacyLevel: 'mask-user-input',
  });

  // datadogRum.startSessionReplayRecording();

  useEffect(() => {
    TagManager.initialize({ gtmId: googleTagManagerConfig.trackingId });
  }, []);
  // https://stackoverflow.com/questions/39094138/reactjs-event-listener-beforeunload-added-but-not-removed
  // https://v5.reactrouter.com/core/api/Prompt
  // https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd
  // const useUnload = fn => {
  //   const cb = useRef(fn); // init with fn, so that type checkers won't assume that current might be undefined
  //   useEffect(() => {
  //     cb.current = fn;
  //   }, [fn]);
  //   useEffect(() => {
  //     const handleLeavePage = e => {
  //       const confirmationMessage = '';
  //       e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
  //       return confirmationMessage; // Gecko, WebKit, Chrome <34
  //     };
  //     const onUnload = (...args) => cb.current?.(...args);
  //     const teste = () => {
  //       Swal.fire({
  //         icon: `error`,
  //         title: `${t('common.words.oops')}`,
  //         text: `testando`,
  //         showConfirmButton: true,
  //         confirmButtonText: 'Sim',
  //         showDenyButton: true,
  //         denyButtonText: 'Não',
  //         backdrop: `rgba(0,0,0,0.9)`,
  //       }).then(result => {
  //         if (result.isConfirmed) {
  //           console.info('teste');
  //         }
  //       });
  //     };
  //     window.onbeforeunload = () => {};
  //     window.addEventListener('beforeunload', handleLeavePage);
  //     return () => window.removeEventListener('beforeunload', handleLeavePage);
  //   }, []);
  // };

  // window.onbeforeunload = () => {
  //   console.info('teste beforeunload');
  //   return window.alert('teste');
  // };

  // useUnload(e => {
  //   e.preventDefault();
  //   e.returnValue = '';
  // });
  return (
    <Suspense fallback={<h1>Loading languages...</h1>}>
      <AuthProvider>
        <AppCommonProvider>
          <Router>
            <div className="bodyApp">
              <Header />
              <div className="bodyContent">
                <Routes />
              </div>
            </div>
          </Router>
          <GlobalStyle />
        </AppCommonProvider>
      </AuthProvider>
    </Suspense>
  );
}
